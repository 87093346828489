import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { TestPageComponent } from './test-page/test-page.component';
import { TestRoutingModule } from './test-routing.module';
import { CommonComponentsModule } from 'src/app/modules/common-components/common-components.module';
import { AdaptiveTestProgressComponent } from './adaptive-test-progress/adaptive-test-progress.component';
import { AnswerCountComponent } from 'src/app/standalone-components/answer-count/answer-count.component';
import { ProgressBarComponent } from 'src/app/standalone-components/progress-bar/progress-bar.component';

// AoT requires an exported function for factories
export function TranslateLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
  //return new TranslateGVarLoader();
}

@NgModule({
  declarations: [
    TestPageComponent,
    AdaptiveTestProgressComponent
  ],
  imports: [
    AnswerCountComponent,
    CommonModule,
    CommonComponentsModule,
    MatProgressSpinnerModule,
    ProgressBarComponent,
    TestRoutingModule,
    TranslateModule.forChild({
      defaultLanguage: 'de',
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateLoaderFactory,
        deps: [HttpClient]
      }
    }),

  ],
  exports:[
    TestPageComponent
  ]
})
export class TestModule { }
