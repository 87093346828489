<header [label]="headerLabel"
        [showImage]="showHeaderImage"
        [showLabelFromId]="false"
        [showLeftButton]="(examQuestionNum>1 || showBackButton) && questionLoadComplete"
        [showRightButton]="examQuestionNum<examQuestionCount && showNextButton && questionLoadComplete"
        (next)="onNext()"
        (previous)="onPrevious()">
</header>
<div class="error-message"
     [@visibility]="errorMessageId ? 'visible' : 'invisible'">
    <p>{{errorMessageId | translate}}</p>
</div>

<div class="main-view">

    <mat-spinner *ngIf="showLoadingProgress"
                 mode="indeterminate"
                 class="custom-spinner"></mat-spinner>

    <div [@visibility]="mainViewVisibility"
         class="router-outlet-container">

        <router-outlet (activate)="setRoutedComponent($event)"
                       (deactivate)="unsetRoutedComponent($event)"></router-outlet>
    </div>

</div>
<div class="stop-button-bar"
     [@visibility]="{value:showStopButton ? 'visible' : 'invisible',
                        params:{displayValue:'flex'}}">
    <round-button [backgroundColor]="'#0088CE'"
                  [labelId]="'exam_page.finish_test'"
                  (click)="onStop()"
                  class="stop-button"></round-button>
</div>
<toolbar>
    <!-- Exit button -->
    <toolbar-exit-button [@visibility]="showExitButton ? 'visible' : 'invisible'"
                         (click)="onExit()">
    </toolbar-exit-button>

    <round-button *ngIf="showStopButton"
                  [backgroundColor]="'#0088CE'"
                  [labelId]="'exam_page.finish_test'"
                  (click)="onStop()"
                  class="tb-stop-button"></round-button>


    <!-- Timer -->
    <exam-timer>
    </exam-timer>

    <!-- Navigation button -->
    <toolbar-toggle-button [@visibility]="showNavigationButton ? 'visible' : 'invisible'"
                           [icon]="'navigation'"
                           [(isOn)]="questionListOn"
                           [toggleButton]="'navigation'">
    </toolbar-toggle-button>


    <!-- Bookmark button-->
    <toolbar-bookmarks [@visibility]="{value:showBookmarkButton ? 'visible' : 'invisible',
                                                params:{displayValue:'flex'}}"
                       [menuAlign]="'right'"
                       [showAddBookmark]="showAddBookmark"
                       [showSelectBookmark]="showSelectBookmark"
                       (addBookmark)="onAddBookmark()"
                       (selectBookmark)="onSelectBookmark()">
    </toolbar-bookmarks>

</toolbar>