import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { backgroundColorAnimation } from 'src/app/global/animations/background-color.animation';

@Component({
  selector: 'answer-letter',
  templateUrl: './test-question-answer-list-item-letter.component.html',
  styleUrls: ['./test-question-answer-list-item-letter.component.css'],
  animations: [
    backgroundColorAnimation
  ]
})
export class TestQuestionAnswerListItemLetterComponent implements OnInit {

  @HostBinding('@backgroundColor')
  get backgroundColorAnimation() {
    return {

      value: this.selected ? 'color1' : 'color2',
      params: {
        color1: '#0088CE', color2: '#6e6a66'
      }
    }
  };

  @Input() index: number;
  @Input() selected: boolean;

  readonly letterList: string[] = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'];

  constructor() { }

  ngOnInit(): void {
  }

}
