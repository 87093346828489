export class AdaptiveLearnStatistics {

    failedAttemptCount: number;
    requiredAttemptCount: number;
    successfulAttemptCount: number;

    constructor(
        failedAttemptCount: number = 0,
        requiredAttemptCount: number = Number.MAX_SAFE_INTEGER,
        successfulAttemptCount: number = 0
    ) {
        this.failedAttemptCount = failedAttemptCount;
        this.requiredAttemptCount = requiredAttemptCount;
        this.successfulAttemptCount = successfulAttemptCount;
    }
}