export enum TestViewType {
    ADAPTIVE_TEST_QUESTION = 'ADAPTIVE_TEST_QUESTION',
    ADAPTIVE_TEST_QUESTION_TYPEIN = 'ADAPTIVE_TEST_QUESTION_TYPEIN',
    TEST_QUESTION = 'TEST_QUESTION',
    TEST_QUESTION_TYPEIN = 'TEST_QUESTION_TYPEIN',
    TOTAL_STATISTICS = 'TOTAL_STATISTICS',
    TOPIC_STATISTICS = 'TOPIC_STATISTICS',
    EXPERT_COMMENTS = 'EXPERT_COMMENTS',
    IMAGE_ZOOM = 'IMAGE_ZOOM',
    QUESTION_LIST = 'QUESTION_LIST'
}
export interface TestView {
    readonly testViewType: TestViewType;
}