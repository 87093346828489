import { SapiAddress } from "./sapi-address";

export class   SapiSubscriptionCreate {

    paymentId: string;
    email: string;
    language: string;
    address: SapiAddress;

    constructor(
        paymentId: string,
        email: string,
        language: string,
        address: SapiAddress,
    ) {
        this.paymentId = paymentId
        this.email = email;
        this.language = language;
        this.address = address
    }
}
