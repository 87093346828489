import { animate, state, style, transition, trigger } from "@angular/animations";

export const backgroundColorAnimation = trigger(
    'backgroundColor',
    [
    state(
        'color1',
        style({
            backgroundColor: '{{color1}}'
        }),
        {
            params:
            {
                color1: 'black'
            }
        }
    ),
    state(
        'color2',
        style({
            backgroundColor: '{{color2}}'
        }),
        {
            params:
            {
                color2: 'white'
            }
        }
    ),
    transition(
        'color1 <=> color2',
        [
            animate('0.2s')
        ]
    ),

]);