<img [src]="src"
     class="test-image"
     (contextmenu)="onContextMenu($event)"
     (error)="onImageLoadError()"
     (load)="onImageLoad($event)"
     alt="Image load failed">

<img *ngIf="showZoomButton"
     src="assets/images/ui/test_page/magnify.png"
     class="magnify"
     (click)="onZoomClick($event)">