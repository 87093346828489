import { Injectable } from '@angular/core';
import { TestDataConfigTopic } from 'src/app/models/test/test-data-config';
import { PersistentStateService } from 'src/app/services/persistent-state/persistent-state.service';

/**
 * The enum defines two possible values of the persistense member of the BookmarkService class
 * The enum is also a type of a parameter to be assigned to the member
 */
export enum BookmarkPersistence {
  Persistent,
  Temporary
}
export interface Bookmark {
  topic: TestDataConfigTopic;
  questionNum: string;
}

/**
 * The service is responsible for bookmarks management: adding, removing, finding, etc.
 */

@Injectable()
export class BookmarkService {

  /**
   * The test bookmarks are persistrent. They are saved between test application runs
   * The exam test bookmarks are temporary. After next exam test start old bookmarks must be removed
   */
  private persistence: BookmarkPersistence = BookmarkPersistence.Persistent;

  /**
   * @return list of bookmarks as an array of TestConfigTopic
   * @see TestDataConfigTopic
   */
  get bookmarkList(): TestDataConfigTopic[] {
    return this._bookmarkList;
  }

  private _bookmarkList: TestDataConfigTopic[];

  /**
   * The class constructor
   * @param persistentState The injectable persitent state service, which is used to staore the data 
   * persistent between the application executions.
   * @see PersistentStateService
   */
  constructor(
    private persistentState: PersistentStateService
  ) {
    this._bookmarkList = [];
  }

  /**
   * Adds a question bookmark to the list
   * @param topic The topic, to which the question blongs
   * @param questionNum The nuber of the question beeing bookmarked
   * @see TestDataConfigTopic
   */

  addBookmark(
    topic: TestDataConfigTopic,
    questionNum: string
  ): void {

    const index: number = this._getTopicIndex(topic);

    this._bookmarkList[index].questionList.push(questionNum);

    if (this.persistence == BookmarkPersistence.Persistent) {
      this.persistentState.bookmarkList = this._bookmarkList;
    }
  }

  deleteBookmark(topic: TestDataConfigTopic, questionNum: string): void {

    // Find the topic
    const topicIndex: number = this._getTopicIndex(topic);

    // Find the question in the topic
    const questionIndex: number = this._bookmarkList[topicIndex].questionList.indexOf(questionNum);

    // If the question was found, then delete it from the question list
    if (questionIndex > -1) {
      this._bookmarkList[topicIndex].questionList.splice(questionIndex, 1);

      // If it was the last bookmark in the topic, then delete the topic too
      if (this._bookmarkList[topicIndex].questionList.length == 0) {
        this._bookmarkList.splice(topicIndex, 1);
      }

      // Save the changes
      if (this.persistence == BookmarkPersistence.Persistent) {
        this.persistentState.bookmarkList = this._bookmarkList;
      }
    }
  }

  /**
   * Checks if any bookmark was added to the list
   * @returns true if a bookmark was already added to the list
   * false if the bookmark list is empty
   */

  anyBookmarkExist(): boolean {
    return this._bookmarkList.length != 0;
  }

  /**
   * Check if the question was already bookmarked.
   * @param topic The topic, to which the question blongs
   * @param questionNum The nuber of the question beeing bookmarked
   * @returns true if the question was already bookmarked, false - otherwise
   * @see TestDataConfigTopic
   */
  bookmarkExist(topicId: string, questionNum: string): boolean {
    let index: number = this._bookmarkList.findIndex(value => value.id == topicId);
    return index == -1 ? false : this._bookmarkList[index].questionList.indexOf(questionNum) != -1;
  }

  /**
   * Removes all bookmarks
   */
  clear(): void {
    this._bookmarkList = [];
    if (this.persistence == BookmarkPersistence.Persistent) {
      this.persistentState.bookmarkList = this._bookmarkList;
    }
  }

  /**
   * Resets the bookmark list. It assigns an empty list to the temporary bookmark list.
   * The bookmark list from persistent storage is assigned to the persistent bookmark list.
   * The method is envoked when a new test or exam test is started
   * @param persistence 
   */

  reset(persistence: BookmarkPersistence): void {
    this.persistence = persistence;
    if (this.persistence == BookmarkPersistence.Persistent) {
      this._bookmarkList = this.persistentState.bookmarkList;
    } else {
      this._bookmarkList = [];
    }
  }

  /**
   * Private utility method that finds the index of a topic in the bookmark list array
   * If the topic is not found, then it is added to the bookmark list
   * @param topic the topic whose index is to be found
   * @retun the index of a topic in the bookmark list array
   * @see TestDataConfigTopic
   */
  private _getTopicIndex(
    topic: TestDataConfigTopic
  ): number {

    let index: number = this._bookmarkList.findIndex(
      value => value.id == topic.id
    );

    if (index == -1) {
      const newTopic: TestDataConfigTopic =
        new TestDataConfigTopic(
          topic.id,
          topic.name,
          [],
          []
        );
      this._bookmarkList.push(newTopic);
      index = this._bookmarkList.length - 1;
    }
    return index;
  }
}
