import { Queue } from "src/app/models/queue";
import { TestDataConfigTopic } from "src/app/models/test/test-data-config";
import { TestResult } from "src/app/models/test/test-result";
import { ETopicMenuItemState } from "src/app/routed-modules/lesson/models/lesson-data-models";
import { Feature } from "src/app/services/persistent-state/keys";
import { AdaptiveLearnStatistics } from "../data/adaptive-learn-statistics";
import { TestQuestionListService } from "./test-question-list.service";

export class TestQuestionListAdaptiveLearnService
    extends TestQuestionListService {

    private readonly QUEUE_CNT = 3;

    private currentQueueIdx: number = 0;
    private queueList: Queue<number>[];

    get failedAttemptCount(): number {
        return this._failedAttemptCount;
    }
    private _failedAttemptCount: number = 0;

    get isAdaptiveLearnMode(): boolean {
        return true;
    }

    get requiredAttemptCount(): number {
        return this._requiredAttemptCount;
    }
    private _requiredAttemptCount: number = Number.MAX_SAFE_INTEGER;

    get savedQueues(): Object {

        return this._savedQueues != null ?
            this._savedQueues :
            this._savedQueues = this.persistentState.get(
                Feature.ADAPTIVE_LEARN_QUEUE_LIST,
                {}
            );
    }
    private _savedQueues: Object;

    get successfulAttemptCount(): number {
        return this._successfulAttemptCount;
    }
    private _successfulAttemptCount: number = 0;

    get topicList(): TestDataConfigTopic[] {
        return super.topicList;
    }

    set topicList(topicList: TestDataConfigTopic[]) {

        this._topicList = this.applyFilter(topicList);

        // load saved test results
        let testResults: Object = this.persistentState.get(
            Feature.ADAPTIVE_TEST_RESULT,
            {}
        );

        // Load statistics
        let statistics = this.persistentState.get(
            Feature.ADAPTIVE_LEARN_STATISTICS,
            {}
        )

        // Calculate the total number of questions in the test
        this._testQuestionCount = 0;
        this.topicList.forEach(

            (topic) => {

                // Calculate the total test questions count
                let topicQueueList =
                    this.savedQueues[topic.id + '_queueList'];

                if (topicQueueList != null) {
                    topicQueueList.forEach(
                        (queue: number[]) =>
                            this._testQuestionCount += queue.length
                    );
                } else {
                    this._testQuestionCount +=
                        topic.questionList.length;
                }

                // Load the topic test results
                topic.testResult = testResults[topic.id] == null ?
                    TestResult.NONE : testResults[topic.id];

                topic.state = topic.testResult == TestResult.PASSED ?
                    ETopicMenuItemState.DISABLED : ETopicMenuItemState.ENABLED;

                // If the topic statistics was no created yet, the do it
                // Total statistics page requires the information for all topics
                // to be able to calculate total statistics
                if (statistics[topic.id] == null) {
                    statistics[topic.id] = new AdaptiveLearnStatistics(
                        0,
                        topic.questionList.length * this.QUEUE_CNT,
                        0
                    );
                }
            }
        );

        // Save statistics
        this.persistentState.set(
            Feature.ADAPTIVE_LEARN_STATISTICS,
            statistics
        );
    }

    get topicQuestionCount(): number {
        return this._topicQuestionCount;
    }
    private _topicQuestionCount: number;

    next(): boolean {

        // Remove the current question from the
        // current queue
        const currentQuestionNum: number =
            this.currentQueue.dequeue();

        if (this.isCurrentQuestionCorrectlyAnswered) {

            this._successfulAttemptCount++;

            // If the question was answered corectly,
            // then move it to the next queue if the
            // current queue is not the last one
            if (this.currentQueueIdx < this.QUEUE_CNT - 1) {
                this.queueList[this.currentQueueIdx + 1].
                    enqueue(currentQuestionNum);
            } else {
                // If this was the last queue, then drop the question
                // and reduce the topic question number
                this._topicQuestionCount--;
                this._testQuestionCount--;
            }

            // Delete the question statistics and answer selections
            // of the current question
            this.statistics.resetCurrentTestStatistics();

            // Mark the test result for current topic as unfinished
            this.updateTopicTestResult();

        } else {

            this._failedAttemptCount++;
            this._requiredAttemptCount += this.currentQueueIdx;

            // If the questions was not answered or answered incorrectly
            // Then move it to the first queue
            this.queueList[0].enqueue(currentQuestionNum);
        }

        if (!this.currentQueue.isEmpty) {
            // If the current queue is not empty, 
            // then show the next question
            this._topicQuestionNum = this.currentQueue.first;
        } else {
            // If the current queue is empty, then
            // switch to the next queue. If the current
            // queue is the last one, the check the first queue.
            // It may have the incorrectly answered questions.
            // If the first queue is also empty, then the topic
            // learning is complete. Move to the next topic
            if (this.currentQueueIdx < this.QUEUE_CNT - 1) {
                this.currentQueueIdx++;
                this._topicQuestionNum = this.currentQueue.first;
            } else {
                if (!this.queueList[0].isEmpty) {
                    this.currentQueueIdx = 0;
                } else {

                    this.topic.state = ETopicMenuItemState.DISABLED;
                    this.topic.testResult = TestResult.PASSED;

                    // Save the current queue list
                    this.saveQuestionQueue();

                    this.saveStatistics();

                    // Save the test result for current topic
                    this.saveTopicTestResult();

                    // Move to the next topic
                    let nextTopicId: string = this.findNextUnfinishedTopic();
                    if (nextTopicId != null) {
                        this.selectTopic(nextTopicId);
                    } else {
                        return false;
                    }
                }
            }
        }

        return true;
    }

    // Going backwards makes no sense in Adaptive Learning mode
    // Therefore this method does nothing
    previous(): boolean {
        return false;
    }

    resetState(): void {
        // Delete the queue lists
        this.persistentState.delete(
            Feature.ADAPTIVE_LEARN_QUEUE_LIST
        );
        this.persistentState.delete(
            Feature.ADAPTIVE_TEST_RESULT
        );
    }

    restoreState(): void {

        this._topicIdx = this.persistentState.get(
            Feature.CURRENT_TEST_TOPIC_IDX,
            0
        );

        this._topicQuestionNum = this.persistentState.get(
            Feature.CURRENT_TEST_QUESTION_IDX,
            1
        );
        this._testQuestionNum = this.calcTestQuestionNum();

        this.initQuestionQueue();
        this.loadStatistics();
    }

    saveState(): void {
        this.persistentState.set(
            Feature.CURRENT_TEST_QUESTION_IDX,
            this.topicQuestionNum
        );
        this.persistentState.set(
            Feature.CURRENT_TEST_TOPIC_IDX,
            this._topicIdx
        );
        this.persistentState.set(
            Feature.CURRENT_TEST_TOPIC_IDX,
            this._topicIdx
        );

        this.saveQuestionQueue();
        this.saveStatistics();
    }

    selectTopic(topicId: string): void {

        this._topicIdx = this.topicList.findIndex(
            element => element.id === topicId
        );
        this._topicQuestionNum = 1;
        this._testQuestionNum = this.calcTestQuestionNum();

        this.initQuestionQueue();
        this.loadStatistics();
    }



    private get currentQueue(): Queue<number> {
        return this.queueList[this.currentQueueIdx];
    }

    private createQuestionQueue(): void {

        this.currentQueueIdx = 0;

        // Initialize the queue list
        this.queueList = new Array<Queue<number>>(this.QUEUE_CNT);
        for (
            let i: number = 0;
            i < this.QUEUE_CNT;
            i++
        ) {
            this.queueList[i] = new Queue<number>();
        }

        // Initialize the first queue
        for (
            let i: number = 1;
            i <= this.topic.questionList.length;
            i++
        ) {
            this.currentQueue.enqueue(i);
        }
    }

    // Unfinished topic is either absent among saved
    // topic queue lists, or it is there and has 
    // not empty queue. If all topics are finished,
    // then the funcion return null. Ohterwise it 
    // returns the topic id that can be used as the
    // selectTopic() method parameter
    private findNextUnfinishedTopic(): string {

        // for each topic ater the current one
        for (
            let i = this._topicIdx < this.topicList.length - 1 ?
                this._topicIdx + 1 : 0;
            i != this._topicIdx;
            i++
        ) {
            let topicId: string = this.topicList[i].id;

            // Get the saved list of arrays
            let queueListCopy: number[][] =
                this.savedQueues[topicId + '_queueList'];

            // If the topic is absent in the saved queue list,
            // then it was never started. Let's start it
            if (queueListCopy == null) {
                return topicId;
            } else {
                // If the topic is present in the saved queue list,
                // then check if it has any not empty queue
                for (const queueArr of queueListCopy) {

                    if (queueArr.length > 0) {
                        return topicId;
                    }
                }
            }

            // If we reached the end of the array, 
            // then start from the beginning
            if (i == this.topicList.length - 1) {
                i = -1;
            }
        }

        return null;
    }

    private initQuestionQueue(): void {

        // Load the saved queues or create new ones
        if (!this.loadQuestionQueue()) {
            this.createQuestionQueue();
        }

        // Count the questions in the queues
        this._topicQuestionCount = 0;
        this.queueList.forEach(
            (queue: Queue<number>) =>
                this._topicQuestionCount += queue.size
        );
    }

    private loadQuestionQueue(): boolean {

        // Check if the queue list for this topic was already saved
        if (this.savedQueues[this.topic.id + '_queueList'] == null) {
            return false;
        } else {
            // Get the saved list of arrays
            let queueListCopy: number[][] =
                this.savedQueues[this.topic.id + '_queueList'];

            // Convert the saved list of arraus to the list of queues
            this.queueList = new Array<Queue<number>>(queueListCopy.length);
            queueListCopy.forEach(
                (value: number[], idx: number) => {
                    this.queueList[idx] = new Queue<number>();
                    this.queueList[idx].fromArray(value);
                }
            );

            this.currentQueueIdx = this.savedQueues[
                this.topic.id + '_currentQueueIdx'
            ];
            return true;
        }

    }

    private saveQuestionQueue(): void {

        // Replace the queue list of the current topic
        let queueListCopy: number[][] = new Array<number[]>(this.queueList.length);
        this.queueList.forEach(
            (value: Queue<number>, idx: number) =>
                queueListCopy[idx] = value.toArray()
        );
        this.savedQueues[this.topic.id + '_queueList'] = queueListCopy;
        this.savedQueues[this.topic.id + '_currentQueueIdx'] = this.currentQueueIdx;

        // Save the queue lists
        this.persistentState.set(
            Feature.ADAPTIVE_LEARN_QUEUE_LIST,
            this.savedQueues
        );
    }


    private loadStatistics(): void {

        let statistics = this.persistentState.get(
            Feature.ADAPTIVE_LEARN_STATISTICS,
            {}
        )

        let topicStatsistics: AdaptiveLearnStatistics =
            statistics[this.topicId] != null ?
                statistics[this.topicId] :
                new AdaptiveLearnStatistics(
                    0,
                    this.topic.questionList.length * this.QUEUE_CNT,
                    0
                );

        this._failedAttemptCount = topicStatsistics.failedAttemptCount;
        this._requiredAttemptCount = topicStatsistics.requiredAttemptCount;
        this._successfulAttemptCount = topicStatsistics.successfulAttemptCount;
    }

    private saveStatistics(): void {

        let statistics = this.persistentState.get(
            Feature.ADAPTIVE_LEARN_STATISTICS,
            {}
        )

        statistics[this.topicId] = new AdaptiveLearnStatistics(
            this._failedAttemptCount,
            this._requiredAttemptCount,
            this._successfulAttemptCount
        )

        this.persistentState.set(
            Feature.ADAPTIVE_LEARN_STATISTICS,
            statistics
        );
    }


    private saveTopicTestResult(): void {
        // load saved data
        let testResults: Object = this.persistentState.get(
            Feature.ADAPTIVE_TEST_RESULT,
            {}
        );

        // Assign current topic result
        testResults[this.topicId] = TestResult.PASSED;

        // Save new data
        this.persistentState.set(
            Feature.ADAPTIVE_TEST_RESULT,
            testResults
        );

    }

    private updateTopicTestResult(): void {

        if (this.topic.testResult != TestResult.FAILED) {

            this.topic.testResult = TestResult.FAILED;

            // load saved data
            let testResults: Object = this.persistentState.get(
                Feature.ADAPTIVE_TEST_RESULT,
                {}
            );

            // Assign current topic result
            testResults[this.topicId] = TestResult.FAILED;

            // Save new data
            this.persistentState.set(
                Feature.ADAPTIVE_TEST_RESULT,
                testResults
            );

        }
    }
}
