import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {

  cancelBtnLabelId: string;
  confirmBtnLabelId: string;
  messageId: string;
  titleId: string;

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public params: ConfirmDialogParams) {
    // Update view with given values
    this.cancelBtnLabelId = params.cancelBtnLabelId;
    this.confirmBtnLabelId = params.confirmBtnLabelId;
    this.titleId = params.titleId;
    this.messageId = params.messageId;
  }

  ngOnInit(): void {
  }

  onCancel():void {
    this.dialogRef.close(false);
  }

  onConfirm():void {
    this.dialogRef.close(true);
  }

}

export class ConfirmDialogParams {

  cancelBtnLabelId: string;
  confirmBtnLabelId: string;
  messageId: string;
  titleId: string;

}