import { Component, OnInit, Input } from '@angular/core';

import { ILanguage } from 'src/app/models/config/config-types';
/**
 * The component is used to display 
 * @author Ruslan Rubtsov
 * @version 1.0.1
 */
@Component({
  selector: 'app-language-menu-item',
  templateUrl: './language-menu-item.component.html',
  styleUrls: ['./language-menu-item.component.css']
})

/**
 * The class is the Language Menu Item component implementation
 */
export class LanguageMenuItemComponent implements OnInit {

  /**
   * Component parameter that specifies the menu item language
   * @see Language
   */
  @Input() language:ILanguage;

  constructor() { }

  /**
   * OnInit interface implementation
   * @see OnInit
   */
  ngOnInit(): void {
  }
}
