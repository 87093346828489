import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'answer-count',
  templateUrl: 'answer-count.component.html',
  styleUrls: ['answer-count.component.css'],
  imports: [CommonModule],
  standalone: true
})
export class AnswerCountComponent implements OnInit {

  @Input() count: number = 0;
  @Input() isCorrect: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
