import { Component, Input, OnInit } from '@angular/core';

/**
 * The component is used to display 
 * @author Ruslan Rubtsov
 * @version 1.0.1
 */
@Component({
  selector: 'test-question-text',
  templateUrl: './test-question-text.component.html',
  styleUrls: ['./test-question-text.component.css']
})
export class TestQuestionTextComponent implements OnInit {

  get textHtml(): string {
    return '#' + this.number + '  ' + this.text;
  }

  /**
   * Component parameter that 
   */
  @Input() number: string;
  /**
   * Component parameter that 
   */
  @Input() text: string;

  constructor() {
  }

  /**
   * OnInit interface implementation
   * @see OnInit
   */
  ngOnInit(): void {
  }

}
