import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, ReplaySubject, Subject } from "rxjs";
import { retry, take } from "rxjs/operators";
import { IAppConfig } from "src/app/global/services/app-config/i-app-config";
import { IProjConfig } from "src/app/global/services/proj-config/proj-config.interfaces";
import { ProjConfigService } from "src/app/global/services/proj-config/proj-config.service";
import { IAdScreenConfig, ILanguage, TestConfig } from "src/app/models/config/config-types";
import { TestDataConfig } from "src/app/models/test/test-data-config";
import { ILessonTopic } from "src/app/routed-modules/lesson/models/lesson-data-models";
import { IDataProvider } from "./i-data-provider";
import { ITestTopic } from "./i-test-data";

@Injectable({ providedIn: 'root' })
export class HttpDataProvider implements IDataProvider {

    private httpRequestRetryCount: number = 3;

    constructor(
        private http: HttpClient,
        private projConfig: ProjConfigService
    ) {
        this.appConfig.subscribe(
            config =>
                this.httpRequestRetryCount = config.httpRequestRetryCount
        );
    }

    get acceptLanguage(): Observable<string> {
        return this.http.get<string>(
            this.projConfig.acceptLanguageUrl
        );
    }

    get adScreenConfig(): Observable<IAdScreenConfig> {

        return this.http.get<IAdScreenConfig>(
            this.projConfig.url.adScreenConfig
        );
    }

    get appConfig(): Observable<IAppConfig> {

        return this.http.get<IAppConfig>(
            ProjConfigService.APP_CONFIG_URL
        );
    }

    get projConfigData(): Observable<IProjConfig> {

        return this.http.get<IProjConfig>(
            ProjConfigService.PROJ_CONFIG_URL
        );
    }

    getLanguageList(lang: string): Observable<ILanguage[]> {

        const languageListUrl: string = this.projConfig.languageListUrl(lang);
        return this.http.get<ILanguage[]>(languageListUrl).
            pipe(retry(this.httpRequestRetryCount));
    }

    getLessonTestDataConfig(
        lang: string,
        dataFolder: string
    ): Observable<TestDataConfig> {

        const configUrl: string = this.projConfig.lessonTestDataConfigUrl(
            lang,
            dataFolder
        );
        return this.http.get<TestDataConfig>(configUrl).
            pipe(retry(this.httpRequestRetryCount));

    }

    getLessonTestTopic(
        lang: string,
        dataFolder: string,
        topicId: string
    ): Observable<ITestTopic> {

        const configUrl: string = this.projConfig.lessonTestTopicUrl(
            lang,
            dataFolder,
            topicId
        );
        return this.http.get<ITestTopic>(configUrl).
            pipe(retry(this.httpRequestRetryCount));
    }


    getLessonTopicList(
        lang: string,
        testId: string
    ): Observable<ILessonTopic[]> {

        const subj$$: Subject<ILessonTopic[]> =
            new Subject<ILessonTopic[]>();

        this.getTestConfigList(lang).subscribe(
            configList => {
                const config: TestConfig =
                    configList.find(
                        value => value.testId == testId
                    );

                this.http.get<ILessonTopic[]>(
                    config.lesson.topicList
                ).subscribe(
                    topicList => {
                        subj$$.next(topicList);
                    }
                )
            }
        );

        return subj$$.asObservable().pipe(take(1));
    }

    getTestConfigList(lang: string): Observable<TestConfig[]> {

        const subj$$: ReplaySubject<TestConfig[]> =
            new ReplaySubject<TestConfig[]>();

        const testConfigListUrl: string =
            this.projConfig.testConfigListUrl(lang);

        const tcListObs$: Observable<TestConfig[]> =
            this.http.get<TestConfig[]>(testConfigListUrl).
                pipe(retry(this.httpRequestRetryCount));

        tcListObs$.subscribe(
            (tcList: TestConfig[]) => {

                // The deserialized from a JSON test config list
                // does not contain TestConfig metods. So replace the 
                // deserialized objects with their copies with methods
                for (
                    let i: number = 0;
                    i < tcList.length;
                    i++
                ) {
                    tcList[i] = new TestConfig(tcList[i]);
                }
                subj$$.next(tcList);
            },
            error => subj$$.error(error)
        );

        return subj$$.asObservable().pipe(take(1));

    }

    getTestDataConfig(
        lang: string,
        testId: string
    ): Observable<TestDataConfig> {
        const configUrl: string =
            this.projConfig.testDataConfigUrl(
                lang,
                testId
            );
        return this.http.get<TestDataConfig>(configUrl).
            pipe(retry(this.httpRequestRetryCount));

    }

    getTestTopic(
        lang: string,
        testId: string,
        topicId: string
    ): Observable<ITestTopic> {

        const testTopicUrl: string =
            this.projConfig.testTopicUrl(
                lang,
                testId,
                topicId
            );
        return this.http.get<ITestTopic>(testTopicUrl).
            pipe(retry(this.httpRequestRetryCount));
    }

}