import { Injectable } from '@angular/core';
import { Subject, Observable, ReplaySubject } from 'rxjs';

export class QuestionKey {
  topicId: string;
  questionNum: string;
  constructor(topicId: string, questionNum: string) {
    this.topicId = topicId;
    this.questionNum = questionNum;
  }
}

@Injectable()
export class TestPageCommunicationService {

  private answerSelect$$: Subject<number> =
    new Subject<number>();
  private error$$: Subject<string> =
    new Subject<string>();
  private examQuestionSelect$$: Subject<QuestionKey> =
    new Subject<QuestionKey>();
  private mainViewLoad$$: ReplaySubject<boolean> =
    new ReplaySubject<boolean>();
  private pictureZoom$$: ReplaySubject<QuestionKey> =
    new ReplaySubject<QuestionKey>();
  private statQuestionSelect$$: ReplaySubject<QuestionKey> =
    new ReplaySubject<QuestionKey>();
  private statTopicSelect$$: ReplaySubject<string> =
    new ReplaySubject<string>();

  answerSelect$: Observable<number> =
    this.answerSelect$$.asObservable();
  error$: Observable<string> =
    this.error$$.asObservable();
  examQuestionSelect$: Observable<QuestionKey> =
    this.examQuestionSelect$$.asObservable();
  mainViewLoad$: Observable<boolean> =
    this.mainViewLoad$$.asObservable();
  pictureZoom$: Observable<QuestionKey> =
    this.pictureZoom$$.asObservable();
  statQuestionSelect$: Observable<QuestionKey> =
    this.statQuestionSelect$$.asObservable();
  statTopicSelect$: Observable<string> =
    this.statTopicSelect$$.asObservable();

  constructor() {
  }

  reportError(messageId: string): void {
    this.error$$.next(messageId);
  }

  selectAnswer(index: number): void {
    this.answerSelect$$.next(index);
  }

  selectExamQuestion(
    topicId: string, questionNum: string
  ) {
    this.examQuestionSelect$$.next(
      new QuestionKey(
        topicId,
        questionNum
      )
    );
  }

  selectStatQuestion(
    topicId: string,
    questionNum: string
  ) {
    this.statQuestionSelect$$.next(
      new QuestionKey(
        topicId,
        questionNum
      )
    );
  }

  selectStatTopic(topicId: string): void {
    this.statTopicSelect$$.next(topicId);
  }

  mainViewLoad(success: boolean): void {
    this.mainViewLoad$$.next(success);
  }

  zoomPicture(
    topicId: string,
    questionNum: string
  ) {
    this.pictureZoom$$.next(
      new QuestionKey(
        topicId,
        questionNum
      )
    );
  }
}
