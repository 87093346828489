import { Feature } from "src/app/services/persistent-state/keys";
import { PersistentStateService } from "src/app/services/persistent-state/persistent-state.service";
import { StatisticsService } from "src/app/services/statistics.service";
import { TestConfig } from "src/app/routed-modules/test-config/data/test-config";
import { TestDataConfigTopic } from "src/app/models/test/test-data-config";

export abstract class TestQuestionListService {

    /**
    *   Public members
    */

    get successfulAttemptCount(): number {
        return 0;
    }

    get failedAttemptCount(): number {
        return 0;
    }

    get isAdaptiveLearnMode(): boolean {
        return false;
    }

    get isCurrentQuestionCorrectlyAnswered(): boolean {
        return this.statistics.isCorrectlyAnswered(
            this.questionNum
        )
    }

    get questionNum(): string {
        return this.topic.questionList[this._topicQuestionNum - 1];
    }

    get requiredAttemptCount(): number {
        return 0;
    }

    get testQuestionCount(): number {
        return this._testQuestionCount;
    }
    protected _testQuestionCount: number = 0;

    get testQuestionNum(): number {
        return this._testQuestionNum;
    }
    protected _testQuestionNum: number = 0;

    get topicId(): string {
        return this.topic.id;
    }

    get topicList(): TestDataConfigTopic[] {
        return this._topicList;
    }

    set topicList(topicList: TestDataConfigTopic[]) {

        this._topicList = this.applyFilter(topicList);

        // Calculate the total number of questions in the test
        this._testQuestionCount = 0;
        this.topicList.forEach(
            (topic) => {
                this._testQuestionCount +=
                    topic.questionList.length;
            }
        );

    }
    protected _topicList: TestDataConfigTopic[];

    get topicName(): string {
        return this.topicList == null ? '' : this.topic.name;
    }

    abstract get topicQuestionCount(): number;

    get topicQuestionNum(): number {
        return this._topicQuestionNum;
    }
    protected _topicQuestionNum: number = 0;

    get topic(): TestDataConfigTopic {
        return this.topicList[this._topicIdx];
    }

    /**
     * Constructor
     * @param persistentState The injectable persitent state service, which is used to staore the data 
     * persistent between the application executions.
     * @see PersistentStateService
     */

    constructor(
        protected persistentState: PersistentStateService,
        protected statistics: StatisticsService
    ) {

    }


    /**
     * Public methods
     */

    abstract next(): boolean;

    abstract previous(): boolean;

    abstract resetState(): void;

    // must be executed after this.topicList initialization;
    abstract restoreState(): void;

    abstract saveState(): void;

    selectQuestion(
        topicId: string,
        questionNum: string
    ) {
        this._topicIdx = this.topicList.findIndex(
            value => value.id == topicId
        );

        const questionIdx: number =
            this.topicList[this._topicIdx].questionList.findIndex(
                value => value == questionNum
            );
        this._topicQuestionNum = questionIdx + 1;

        this.calcTestQuestionNum();
    }

    abstract selectTopic(topicId: string): void;

    /**
    * Private members
    */
    protected _topicIdx: number;


    /**
     * Private methods
     */

    protected applyFilter(
        topicList: TestDataConfigTopic[]
    ): TestDataConfigTopic[] {

        let config: TestConfig = this.persistentState.get(
            Feature.TEST_CONFIG,
            new TestConfig()
        )

        let filter: boolean[] = config.topicCheckList;

        let filteredList: TestDataConfigTopic[] = [];

        topicList.forEach(

            (topic, index) => {

                if (filter[index]) {

                    let newTopic: TestDataConfigTopic =
                        new TestDataConfigTopic(
                            topic.id,
                            topic.name,
                            [],
                            config.onlyOfficial ?
                                topic.officialQuestionList : topic.questionList
                        );

                    // SOetimes the question list does not contain official questions
                    // and the question list is empty when only official questions
                    // filter is applied. Skip such topics
                    if (newTopic.questionList.length > 0) {
                        filteredList.push(newTopic);
                    }
                }
            }
        );

        return filteredList;
    }


    protected calcTestQuestionNum(): number {

        let prevTopicQuestionCnt: number = 0;
        this.topicList.forEach(
            (value, index) => {
                prevTopicQuestionCnt +=
                    (
                        index < this._topicIdx ? value.questionList.length : 0
                    )
            }
        );
        return prevTopicQuestionCnt + this._topicQuestionNum;
    }

}