import { Feature } from "src/app/services/persistent-state/keys";
import { PersistentStateService } from "src/app/services/persistent-state/persistent-state.service";
import { Injectable } from "@angular/core";
import { ISapiSubscription, SapiSubscription } from "../../models/subscription-api/sapi-subscription";

@Injectable({
    providedIn: 'root'
})
export class SapiSubscriptionStorageService {

    constructor(
        private persistentState: PersistentStateService
    ) {

    }

    public delete(): void {
        this.persistentState.delete(Feature.SUBSCRIPTION);
    }

    public get exists(): boolean {

        return this.persistentState.has(Feature.SUBSCRIPTION);
    }

    public load(): SapiSubscription {

        if (!this.exists) {
            return null;
        }

        const savedSubscription: ISapiSubscription =
            this.persistentState.get(
                Feature.SUBSCRIPTION,
                null,
                true
            );

        return new SapiSubscription(savedSubscription);
    }

 /**
 * Saves the provided subscription to persistent storage.
 *
 * @param subscription - The subscription object to be saved.
 */
    public save(subscription: SapiSubscription): void {
        // Save the subscription to persistent storage.
        this.persistentState.set(Feature.SUBSCRIPTION, subscription, true);

        // Update the subscription update time.
        this.persistentState.set(Feature.SUBSCRIPTION_UPDATE_TIME, Date.now());
    }

}