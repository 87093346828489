import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'toolbar-language-select',
  templateUrl: './toolbar-language-select.component.html',
  styleUrls: ['./toolbar-language-select.component.css']
})
export class ToolbarLanguageSelectComponent implements OnInit {

  public isLanguageMenuOn: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  onHideLanguageMenu(): void {
    this.isLanguageMenuOn = false;
  }

}
