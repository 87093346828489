import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';


import { ILanguage, LanguageId, TestConfig } from 'src/app/models/config/config-types'
import { AppConfigProvider } from './app-config-provider'
import { take } from 'rxjs/operators';
import { AppDataProvider } from 'src/app/providers/app-data/app-data-provider';
import { IAppConfig, IAppConfig_Payment, IAppConfig_SubscriptionApi, IInstallPromptConfig, IProfButton } from './i-app-config';
import { ProjConfigService } from '../proj-config/proj-config.service';
import { TranslateService } from '@ngx-translate/core';

/**
 * The service is used to provide different configuration settings
 * @author Ruslan Rubtsov
 * @version 1.0.1
 */
@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  private appConfig: IAppConfig;

  /**
   * Class constructor
   * @param appConfigProvider
   * @see AppConfigProvider
   * @param dataProvider
   * @see AppDataProvider
   */
  constructor(
    appConfigProvider: AppConfigProvider,
    private dataProvider: AppDataProvider,
    private projConfig: ProjConfigService,
    private translate: TranslateService
  ) {
    this.appConfig = appConfigProvider.appConfig;
  }

  get appTitle(): string {
    return this.appConfig.appTitle;
  }

  get appVersion(): string {
    return this.appConfig.appVesrion;
  }

  get authenticationServerUrl(): string {
    return this.appConfig.authentication?.serverUrl;
  }

  get dataRequestRetryCount(): number {
    return this.appConfig.dataRequestRetryCount;
  }

  get dataRequestRetryDelay(): number {
    return this.appConfig.dataRequestRetryDelay;
  }

  get documentTitle(): string {
    return this.appConfig.appTitle;
  }

  get headerImageUrl(): string {
    return this.projConfig.url.headerImage;
  }

  get httpRequestRetryCount(): number {
    return this.appConfig.httpRequestRetryCount;
  }

  get installPromptConfig(): IInstallPromptConfig {
    return this.appConfig.installPrompt;
  }

  get languageIdList(): LanguageId[] {
    return this.projConfig.languageIdList;
  }

  getLanguageList(lang: string): Observable<ILanguage[]> {
    return this.dataProvider.getLanguageList(lang);
  }

  get loadEventTimeout(): number {
    return this.appConfig.loadEventTimeout;
  }

  get noCredentialsUpdateTolerance(): number {
    return this.appConfig.
      authentication.
      noCredentialsUpdateTolerance;
  }

  get paymentData(): IAppConfig_Payment {
    return this.appConfig.payment;
  }

  get paymentResultUrl(): string {
    return window.location.protocol +
      "//" +
      window.location.host +
      this.appConfig.payment.resultUrl;
  }

  get payPalClientId(): string {
    return this.appConfig.payment.payPal.clientId;
  }

  get payPalSdkUrl(): string {
    return this.appConfig.payment.payPal.sdkUrl;
  }

  getProfButton(lang: string): IProfButton {
    return this.appConfig.profButtonList != null ?
      this.appConfig.profButtonList[lang] : null;
  }

  /**
   * Check whether the Ad Screen may be shown.
   * @returns true if the Ad Screen may be shown
   */
  get showAdScreen(): Observable<boolean> {

    if (this.appConfig.advertScreen == null) {
      return of(false);
    }

    // The date in the config is represented as a string, 
    // but Date is required
    const publicationDate: number =
      Date.parse(this.appConfig.advertScreen.publicationDate);

    // The adScreenDelay in the config is represented in days, 
    // but milliseconds are required
    const adScreenDelay: number =
      this.appConfig.advertScreen.delay * 24 * 60 * 60 * 1000;

    // Check if enough time has passed since the publicashion date
    if (Date.now() - publicationDate > adScreenDelay) {
      return of(true);
    }

    // Check the configuration on the server. 
    // It is possible to enable the ad screen before the predefined time
    // by setting the proper flag on the server
    const subj: ReplaySubject<boolean> = new ReplaySubject<boolean>();

    this.dataProvider.adScreenConfig.subscribe(
      config => {
        const ver: string = this.appConfig.advertScreen.version;
        subj.next(config[ver] != null ? config[ver] : false);
      },
      error => {
        subj.next(false);
      }
    );

    return subj.asObservable().pipe(take(1));
  }

  get subscriptionApi(): IAppConfig_SubscriptionApi {
    return this.appConfig.subscriptionApi;
  }


  get tutorialPart1Url(): string {
    return this.appConfig.tutorialPart1Url[this.translate.currentLang];
  }

  get tutorialPart2Url(): string {
    return this.appConfig.tutorialPart2Url[this.translate.currentLang];
  }

  getAllCategoriesTestConfig(lang: string): TestConfig {
    return this.appConfig?.allCategoriesTestConfig[lang];
  }

  getTestConfig(
    lang: string,
    testId: string
  ): Observable<TestConfig> {

    const subject: ReplaySubject<TestConfig> = new ReplaySubject();

    this.getTestConfigList(lang).subscribe(
      configList => {
        const config: TestConfig = configList.find(value => value.testId == testId);
        subject.next(config);
      },
      error => subject.error('login_page.connection_failed'));

    return subject.asObservable().pipe(take(1));
  }

  getTestConfigList(lang: string): Observable<TestConfig[]> {
    return this.dataProvider.getTestConfigList(lang);
  }

  get testPictureFolderUrl(): string {
    return this.projConfig.url.testImagesBase;
  }

  get userCredentialsVerificationPeriod(): number {
    return this.appConfig.authentication.userCredentialsVerificationPeriod;
  }

  get useUnofficialExamQuestions(): boolean {
    return this.appConfig.exam.useUnofficialQuestions;
  }
}
