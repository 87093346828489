import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { slideInAnimation } from 'src/app/global/animations/animations';
import { AppConfigService } from 'src/app/global/services/app-config/app-config.service';
import { PersistentStateService } from 'src/app/services/persistent-state/persistent-state.service';
import { LanguageId } from 'src/app/models/config/config-types';
import { HttpDataProvider } from 'src/app/providers/app-data/http-data-provider';
import { Feature } from 'src/app/services/persistent-state/keys';
import { SubscriptionService } from 'src/app/global/services/subscription-api/subscription.service';

/**
 * The component is used to display 
 * @author Ruslan Rubtsov
 * @version 1.0.1
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [slideInAnimation]
})
export class AppComponent implements OnInit {

  /**
   * Class constructor.
   * @param config The service which is used to obtain application configuration data stored in the external file
   * @see ConfigService
   * @param persistentState The injectable persitent state service, which is used to staore the data 
   * persistent between the application executions.
   * @see PersistentStateService
   * @param translate Internationalization service
   * @see TranslateService
   */
  constructor(
    private config: AppConfigService,
    private dataProvider: HttpDataProvider,
    private translate: TranslateService,
    private persistentState: PersistentStateService,
    private subscriptionService: SubscriptionService
  ) {

  }

  ngOnInit(): void {

    console.log("Initializing the application...");

    document.title = this.config.documentTitle;

    this.selectLanguage();

    this.updateSubscriptionWithUC();
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  private selectLanguage(): void {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang(LanguageId.German);

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    // First check if a language was already selected
    const lang: string = this.persistentState.get(Feature.CURRENT_LANGUAGE);
    if (lang != null) {
      this.translate.use(lang);
    } else {
      // If no language was selcted in past, then use German as default and try to find out the browser preferrable language
      this.translate.use(LanguageId.German);
      this.persistentState.set(Feature.CURRENT_LANGUAGE, LanguageId.German);

      this.getLangFromHttpHeader();
    }
  }

  private getLangFromHttpHeader(): void {
    const languageIdList = this.config.languageIdList;

    this.dataProvider.acceptLanguage.subscribe(
      acceptedLanguageList => {

        let minIndexOf = acceptedLanguageList.length;
        let langIndex = 0;
        for (let i = 0; i < languageIdList.length; i++) {
          let indexOf = acceptedLanguageList.indexOf(languageIdList[i]);
          if (indexOf != -1 && indexOf < minIndexOf) {
            minIndexOf = indexOf;
            langIndex = i;
          }
        }
        const lang: string = languageIdList[langIndex];

        this.persistentState.set(Feature.CURRENT_LANGUAGE, lang);
        this.translate.use(lang);
      },
      error => {
        console.error('getLangFromHttpHeader(). Error:', error)
      }
    );
  }

  /*
  Temporaty code to update from UserCredentials to Subscription
  */
  private updateSubscriptionWithUC(): void {
    let uc = this._fetchUserCredentials();

    if (uc!= null) {
      this.subscriptionService.login(
        uc.userID, 
        uc.password, 
        this.persistentState.deviceId
      );
    }
  }

  private _fetchUserCredentials() {
      let uc = null;
      let value = localStorage['x002'];
      if (value != null) {
        uc = JSON.parse(this._decode(value));
      }
      return uc;
  }
  private _decode(value: string): string {
    let arrValue = JSON.parse(value);
    let result = '';
    for (let i = 0; i < arrValue.length; i++) {
      result = result.concat(String.fromCharCode(arrValue[i]));
    }
    return result;

  }


}
