import { BrowserInfo } from "src/app/global/utils/browser-info";
import { SapiAddress } from "./sapi-address";
import { SapiDevice } from "./sapi-device";
import { SapiSubscription } from "./sapi-subscription";
import { SapiDeviceType } from "./enums/sapi-device-type.enum";

export class   SapiSubscriptionUpdate {

    public static fromSubscription(
        subscription: SapiSubscription
    ): SapiSubscriptionUpdate {

        return new SapiSubscriptionUpdate(
            subscription.startDate,
            subscription.length,
            subscription.userId,
            subscription.password,
            subscription.email,
            subscription.paymentId,
            structuredClone(subscription.address),
            structuredClone(subscription.devices)
        )

    }

    startDate: Date;
    length: number;
    userId: string;
    password: string;
    email: string;
    language: string;
    paymentId: string;
    address: SapiAddress;
    devices: SapiDevice[];

    constructor(
        startDate: Date,
        length: number,
        userId: string,
        password: string,
        email: string,
        paymentId: string,
        address: SapiAddress,
        devices: SapiDevice[]
    ) {
        this.startDate = startDate;
        this.length = length;
        this.userId = userId;
        this.password = password;
        this.email = email;
        this.paymentId = paymentId;
        this.address = address;
        this.devices = devices;
    }

    public get device(): SapiDevice {
        return this.devices.find(
            (device: SapiDevice) =>
                device.type === (
                    BrowserInfo.isMobile ?
                        SapiDeviceType.MOBILE :
                        SapiDeviceType.DESKTOP
                )
        );
    }

    public set device(newDevice: SapiDevice) {

        const idx: number = this.devices.findIndex(
            (device: SapiDevice) =>
                device.type === (
                    BrowserInfo.isMobile ?
                        SapiDeviceType.MOBILE :
                        SapiDeviceType.DESKTOP
                )
        )

        this.devices[idx] = newDevice;
    }

}
