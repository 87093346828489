<div class="container">
    <progress-bar [percent]="percent">
    </progress-bar>
    <div class="percent">{{percentString}}</div>

    <answer-count [count]="successfulAttemptCount"
                  [isCorrect]="true"
                  class="correct-answer-count">
    </answer-count>

    <answer-count [count]="failedAttemptCount"
                  [isCorrect]="false"
                  class="incorrect-answer-count">
    </answer-count>
</div>