<toolbar-toggle-button [icon]="'bookmarks'"
                       [(isOn)]="isButtonOn"
                       [toggleButton]="'bookmark'"
                       class="bookmark-button">

    <bookmark-menu [showAddBookmark]="showAddBookmark"
                   [showSelectBookmark]="showSelectBookmark"
                   [visible]="isBookmarksMenuOn"
                   (addBookmark)="onAddBookmark()"
                   (selectBookmark)="onSelectBookmark()"
                   (clickOutside)="onClickOutside()"
                   [ngClass]="menuAlignClass">
    </bookmark-menu>

    <div *ngIf="isBookmarkListOn">
        <ng-content>
        </ng-content>
    </div>
</toolbar-toggle-button>