import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from './environments/environment';

import { FileCacheService } from './app/services/file-cache.service';
import { AppModule } from './app/modules/app/app.module';
import { AppUpdateService } from './app/global/services/app-update/app-update.service';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .then(
    moduleRef => {

      // this is a temporary patch. It is required only for version 1.4.9
      // TODO: delete for the versions older tahn 1.4.9. At least consider 
      // moving the funstion invokation to file cache service. Note that the
      // code of the update serive is not new until reload. This is why it is
      // invoked here for now, when the current version does not have the latest version
      // of the update service
      // IT IS STILL NEEDED for edriver
      /*const appUpdate = moduleRef.injector.get(AppUpdateService);
      appUpdate.update_1_5_4();*/

      const fileCache = moduleRef.injector.get(FileCacheService);
      fileCache.initialize();

    }
  ).catch(err => console.error(err));

/*const onDeviceReady = () => {
  alert('onDeviceReady');
  platformBrowserDynamic().bootstrapModule(AppModule)
    .then(moduleRef => {
      let fileCache = moduleRef.injector.get(FileCacheService);
      fileCache.initialize();
    })
    .catch(err => console.error(err));
};
document.addEventListener('deviceready', onDeviceReady, false);
*/
