import { Component, Input, OnInit } from '@angular/core';
import { TestResult } from 'src/app/models/test/test-result';
import { ETopicMenuItemState, ITopicMenuItem } from 'src/app/routed-modules/lesson/models/lesson-data-models';

/**
 * The component is used to display 
 * @author Ruslan Rubtsov
 * @version 1.0.1
 */
@Component({
  selector: 'app-topic-menu-item',
  templateUrl: './topic-menu-item.component.html',
  styleUrls: ['./topic-menu-item.component.css']
})
export class TopicMenuItemComponent implements OnInit {

  topic: ITopicMenuItem;

  get disabled(): boolean {
    return this.topic.state == ETopicMenuItemState.DISABLED;
  }

  constructor() { }

  /**
   * OnInit interface implementation
   * @see OnInit
   */
  ngOnInit(): void {
  }

}
