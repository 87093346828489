import { Observable, ReplaySubject } from "rxjs";
import { TestResult } from "src/app/models/test/test-result";
import { PersistentStateService } from "src/app/services/persistent-state/persistent-state.service";

export interface ILessonPage {
    htmlFile: string;
    soundFile: string;
    title: string;
}

export enum ETopicMenuItemState {
    ENABLED,
    DISABLED
}

export interface ITopicMenuItem {
    id: string;
    name: string;
    state?: ETopicMenuItemState,
    testResult?: TestResult;
}

export interface ILessonTopic extends ITopicMenuItem {
    hasTest: boolean;
    pageList: ILessonPage[];
}

export interface ILessonBookmark {
    topicId: string;
    pageIdx: number;
}

export interface ILessonBookmarkList {
    [index: string]: number[];
}

export class LessonBookmarkList {

    $onUpdate: Observable<never>;

    private _bookmarkList: ILessonBookmarkList;
    private _subject: ReplaySubject<never>;

    constructor(persistentState: PersistentStateService) {
        this._bookmarkList = persistentState.lessonBookmarkList;
        this._subject = new ReplaySubject<never>();
        this.$onUpdate = this._subject.asObservable();
    }

    get hasAnyBookmark(): boolean {
        return (Object.keys(this._bookmarkList).length > 0);
    }

    addBookmark(bookmark: ILessonBookmark): void {
        if (this._bookmarkList[bookmark.topicId] == null) {
            this._bookmarkList[bookmark.topicId] = [];
        }
        this._bookmarkList[bookmark.topicId].push(bookmark.pageIdx);
        this._bookmarkList[bookmark.topicId].sort((a: number, b: number) => a - b);
        this._subject.next();
    }

    hasBookmark(bookmark: ILessonBookmark): boolean {
        const pageIdxList: number[] = this._bookmarkList[bookmark.topicId];
        return pageIdxList != null && pageIdxList.findIndex(value => value == bookmark.pageIdx) != -1;
    }

    hasTopic(topicId: string): boolean {
        return this._bookmarkList[topicId] != null;
    }

    save(persistentState: PersistentStateService): void {
        persistentState.lessonBookmarkList = this._bookmarkList;
    }
}