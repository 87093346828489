<div class="answer-container"
     (click)="onClick($event)">

    <answer-letter [index]="index"
                   [selected]="selected">

    </answer-letter>

    <div *ngIf="answer.picture.length > 0">
        <img [src]="imageBaseUrl+answer.picture">
    </div>

    <answer-text [text]="answer.text"
                 [commentsMode]="commentsMode"
                 [correct]="correct">

    </answer-text>
</div>

<div class="explanation-container"
     *ngIf="hasExplanation">
    <div class="explanation-left-padding"
         [@heightOpacity]="commentsMode ? 'visible' : 'invisible'">
    </div>

    <answer-explanation [@heightOpacity]="commentsMode ? 'visible' : 'invisible'"
                        [text]="answer.explanation">

    </answer-explanation>
</div>