import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { RouterUrlList } from 'src/app/models/url-list';
import { StartPageComponent } from '../../routed-modules/start-page/start-page/start-page.component';

const routes: Routes = [
  {
    path: RouterUrlList.AD_SCREEN,
    loadChildren: () =>
      import('../../routed-modules/ad-screen/ad-screen.module').
        then(m => m.AdScreenModule),
    data: { animation: 'AdScreen' }
  },
  {
    path: RouterUrlList.CLUB,
    loadChildren: () =>
      import('../../routed-modules/club-page/club-page.module').
        then(m => m.ClubPageModule),
    data: { animation: 'Club' }
  },
  {
    path: RouterUrlList.HELP,
    loadChildren: () =>
      import('../../routed-modules/help/help.module').
        then(m => m.HelpModule),
    data: { animation: 'Help' }
  },
  {
    path: RouterUrlList.LESSON,
    loadChildren: () =>
      import('../../routed-modules/lesson/lesson.module').
        then(m => m.LessonModule),
    data: { animation: 'Lesson' }
  },
  {
    path: RouterUrlList.LESSON_TEST + '/:dataFolder/:topicId',
    loadChildren: () =>
      import('../../routed-modules/lesson-test/lesson-test.module').
        then(m => m.LessonTestModule),
    data: { animation: 'LessonTest' }
  },
  {
    path: RouterUrlList.LOGIN,
    loadChildren: () =>
      import('../../routed-modules/login/login.module').
        then(m => m.LoginModule),
    data: { animation: 'Login' }
  },
  {
    path: RouterUrlList.LGOUT,
    loadChildren: () =>
      import('../../routed-modules/logout/logout.module').
        then(m => m.LogoutModule),
    data: { animation: 'Logout' }
  },
  {
    path: RouterUrlList.PAYMENT_RESULT + '/:orderId/:result',
    loadChildren: () =>
      import('../../routed-modules/payment-result/payment-result.module').
        then(m => m.PaymentResultModule),
    data: { animation: 'PaymentResul' }
  },

  /*  {
      path: RouterUrlList.PfPaymentSuccess + '/:orderId',
      component: PfPaymentSuccessComponent,
      data: { animation: 'PfPaymentSuccess' }
    },*/
  {
    path: RouterUrlList.IMAGE_ZOOM + '/:topicId/:questionId',
    loadChildren: () =>
      import('../../routed-modules/picture-zoom/picture-zoom.module').
        then(m => m.PictureZoomModule),
    data: { animation: 'PictureZoom' }
  },
  {
    path: RouterUrlList.PURCHASE,
    loadChildren: () =>
      import('../../routed-modules/purchase/purchase.module').
        then(m => m.PurchaseModule),
    data: { animation: 'Purchase' }
  },
  {
    path: RouterUrlList.SHOP_LIST,
    loadChildren: () =>
      import('../../routed-modules/shop-list/shop-list.module').
        then(m => m.ShopListModule),
    data: { animation: 'ShopList' }
  },
  {
    path: RouterUrlList.START_PAGE,
    component: StartPageComponent,
    data: { animation: 'StartPage' }
  },
  /*{
    path: RouterUrlList.TestOptionPage,
    component: TestOptionsPageComponent,
    data: { animation: 'TestOptions' }
  },*/
  {
    path: RouterUrlList.TEST_OPTIONS,
    loadChildren: () =>
      import('../../routed-modules/test-options/test-options.module').
        then(m => m.TestOptionsModule),
    data: { animation: 'TestOptions' }
  },
  {
    path: RouterUrlList.CONFIGURATION,
    loadChildren: () =>
      import('../../routed-modules/test-config/test-config.module').
        then(m => m.TestConfigModule),
    data: { animation: 'TopicFilter' }
  },
  {
    path: '', redirectTo: RouterUrlList.START_PAGE,
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: RouterUrlList.START_PAGE,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.
    forRoot(routes,
      {
        useHash: true,
        enableTracing: false
      })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
