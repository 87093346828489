import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { fadeInOutAnimation } from 'src/app/global/animations/animations';
import { ITopicMenuItem } from 'src/app/routed-modules/lesson/models/lesson-data-models';

@Component({
  selector: 'toolbar-topic-select',
  templateUrl: './toolbar-topic-select.component.html',
  styleUrls: ['./toolbar-topic-select.component.css'],
  animations: [
    fadeInOutAnimation
  ]
})
export class ToolbarTopicSelectComponent implements OnInit {

  @Input() lessonTestFolder: string;
  @Input() topicList: ITopicMenuItem[];  // it is used to pass the list to the TopicMenu component

  @Output() selectTopic: EventEmitter<string> = new EventEmitter<string>();

  isTopicMenuOn: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  onSelectTopic(topicId: string): void {
    this.isTopicMenuOn = false;
    // Notify parent about topic selection
    this.selectTopic.emit(topicId);
  }
}
