import { animate, animation, state, style, transition, trigger, useAnimation } from "@angular/animations";

export const heightOpacityAnimation =

    trigger(
        'heightOpacity',
        [
            state(
                'visible',
                style({
                    height: '*',
                    opacity: 1,
                    display: '{{displayValue}}'
                }),
                {
                    params:
                    {
                        displayValue: 'block'
                    }
                }
            ),
            state(
                'invisible',
                style({
                    height: 0,
                    opacity: 0,
                    display: 'none'
                })
            ),
            transition(
                'invisible => visible',
                [
                    style({
                        height: 0,
                        opacity: 0,
                        display: 'block'
                    }),
                    animate(
                        '0.4s ease-out',
                        style({
                            opacity: 1,
                            height: '*'
                        })
                    )
                ]
            ),
            transition(
                'visible => invisible',
                [
                    animate(
                        '0.3s ease-in',
                        style({
                            opacity: 0,
                            height: 0
                        })
                    )
                ]
            )
        ]
    );

