<div class="container">

    <div class="message"
         [innerHtml]="message">
    </div>

    <div *ngIf="message2"
         class="message2"
         [innerHtml]="message2">
    </div>

    <div class="button_bar">
        <round-button [backgroundColor]="'#0088CE'"
                      [labelId]="'alert_dialog.ok'|translate"
                      (click)="onConfirm()">
        </round-button>
    </div>
</div>