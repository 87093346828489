import {
  Component, EventEmitter,
  OnInit, Output, Input
} from '@angular/core';
import { fadeInOutAnimation } from 'src/app/global/animations/animations';


/**
 * The component is used to dsiplay a bookmark menu
 * @author Ruslan Rubtsov
 * @version 1.0.1
 */
@Component({
  selector: 'bookmark-menu',
  templateUrl: './bookmark-menu.component.html',
  styleUrls: ['./bookmark-menu.component.css'],
  animations: [fadeInOutAnimation]
})

/**
 * The clkass is the BookmarkMenu compontn implementation
 */
export class BookmarkMenuComponent implements OnInit {

  /**
   * Compoonent input parameter that is used to show/hide "Add bookmark" menu item
   * The menu item is hidded if the current question has alredy been bookmarked
   */
  @Input() showAddBookmark: boolean = true;

  /**
   * Component input parameter that is used to show/hide "Select bookmark" menu item.
   * The menu item is hidden when no question has been bookmarked yet.
   */
  @Input() showSelectBookmark: boolean = true;

  /**
   * Component input parameter that uis used to show/hide the whole menu
   */
  @Input() visible: boolean = false;

  /**
   * Component output parameter. The event, which is used to notify another component
   * when a user select "Add bookmark" menu item
   */
  @Output('addBookmark') addBookmark: EventEmitter<any> = new EventEmitter();

  /**
   * Component output parameter. The event, which is used to notify another component
   * when a user select "Select bookmark" menu item
   */
  @Output('selectBookmark') selectBookmark: EventEmitter<any> = new EventEmitter();

  constructor() { }

  /**
   * The method is an implementation of the OnInit interface
   * @see OnInit
   */
  ngOnInit(): void {
  }

  /**
   * The method hided the menu component
   */

  hide(): void {
    this.visible = false;
  }

  /**
   * Mouse click event handler, which is invoked when a user clicks on the "Add bookmark" menu item
   * Hides the menu and emits the add bookmark event (outpiut parameter)
   * @param event contains mouse click event information
   * @see MouseEvent
   */
  onAddBookamrk(event: MouseEvent): void {
    this.hide();
    this.addBookmark.emit();
    event.stopPropagation();
  }

  /**
   * Mouse click event handler, which is invoked when a user clicks on the "Select bookmark" menu item
   * Hides the menu and emits the select bookmark event (outpiut parameter)
   * @param event contains mouse click event information
   * @see MouseEvent
   */
  onSelectBookamrk(event: MouseEvent): void {
    this.hide();
    this.selectBookmark.emit();
    event.stopPropagation();
  }
}

