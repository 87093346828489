import { Component, Input, OnDestroy, OnInit, HostListener, EventEmitter, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { TimerService } from './timer-service/timer.service';

/**
 * The component is used to display exam timer
 * @author Ruslan Rubtsov
 * @version 1.0.1
 */
@Component({
  selector: 'exam-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.css']
})
export class TimerComponent implements OnDestroy, OnInit {

  /**
   * Component parameter that define timer update interval
   */
  /*@Input() period: number;*/
  /**
   * Component parameter that defines timeout value
   */
  /*@Input() set timeout(time: number) {
    if (time != null) {
      this._timeout = time;
      this.remainingTime = time;
      this.label = this.formatTime(time);
    }
  }
  get timeout(): number {
    return this._timeout;
  }*/

  /*private timeout: number = 0;*/
  /**
   * Component parameter that is used to detect an event when the timer must be stopped
   * because the Stop Exam button was pressed
   */
  //@Input() stop: Observable<null>;
  /**
   * The component output parameter, which is used to notify parent component about timeout
   */
  //@Output() timeIsUp: EventEmitter<null> = new EventEmitter<null>();

  /**
   * The string contains time left till the timeout
   */
  label: string;

  private destroyed$$: Subject<void>;
  /*private period: number;
  private remainingTime: number;*/
  private visible: boolean = false;

  constructor(private timerService: TimerService) { }

  /**
   * OnDestroy interface implementation
   * @see OnDestroy
   */
  ngOnDestroy(): void {
    this.destroyed$$.next();
  }

  /**
   * OnInit interface implementation
   * @see OnInit
   */
  ngOnInit(): void {
    // Component destroyed notification
    this.destroyed$$ = new Subject<void>();

    this.timerService.tick$.
      subscribe((time) => this.displayTime(time));
  }

  @HostListener('document:visibilitychange', ['$event'])
  public onVisibilityChange(event) {
    document.hidden ? this.timerService.pause() :
      this.timerService.resume();
  }


  private displayTime(time: number): void {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    this.label = `${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
  }
}
