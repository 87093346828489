import { Component, Input, OnInit } from '@angular/core';
import { TestResult } from 'src/app/models/test/test-result';
import { AssetUrl } from 'src/app/utils/asset-url';

/**
 * The component is used to indicate lesson test results (passed, failed, none)
 * @author Ruslan Rubtsov
 * @version 1.0.1
 */

@Component({
  selector: 'test-result-indicator',
  templateUrl: './test-result-indicator.component.html',
  styleUrls: ['./test-result-indicator.component.css']
})
export class TestResultIndicatorComponent implements OnInit {

  @Input() set result(result: TestResult) {
    this.imageSource = result == TestResult.PASSED ? AssetUrl.GREEN_LAMP_ICON :
      result == TestResult.FAILED ? AssetUrl.RED_LAMP_ICON : AssetUrl.WHITE_LAMP_ICON;
  }

  imageSource: string = AssetUrl.WHITE_LAMP_ICON;

  constructor() { }

  ngOnInit(): void {
  }

}
