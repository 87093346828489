import { Directive, Input } from "@angular/core";
import { ToggleButtonService } from "./toggle-button.service";
import { ToolbarToggleButtonComponent } from "./toolbar-toggle-button.component";

@Directive({
    selector: '[toggleButton]'
})

export class ToggleButtonDirective {

    @Input() toggleButton: string;
    @Input() togglableFromOutside: string = 'true';

    constructor(
        private host: ToolbarToggleButtonComponent,
        private service: ToggleButtonService
    ) { }

    ngOnInit(): void {
        this.host.togglableFromOutside = this.togglableFromOutside == 'true';
        this.service.addButton(this.toggleButton, this.host);
    }
}