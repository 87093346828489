import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { fadeInOutAnimation } from 'src/app/global/animations/animations';
import { Align } from 'src/app/models/enums/align.enum';

@Component({
  selector: 'toolbar-bookmarks',
  templateUrl: './toolbar-bookmarks.component.html',
  styleUrls: ['./toolbar-bookmarks.component.css'],
  animations: [fadeInOutAnimation]
})
export class ToolbarBookmarksComponent implements OnInit {

  @Input() menuAlign: string = 'center';
  @Input() showAddBookmark: boolean = false;
  @Input() showSelectBookmark: boolean = false;

  /**
   * The component output parameter, which is used to notify parent component
   * about Add Bookmark selection from the Bookmarsk menu
   */
  @Output() addBookmark: EventEmitter<void> = new EventEmitter<void>();
  /**
   * The component output parameter, which is used to notify parent component
   * about "Select bookmark" item selection
   */
  @Output() selectBookmark: EventEmitter<null> = new EventEmitter();

  set isButtonOn(isOn: boolean) {
    this._isButtonOn = isOn;

    // Show/hide the bookmarks menu
    this.isBookmarksMenuOn = isOn;
    this.isBookmarkListOn = false;
  }
  get isButtonOn(): boolean {
    return this._isButtonOn;
  }
  private _isButtonOn: boolean = false;

  get menuAlignClass(): string {
    return this.menuAlign == Align.CENTER ? 'bookmark-menu-align-center' :
      this.menuAlign == Align.LEFT ? 'bookmark-menu-align-left' :
        'bookmark-menu-align-right';
  }

  public isBookmarksMenuOn: boolean = false;
  public isBookmarkListOn: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  /**
   * Add Bookmark menu item selection handler
   */
  onAddBookmark(): void {
    this.addBookmark.emit();
    // Hide the menu
    this.isBookmarksMenuOn = false;
    // Unselect the button
    this.isButtonOn = false;
  }

  onClickOutside(): void {
    // Hide veyrthing
    this.isButtonOn = false;
    this.isBookmarksMenuOn = false;
    this.isBookmarkListOn = false;
  }

  /**
   * Select Bookmark menu item selection handler
   */
  onSelectBookmark(): void {
    this.selectBookmark.emit();
    // Hide the menu
    this.isBookmarksMenuOn = false;
    // Show the bookmark list
    this.isBookmarkListOn = true;
  }

  /**
   * Hides the Bookmark menu
   */
  /*onHideBookmarkMenu(): void {
    // Hide the menu
    this.isBookmarksMenuOn = false;
    // Unselect the button
    this.isButtonOn = false;
  }*/

}
