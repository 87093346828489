import { SapiDeviceType, SapiDeviceTypeFactory } from "./enums/sapi-device-type.enum";

export class SapiDevice {

    readonly type: SapiDeviceType;
    readonly deviceId: string;
    readonly model: string;
    readonly operationSystem: string;

    constructor(source: ISapiDevice) {
        this.type = SapiDeviceTypeFactory
            .fromString(source.type);
        this.deviceId = source.deviceId;
        this.model = source.model;
        this.operationSystem = source.operationSystem;
    }

}
export interface ISapiDevice {

    readonly type: string;
    readonly deviceId: string;
    readonly model: string;
    readonly operationSystem: string;
}