import { Injectable } from '@angular/core';

import { PersistentStateService } from './persistent-state/persistent-state.service';
import { Statistics } from '../models/statistics';
import { Feature } from './persistent-state/keys';

/**
 * The service is used to 
 * @author Ruslan Rubtsov
 * @version 1.0.1
 */
@Injectable({
  providedIn: 'root'
})
export class StatisticsService {

  readonly TOTAL_STATISTICS_KEY: string = 'total_statistics';

  get correctlyAnsweredQuestions(): Object {
    return this.persistentState.get(
      Feature.CORRECTLY_ANSWERED_QUESTIONS,
      {}
    );
  }

  set correctlyAnsweredQuestions(
    correctlyAnsweredQuestions: Object
  ) {

    this.persistentState.set(
      Feature.CORRECTLY_ANSWERED_QUESTIONS,
      correctlyAnsweredQuestions
    );
  }

  get incorrectlyAnsweredQuestions(): Object {
    return this.persistentState.incorrectlyAnsweredQuestions;
  }

  set incorrectlyAnsweredQuestions(
    incorrectlyAnsweredQuestions: Object
  ) {

    this.persistentState.incorrectlyAnsweredQuestions =
      incorrectlyAnsweredQuestions;
  }


  /**
   * Class constructor
   * @param persistentState The injectable persitent state service, which is used to staore the data 
   * persistent between the application executions.
   * @see PersistentStateService
   */
  constructor(
    private persistentState: PersistentStateService
  ) {

  }

  currentTestSytatisticsExist(): boolean {
    return this.persistentState.
      currentTestStatisticsExist();
  }

  getIncorrectAnswerCount(
    questionId: string
  ): number {
    let count: number =
      this.incorrectlyAnsweredQuestions[
      questionId
      ];
    return count == null ? 0 : count;
  }

  getStatsitstics(
    topicId: string
  ): Statistics {

    return this.persistentState.
      getStatistics(topicId);
  }

  isAnswered(
    questionId
  ): boolean {
    return this.correctlyAnsweredQuestions[questionId] != null ||
      this.incorrectlyAnsweredQuestions[questionId] != null;
  }

  isCorrectlyAnswered(
    questionId: string
  ): boolean {

    return this.correctlyAnsweredQuestions[questionId] != null;
  }

  resetCurrentTestStatistics(): void {
    this.persistentState.resetCurrentTestStatistics();
  }

  update(
    topicId: string,
    questionId: string,
    correctAnswerList: number[],
    selectionList: boolean[]
  ): void {


    // Check if the answer selections are correct
    let erroCount: number = this._checkAnswerList(
      correctAnswerList,
      selectionList
    );

    // Get current statistics data
    let correctlyAnsweredQuestions: Object =
      this.correctlyAnsweredQuestions;
    let incorrectlyAnsweredQuestions: Object =
      this.incorrectlyAnsweredQuestions;
    let topicStatistics =
      this.persistentState.getStatistics(topicId);
    let totalStatistics =
      this.persistentState.getStatistics(this.TOTAL_STATISTICS_KEY);

    // If all answers were unselected, then it is neither correctly nor incorrectly answered question
    // Remove the question statistics
    if (!selectionList.includes(true)) {

      if (questionId in incorrectlyAnsweredQuestions) {

        delete incorrectlyAnsweredQuestions[questionId];
        topicStatistics.incorrectAnswerCount--;
        totalStatistics.incorrectAnswerCount--;
      }

      if (questionId in correctlyAnsweredQuestions) {

        delete correctlyAnsweredQuestions[questionId];
        topicStatistics.correctAnswerCount--;
        totalStatistics.correctAnswerCount--;
      }

    } else {
      // Update the statistics data
      if (erroCount == 0) {

        //      if (correctlyAnsweredQuestions[questionId] == null) {
        if (!(questionId in correctlyAnsweredQuestions)) {

          correctlyAnsweredQuestions[questionId] = 0;
          topicStatistics.correctAnswerCount++;
          totalStatistics.correctAnswerCount++;
        }
        //      if (incorrectlyAnsweredQuestions[questionId] != null) {
        if (questionId in incorrectlyAnsweredQuestions) {

          delete incorrectlyAnsweredQuestions[questionId];
          topicStatistics.incorrectAnswerCount--;
          totalStatistics.incorrectAnswerCount--;
        }
      } else {
        //      if (incorrectlyAnsweredQuestions[questionId] == null) {
        if (!(questionId in incorrectlyAnsweredQuestions)) {

          topicStatistics.incorrectAnswerCount++;
          totalStatistics.incorrectAnswerCount++;
        }
        incorrectlyAnsweredQuestions[questionId] = erroCount;
        //      if (Object.keys(correctlyAnsweredQuestions).includes(questionId)) {
        if (questionId in correctlyAnsweredQuestions) {

          delete correctlyAnsweredQuestions[questionId];
          topicStatistics.correctAnswerCount--;
          totalStatistics.correctAnswerCount--;
        }
      }
    }

    // Save updated statistics data
    //this.persistentState.set(Feature.CORRECTLY_ANSWERED_QUESTIONS, correctlyAnsweredQuestions);
    this.correctlyAnsweredQuestions = correctlyAnsweredQuestions;
    this.incorrectlyAnsweredQuestions = incorrectlyAnsweredQuestions;

    this.persistentState.setStatistics(
      topicId,
      topicStatistics
    );
    this.persistentState.setStatistics(
      this.TOTAL_STATISTICS_KEY,
      totalStatistics
    );
  }

  private _checkAnswerList(
    correctAnswerList: number[],
    selectionList: boolean[]
  ): number {

    // Create array of correct selections
    let correctSelectionList: boolean[] =
      selectionList.map(
        () => false
      );
    correctAnswerList.forEach(
      value => {
        correctSelectionList[value - 1] = true;
      }
    );

    let errorCount: number = 0;
    for (
      let i: number = 0;
      i < selectionList.length;
      i++) {

      if (correctSelectionList[i] != selectionList[i]) {
        errorCount++;
      }
    }
    return errorCount;
  }
}
