import { AppConfigService } from 'src/app/global/services/app-config/app-config.service'
import { PersistentStateService } from 'src/app/services/persistent-state/persistent-state.service'
import { Observable, ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { SubscriptionCheckResult, SubscriptionService } from './subscription-api/subscription.service';
import { Feature } from 'src/app/services/persistent-state/keys';

@Injectable()
export class SubscriptionVerificatorService {

    private init$$: ReplaySubject<void> = new ReplaySubject<void>();
    private init$: Observable<void> = this.init$$.asObservable();

    private isTestProtected: boolean;
    private noCredentialsUpdateTolerance: number;
    private verificationPeriod: number;

    /**
     * Constructor for the SubscriptionVerificatorService.
     *
     * @param subscriptionService The SubscriptionService instance.
     * @param config The AppConfigService instance.
     * @param persistentState The PersistentStateService instance.
     * @param translate The TranslateService instance.
     */
    constructor(
        private subscriptionService: SubscriptionService,
        private config: AppConfigService,
        private persistentState: PersistentStateService,
        private translate: TranslateService
    ) {
        this.init();
    }

    verify(): Observable<SubscriptionCheckResult> {

        // Prepare return values
        const result$$: ReplaySubject<SubscriptionCheckResult> =
            new ReplaySubject<SubscriptionCheckResult>();

        const success: SubscriptionCheckResult = {
            valid: true,
            logout: false,
            errorMessageId: '',
            userId: ''
        }

        // Wait for data inititialization
        this.init$.pipe(take(1)).subscribe(
            () => {

                // Protected exams do not need user ceredentials verification
                if (!this.isTestProtected) {

                    result$$.next(success);
                } else {

                    // If the test is protected, then check the time since last verification
                    if (!this.verificationExpired()) {

                        result$$.next(success);
                    } else {

                        // If verification expired, then check user credentials on the
                        // Authorization Server

                        this.checkSubscription()
                            .subscribe(
                                checkResult => result$$.next(checkResult),
                                error => this.onCheckSubscriptionError(error)
                            );
                    }
                }
            }
        )

        return result$$.asObservable().pipe(take(1));
    }

    private checkSubscription(): Observable<SubscriptionCheckResult> {

        return this.subscriptionService.checkSubscription(
            this.persistentState.deviceId
        );
    }




    private init(): void {

        this.noCredentialsUpdateTolerance =
            this.config
                .noCredentialsUpdateTolerance;

        // Get the verification period from the application configuration file
        this.verificationPeriod =
            this.config
                .userCredentialsVerificationPeriod;

        // Check if the test is protected
        this.config.getTestConfig(
            this.translate.currentLang,
            this.persistentState.testId
        ).subscribe(
            testCofig => {
                // Save configuration data
                this.isTestProtected = testCofig.protected;

                // Inform subscribers that inititalization is complete
                this.init$$.next();
            }
        );
    }

    private onCheckSubscriptionError(
        result$$: ReplaySubject<SubscriptionCheckResult>
    ): void {

        // If the time since last successful update exceeds the noCredentialsUpdateTolerance
        // then report error. Otherwise report success
        if (this.timeSinceLastUpdate > this.noCredentialsUpdateTolerance) {

            result$$.next(
                {
                    valid: false,
                    logout: false,
                    errorMessageId: 'login_page.no_credentials_update_error',
                    userId: ''
                }
            )
        } else {

            result$$.next(
                {
                    valid: true,
                    logout: false,
                    errorMessageId: '',
                    userId: ''
                }
            )
        }
    }

    private get timeSinceLastUpdate(): number {

        let lastUpdateTime: number =
            this.persistentState.get(Feature.SUBSCRIPTION_UPDATE_TIME, 0);
        let now = new Date().getTime();

        return now - lastUpdateTime;
    }


    private verificationExpired(): boolean {
        return this.timeSinceLastUpdate > this.verificationPeriod;
    }
}