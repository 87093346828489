import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AlertDialogService } from "src/app/modules/common-components/alert-dialog/alert-dialog";
import { BrowserInfo } from "../../utils/browser-info";
import { AppConfigService } from "../app-config/app-config.service";

@Injectable()
export class HelpService {

    constructor(
        private alert: AlertDialogService,
        private appConfig: AppConfigService,
        private translate: TranslateService
    ) {
    }

    showPrompt(): void {

        // Show the message
        const messageId: string = BrowserInfo.isCrhomeBasedBrowser() ?
            'start_page.installation_prompt' :
            'start_page.installation_prompt_no_button';

        //this.alert.show('Display mode: '+InstallPrompt.getPWADisplayMode()+'<br><i> Is Chrome:</i> '+InstallPrompt.isCrhomeBasedBrowser());
        this.translate.get(messageId).
            subscribe(
                translation => {

                    const url1: string = this.appConfig.tutorialPart1Url;
                    const url2: string = this.appConfig.tutorialPart2Url;

                    const message: string =
                        translation.replace('__URL1__', url1).
                            replace('__URL2__', url2);

                    const copyright: string =
                        this.appConfig.installPromptConfig.showCopyright ? 
                            this.translate.instant('start_page.copyright'):
                            null;

                    this.alert.show(message, copyright);
                }
            );
    }
}