/**
 * A test config
 * @author Ruslan Rubtsov
 * @version 1.0.1
 */
export class TestConfig {

    static copy(
        other: TestConfig
    ): TestConfig {
        let newConfig: TestConfig = new TestConfig();
        //newConfig = Object.assign(other);
        newConfig.adaptiveMode = other.adaptiveMode;
        newConfig.allQuestions = other.allQuestions;
        newConfig.onlyOfficial = other.onlyOfficial;
        newConfig.topicCheckList = [...other.topicCheckList];
        return newConfig;
    }

    adaptiveMode: boolean = false;
    allQuestions: boolean = true;
    onlyOfficial: boolean = false;
    topicCheckList: boolean[] = [
        true, true, true, true, true,
        true, true, true, true, true,
        true, true, true, true, true,
        true, true, true, true, true
    ];

    equals(other: TestConfig): boolean {
        return this.adaptiveMode == other.adaptiveMode &&
            this.allQuestions == other.allQuestions &&
            this.onlyOfficial == other.onlyOfficial &&
            this.compareTopicCheckList(other.topicCheckList);
    }

    protected compareTopicCheckList(otherList: boolean[]): boolean {

        // if the other array is a falsy value, return
        if (!otherList)
            return false;

        // if the argument is the same array, 
        // we can be sure the contents are same as well
        if (otherList === this.topicCheckList)
            return true;

        // compare lengths - can save a lot of time 
        if (this.topicCheckList.length != otherList.length)
            return false;

        for (
            let i = 0;
            i < this.topicCheckList.length;
            i++
        ) {
            if (this.topicCheckList[i] != otherList[i]) {
                return false;
            }
        }
        return true;
    }
}