import { InjectionToken } from '@angular/core';
import { TestConfig } from 'src/app/routed-modules/test-config/data/test-config';
import { Feature } from 'src/app/services/persistent-state/keys';
import { PersistentStateService } from 'src/app/services/persistent-state/persistent-state.service';
import { StatisticsService } from 'src/app/services/statistics.service';
import { TestQuestionListAdaptiveLearnService } from './test-question-list-adaptive-learn.service';
import { TestQuestionListNormalService } from './test-question-list-normal.service';
import { TestQuestionListService } from './test-question-list.service';

export const TEST_QUESTION_LIST_SERVICE: InjectionToken<TestQuestionListService> =
    new InjectionToken<TestQuestionListService>('TestQuestionListService');

export function testQuestionListServiceFactory(
    persistentState: PersistentStateService,
    statistics: StatisticsService
): TestQuestionListService {

    const testConfig: TestConfig = persistentState.get(
        Feature.TEST_CONFIG,
        new TestConfig()
    );

    return testConfig.adaptiveMode ?
        new TestQuestionListAdaptiveLearnService(
            persistentState,
            statistics
        ) :
        new TestQuestionListNormalService(
            persistentState,
            statistics
        );

}