import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppConfigService } from 'src/app/global/services/app-config/app-config.service';
import { TestPageCommunicationService } from 'src/app/services/test/test-page-communication.service';

/**
 * The component is used to display 
 * @author Ruslan Rubtsov
 * @version 1.0.1
 */
@Component({
  selector: 'test-question-picture',
  templateUrl: './test-question-picture.component.html',
  styleUrls: ['./test-question-picture.component.css']
})
export class TestQuestionPictureComponent implements OnInit {



  @Input() showZoomButton: boolean = true;
  
  /**
   * Component parameter that 
   */
  @Input() set src(url: string) {
    this._imgUrl = url;
    // Fire the imageLoad event on timeout if the browser does not fire it
    this._timeout = setTimeout(() => this.onImageLoad(null),
      this.config.loadEventTimeout);
  }

  get src(): string {
    return this._imgUrl;
  }

  /**
   * The component output parameter, which is used to notify parent component
   */
  @Output('zoom') zoom: EventEmitter<any> = new EventEmitter();
  /**
   * The component output parameter, which is used to notify parent component
   */
  @Output('pictureOrientation') landscapeOrientation: EventEmitter<boolean> = new EventEmitter();

  private _imgUrl: string;
  private _timeout: any;

  /**
   * Class constructor.
   * @param communication The service, whichh is used for communication between compnents on the Test Page
   * @see TestPageCommunicationService
   * @param config The service, which is used to obtain application configuration data stored in the external file
   * @see ConfigService
   */
  constructor(
    private config: AppConfigService,
    private communication: TestPageCommunicationService) { }

  /**
   * OnInit interface implementation
   * @see OnInit
   */
  ngOnInit(): void {
  }

  onContextMenu(event: MouseEvent): void {
      event.preventDefault();
  }


  onImageLoad(event): void {
    // Notify the main view that the image is loaded
    clearTimeout(this._timeout);
    this.communication.mainViewLoad(true);

    // Inform the mainn view about the picture orientation
    if (event && event.target.naturalWidth && event.target.naturalHeight) {
      const img = event.target;
      setTimeout(() => {
        this.landscapeOrientation.emit(img.naturalWidth > img.naturalHeight);
      });
    }
  }

  onImageLoadError(): void {

    // Notify the main view that the image load failed
    clearTimeout(this._timeout);
    this.communication.mainViewLoad(false);
  }

  onZoomClick(event: MouseEvent): void {
    this.zoom.emit();
  }
}
