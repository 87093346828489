import { Visibility } from "src/app/global/animations/animations";

export interface ITestPageToolbarState {
    readonly bookmarksBtnVisibility: Visibility;
    readonly commentsBtnVisibility: Visibility;
    readonly navigationBtnVisibility: Visibility;
    readonly statisticsBtnVisibility: Visibility;
}

export class TestPageToolbarState {

    readonly ADAPTIVE_TEST_QUESTION: ITestPageToolbarState = {
        bookmarksBtnVisibility: Visibility.INVISIBLE,
        commentsBtnVisibility: Visibility.VISIBLE,
        navigationBtnVisibility: Visibility.VISIBLE,
        statisticsBtnVisibility: Visibility.VISIBLE
    };
    readonly ADAPTIVE_TEST_QUESTION_TYPEIN: ITestPageToolbarState = {
        bookmarksBtnVisibility: Visibility.INVISIBLE,
        commentsBtnVisibility: Visibility.VISIBLE,
        navigationBtnVisibility: Visibility.VISIBLE,
        statisticsBtnVisibility: Visibility.VISIBLE
    };
    readonly TEST_QUESTION: ITestPageToolbarState = {
        bookmarksBtnVisibility: Visibility.VISIBLE,
        commentsBtnVisibility: Visibility.VISIBLE,
        navigationBtnVisibility: Visibility.VISIBLE,
        statisticsBtnVisibility: Visibility.VISIBLE
    };
    readonly TEST_QUESTION_TYPEIN: ITestPageToolbarState = {
        bookmarksBtnVisibility: Visibility.VISIBLE,
        commentsBtnVisibility: Visibility.VISIBLE,
        navigationBtnVisibility: Visibility.VISIBLE,
        statisticsBtnVisibility: Visibility.VISIBLE
    };
    readonly TOTAL_STATISTICS: ITestPageToolbarState = {
        bookmarksBtnVisibility: Visibility.INVISIBLE,
        commentsBtnVisibility: Visibility.INVISIBLE,
        navigationBtnVisibility: Visibility.INVISIBLE,
        statisticsBtnVisibility: Visibility.VISIBLE
    };
    readonly TOPIC_STATISTICS: ITestPageToolbarState = {
        bookmarksBtnVisibility: Visibility.INVISIBLE,
        commentsBtnVisibility: Visibility.INVISIBLE,
        navigationBtnVisibility: Visibility.INVISIBLE,
        statisticsBtnVisibility: Visibility.VISIBLE
    };
    readonly EXPERT_COMMENTS: ITestPageToolbarState = {
        bookmarksBtnVisibility: Visibility.INVISIBLE,
        commentsBtnVisibility: Visibility.INVISIBLE,
        navigationBtnVisibility: Visibility.INVISIBLE,
        statisticsBtnVisibility: Visibility.VISIBLE
    };
    readonly IMAGE_ZOOM: ITestPageToolbarState = {
        bookmarksBtnVisibility: Visibility.INVISIBLE,
        commentsBtnVisibility: Visibility.INVISIBLE,
        navigationBtnVisibility: Visibility.INVISIBLE,
        statisticsBtnVisibility: Visibility.INVISIBLE
    };
    readonly QUESTION_LIST: ITestPageToolbarState = {
        bookmarksBtnVisibility: Visibility.INVISIBLE,
        commentsBtnVisibility: Visibility.INVISIBLE,
        navigationBtnVisibility: Visibility.INVISIBLE,
        statisticsBtnVisibility: Visibility.INVISIBLE
    };

}
