import { Inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Observable, ReplaySubject } from "rxjs";
import { take } from 'rxjs/operators';
import { AlertDialogComponent, AlertDialogParams } from "./alert-dialog.component";

@Injectable()
export class AlertDialogService {

    private isOpen: boolean = false;
    private afterClosed: Observable<any>;

    constructor(@Inject(MatDialog) private dialog: MatDialog) {
    }

    show(
        message: string,
        message2?: string
    ): Observable<any> {

        // If no dialog is alredy open, then just show the dialog
        if (!this.isOpen) {

            return this.openDialog(message, message2);

        } else {

            // If a dialog is already open, then wait for it to be closed
            // and then show the new one

            const subject: ReplaySubject<any> = new ReplaySubject<any>();

            this.afterClosed.subscribe(

                () => this.openDialog(message, message2).

                    subscribe(
                        () => subject.next()
                    )
            );


            return subject.asObservable().pipe(take(1));
        }
    }

    private openDialog(
        message: string,
        message2?: string
    ): Observable<any> {

        // Indicate that a dialog is open
        this.isOpen = true;

        const params: AlertDialogParams = {
            message: message,
            message2: message2
        }

        const dialogRef: MatDialogRef<AlertDialogComponent> =
            this.dialog.open(
                AlertDialogComponent,
                { data: params }
            );

        this.afterClosed = dialogRef.afterClosed();
        this.afterClosed.subscribe(
            () => {
                // Indicate that no dialog is open
                this.isOpen = false;
            }
        );

        return dialogRef.afterClosed();
    }
}