import { Injectable } from '@angular/core';

import { LanguageId, TestId } from 'src/app/models/config/config-types';
import { UserCredentials } from '../../models/user-credentials';
import { Statistics } from '../../models/statistics';
import { TestDataConfigTopic } from '../../models/test/test-data-config'
import { Feature, PersistentStateKey } from './keys';
import { TestResult } from 'src/app/models/test/test-result';
import { ILessonBookmarkList } from 'src/app/routed-modules/lesson/models/lesson-data-models';
import { TestMode } from 'src/app/models/test/test-mode';

/**
 * The service is used to 
 * @author Ruslan Rubtsov
 * @version 1.0.1
 */
@Injectable({
  providedIn: 'root'
})
export class PersistentStateService {

  private _deviceId: string;
  private _incorrectlyAnsweredQuestions: Object;
  private _statistics: Object;
  private _testAuthId: string;
  private _testId: TestId;

  private _map: any;

  /**
   * Class constructor.
   */
  constructor() {
    this._map = new Map<string, any>();
  }

  delete(feature: string): void {

    this._map.delete(feature);

    localStorage.removeItem(
      PersistentStateKey.get(feature)
    );
  }

  has(feature: string): boolean {

    return this._map.has(feature) ?
      true :
      localStorage[PersistentStateKey.get(feature)] != null;
  }

  // Do not forget to add the test category specific field to resetFields()!!!
  get(
    feature: string,
    defaultValue: any = null,
    decode: boolean = false
  ): any {
    return this._map.has(feature) ?
      this._map.get(feature) :
      this._fetch(feature, defaultValue, decode);
  }

  set(
    feature: string,
    value: any,
    encode: boolean = false
  ): void {

    this._map.set(feature, value);

    const key: string = PersistentStateKey.get(feature);
    const strValue: string = encode ?
      this.encode(JSON.stringify(value)) :
      JSON.stringify(value);

    localStorage[key] = strValue;
  }

  private _fetch(
    feature: string,
    defaultValue: any,
    decode: boolean
  ): any {

    let strValue = localStorage[PersistentStateKey.get(feature)];

    this._map.set(
      feature,
      strValue == null ?
        defaultValue :
        JSON.parse(decode ?
          this.decode(strValue) :
          strValue
        )
    );

    return this._map.get(feature);
  }

  getLessonTestResult(
    dataFolder: string,
    topicId: string
  ): TestResult {

    const storedValue: string = localStorage[
      dataFolder + '_' +
      PersistentStateKey.get(Feature.LESSON_TEST_RESULT)
    ];
    // If no lesson test were taken at all, return NONE
    if (storedValue == null) {
      return TestResult.NONE;
    }
    // If this topic test were not taken, then return NONE.
    // Otherwise return the stored result
    let categoryResults: Object = JSON.parse(storedValue);
    return categoryResults[topicId] == null ?
      TestResult.NONE : categoryResults[topicId];
  }

  setLessonTestResult(
    dataFolder: string,
    topicId: string,
    result: TestResult
  ): void {

    let storedValue: string = localStorage[
      dataFolder + '_' +
      PersistentStateKey.get(Feature.LESSON_TEST_RESULT)
    ];

    let categoryResults: Object = storedValue == null ?
      {} : JSON.parse(storedValue);

    categoryResults[topicId] = result;
    localStorage[
      dataFolder + '_' +
      PersistentStateKey.get(Feature.LESSON_TEST_RESULT)
    ] = JSON.stringify(categoryResults);
  }

  /**
  * Old not refactored code
  * 
  */
  resetCurrentTestStatistics(): void {
    this.delete(Feature.CORRECTLY_ANSWERED_QUESTIONS);

    localStorage.removeItem('test_' + PersistentStateKey.get('answerSelection'));
    localStorage.removeItem(PersistentStateKey.get('incorrectlyAnsweredQuestions'));
    localStorage.removeItem(PersistentStateKey.get('statistics'));
    this._incorrectlyAnsweredQuestions = null;
    this._statistics = null;
  }

  currentTestStatisticsExist(): boolean {
    return localStorage[PersistentStateKey.get('statistics')] != null;
  }

  clearAnswerInputList(testMode: TestMode): void {
    localStorage.removeItem(testMode + '_' + PersistentStateKey.get('answerInput'));
  }

  getAnswerInput(testMode: TestMode,
    questionNumber: string): string {

    let value = localStorage[testMode + '_' + PersistentStateKey.get('answerInput')];
    if (value == null) {
      return '';
    } else {
      let answerInputList: Object = JSON.parse(value);
      return answerInputList[questionNumber] != null ? answerInputList[questionNumber] : '';
    }
  }

  setAnswerInput(testMode: TestMode,
    questionNumber: string,
    input: string) {
    let value = localStorage[testMode + '_' + PersistentStateKey.get('answerInput')];
    let answerInputList = (value != null) ? JSON.parse(value) : {};
    answerInputList[questionNumber] = input;
    localStorage[testMode + '_' + PersistentStateKey.get('answerInput')] = JSON.stringify(answerInputList);
  }


  clearAnswerSelectionList(prefix: string): void {
    localStorage.removeItem(`${prefix}_${PersistentStateKey.get('answerSelection')}`);
  }

  getAnswerSelectionList(prefix: string,
    questionNumber: string,
    answerCnt: number): boolean[] {

    let value = localStorage[`${prefix}_${PersistentStateKey.get('answerSelection')}`];
    if (value == null) {
      return this.createDefaultAnswerSelectionList(answerCnt);
    } else {
      let selectionList: Object = JSON.parse(value);
      return selectionList[questionNumber] != null ? selectionList[questionNumber] :
        this.createDefaultAnswerSelectionList(answerCnt);
    }
  }

  setAnswerSelectionList(
    prefix: string,
    questionNumber: string,
    selections: boolean[]
  ) {
    const key: string = `${prefix}_${PersistentStateKey.get('answerSelection')}`;
    const value = localStorage[key];
    const selectionsList = (value != null) ?
      JSON.parse(value) :
      {};

    selectionsList[questionNumber] = selections;

    localStorage[key] = JSON.stringify(selectionsList);
  }

  set bookmarkList(list: TestDataConfigTopic[]) {
    localStorage[PersistentStateKey.get('bookmarkList')] = JSON.stringify(list);
  }

  get bookmarkList(): TestDataConfigTopic[] {
    const storedValue: string = localStorage[PersistentStateKey.get('bookmarkList')];
    let list: TestDataConfigTopic[] = storedValue == null ? [] : JSON.parse(storedValue);
    return list;
  }


  get incorrectlyAnsweredQuestions(): Object {
    if (this._incorrectlyAnsweredQuestions == null) {
      this.fetchIncorrectlyAnsweredQuestions();
    }
    return this._incorrectlyAnsweredQuestions;
  }

  set incorrectlyAnsweredQuestions(value: Object) {
    if (this._incorrectlyAnsweredQuestions == null) {
      this.fetchIncorrectlyAnsweredQuestions();
    }
    this._incorrectlyAnsweredQuestions = value;
    localStorage[PersistentStateKey.get('incorrectlyAnsweredQuestions')] = JSON.stringify(this._incorrectlyAnsweredQuestions);
  }

  get deviceId(): string {
    if (this._deviceId == null) {
      this.fetchDeviceId();
    }
    return this._deviceId;
  }

  set lessonBookmarkList(list: ILessonBookmarkList) {
    localStorage[PersistentStateKey.get('lessonBookmarkList')] = JSON.stringify(list);
  }

  get lessonBookmarkList(): ILessonBookmarkList {
    const storedValue: string = localStorage[PersistentStateKey.get('lessonBookmarkList')];
    let list: ILessonBookmarkList = storedValue == null ? {} : JSON.parse(storedValue);
    return list;
  }

  set lessonWarningPlayed(played: boolean) {
    localStorage[PersistentStateKey.get('lessonWarningPlayed')] = JSON.stringify(played);
  }

  get lessonWarningPlayed(): boolean {
    const storedValue: string = localStorage[PersistentStateKey.get('lessonWarningPlayed')];
    const enabled: boolean = storedValue == null ? false : JSON.parse(storedValue);
    return enabled;
  }

  get purchaseEmail(): string {

    let email: string = localStorage[PersistentStateKey.get('purchaseEmail')];
    return (email != null) ? email : '';
  }

  set purchaseEmail(email: string) {
    localStorage[PersistentStateKey.get('purchaseEmail')] = email;
  }

  getStatistics(topicId: string): Statistics {
    // If statistics was not fetched from the local storage, then fetch it
    if (this._statistics == null) {
      this.fetchStatistics(topicId);
    }
    if (this._statistics[topicId] == null) {
      this._statistics[topicId] = new Statistics();
      localStorage[PersistentStateKey.get('statistics')] = JSON.stringify(this._statistics);
    }
    return this._statistics[topicId];
  }

  setStatistics(topicId: string, statistics: Statistics): void {
    // If statistics was not fetched from the local storage, then fetch it
    if (this._statistics == null) {
      this.fetchStatistics(topicId);
    }
    this._statistics[topicId] = statistics;
    localStorage[PersistentStateKey.get('statistics')] = JSON.stringify(this._statistics);
  }

  get testAuthId(): string {

    if (this._testAuthId == null) {
      let id: string = localStorage[PersistentStateKey.get(Feature.TEST_AUTH_ID)];
      this._testAuthId = (id != null) ? JSON.parse(id) : '';
    }
    return this._testAuthId;
  }

  set testAuthId(id: string) {
    if (this._testAuthId != id) {
      this._testAuthId = id;
      localStorage[PersistentStateKey.get(Feature.TEST_AUTH_ID)] = JSON.stringify(id);
    }
  }


  get testId(): TestId {

    if (this._testId == null) {
      let id: string = localStorage[PersistentStateKey.get(Feature.TEST_ID)];
      this._testId = (id != null) ? JSON.parse(id) : TestId.Student;
    }
    return this._testId;
  }

  set testId(id: TestId) {
    if (this._testId != id) {
      this._testId = id;
      localStorage[PersistentStateKey.get(Feature.TEST_ID)] = JSON.stringify(id);
      // Some data is test specific. Therefore if the test id changes, then the preloaded data must be reset to null
      // and the data relevant to the new test loaded from sorage
      this.resetFields();
      PersistentStateKey.testId = id;
    }
  }

  private createDefaultAnswerSelectionList(answerCnt: number) {
    let selections: boolean[] = new Array<boolean>(answerCnt);
    selections.fill(false);
    return selections;
  }

  private decode(value: string): string {
    let arrValue = JSON.parse(value);
    let result = '';
    for (let i = 0; i < arrValue.length; i++) {
      result = result.concat(String.fromCharCode(arrValue[i]));
    }
    return result;

  }

  private encode(value: string): string {

    let result = new Array(value.length);
    for (let i = 0; i < value.length; i++) {
      result[i] = value.charCodeAt(i);
    }

    return JSON.stringify(result);
  }

  private fetchIncorrectlyAnsweredQuestions() {
    let value = localStorage[PersistentStateKey.get('incorrectlyAnsweredQuestions')];
    this._incorrectlyAnsweredQuestions = (value == null ? {} : JSON.parse(value));
  }

  private fetchDeviceId(): void {
    let value = localStorage[PersistentStateKey.get('deviceId')];
    if (value == null) {
      this._deviceId = new Date().getTime().toString();
      localStorage[PersistentStateKey.get('deviceId')] = this.encode(this._deviceId);
    } else {
      this._deviceId = this.decode(value);
    }
  }

  private fetchStatistics(topicId: string): void {
    let value: string = localStorage[PersistentStateKey.get('statistics')];

    // If no statistics found in the local storage
    // Then create new statistics with default values
    if (value == null) {
      this._statistics = {};
      this._statistics[topicId] = new Statistics();
      localStorage[PersistentStateKey.get('statistics')] = JSON.stringify(this._statistics);
    } else {
      this._statistics = JSON.parse(value);
    }

  }

  // Some data is test specific. Therefore if the test id changes, then the preloaded data must be reset to null
  // and the data relevant to the new test loaded from sorage
  resetFields(): void {
    this._incorrectlyAnsweredQuestions = null;
    this._statistics = null;
    this._map.delete(Feature.ADAPTIVE_LEARN_QUEUE_LIST);
    this._map.delete(Feature.ADAPTIVE_LEARN_STATISTICS);
    this._map.delete(Feature.ADAPTIVE_TEST_RESULT);
    this._map.delete(Feature.CORRECTLY_ANSWERED_QUESTIONS);
    this._map.delete(Feature.CURRENT_LESSON_PAGE);
    this._map.delete(Feature.CURRENT_TEST_QUESTION_IDX);
    this._map.delete(Feature.CURRENT_TEST_TOPIC_IDX);
    this._map.delete(Feature.TEST_CONFIG);
  }
}

