import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { ExamRoutingModule } from './exam-routing.module';
import { ExamPageComponent } from './exam-page/exam-page.component';
import { TimerComponent } from './timer/timer.component';
import { TestModule } from '../test/test.module';
import { TranslateGVarLoader } from 'src/app/utils/translate-gvar-loader';
import { CommonComponentsModule } from 'src/app/modules/common-components/common-components.module';

// AoT requires an exported function for factories
export function TranslateLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
  //return new TranslateGVarLoader();
}


@NgModule({
  declarations: [ExamPageComponent,
    TimerComponent],
  imports: [
    CommonModule,
    CommonComponentsModule,
    ExamRoutingModule,
    MatProgressSpinnerModule,
    TestModule,
    TranslateModule.forChild({
      defaultLanguage: 'de',
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [
  ]
})
export class ExamModule { }
