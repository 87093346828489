import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SystemService {

  get browser(): string {
    const agent: string = navigator.userAgent.toLowerCase();
    /*console.warn('agent', agent);
    console.warn('agent.indexOf("edge")', agent.indexOf("edge"));
    console.warn('agent.indexOf("edg/")', agent.indexOf("edg/"));
    console.warn('agent.indexOf("opr")', agent.indexOf("opr"));
    console.warn('agent.indexOf("chrome")', agent.indexOf("chrome"));
    console.warn('window[chrome]', window['chrome']);
    console.warn('agent.indexOf("firefox")', agent.indexOf("firefox"));
    console.warn('agent.indexOf("safari")', agent.indexOf("safari"));*/
    switch (true) {
      case agent.indexOf("edge") > -1: return "MS Edge";
      case agent.indexOf("edg/") > -1: return "Edge ( chromium based)";
      case agent.indexOf("opr") > -1 && !!window['opr']: return "Opera";
      case agent.indexOf("chrome") > -1 && !!window['chrome']: return "Chrome";
      case agent.indexOf("trident") > -1: return "MS IE";
      case agent.indexOf("firefox") > -1: return "Mozilla Firefox";
      case agent.indexOf("safari") > -1: return "Safari";
      default: return "other";
    }
  }

  get platform(): string {
    return navigator.platform;
  }

  constructor() { }
}
