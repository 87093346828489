<div class="navigation-button"
     (click)="onPrevous($event)">
     <img [@visibility]="showLeftButton ? 'visible' : 'invisible'"
          [src]="leftArrowImgUrl">
</div>

<!--<test-result-indicator *ngIf="testResult != 'none'"
[result]="testResult"></test-result-indicator>-->

<img [@visibility]="showImage ? 'visible' : 'invisible'"
     [src]="imageUrl">
<div *ngIf="!showImage"
     class="label">
     <span [@visibility]="!showLabelFromId ? 'visible' : 'invisible'"
           class="label-text">
          {{label}}
     </span>
     <span [@visibility]="showLabelFromId ? 'visible' : 'invisible'"
           class="label-text">
          {{labelId | translate}}
     </span>
</div>
<div class="navigation-button"
     (click)="onNext($event)">
     <img [@visibility]="showRightButton ? 'visible' : 'invisible'"
          [src]="rightArrowImgUrl">
</div>