<div [@visibility]="visible ? 'visible' : 'invisible'"
     (clickOutside)="visible=false">
    <app-bookmark-menu-item *ngIf="showAddBookmark"
                            [labelId]="'test_page.add_bookmark'"
                            (click)="onAddBookamrk($event)">
    </app-bookmark-menu-item>
    <app-bookmark-menu-item *ngIf="showSelectBookmark"
                            [labelId]="'test_page.select_bookmark'"
                            (click)="onSelectBookamrk($event)">
    </app-bookmark-menu-item>
</div>