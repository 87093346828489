import { Component, OnInit, Input } from '@angular/core';

/**
 * The component is used to display a bookmark menu item.
 * @see BookmarkMenuComponent
 * @author Ruslan Rubtsov
 * @version 1.0.1
 */
@Component({
  selector: 'app-bookmark-menu-item',
  templateUrl: './bookmark-menu-item.component.html',
  styleUrls: ['./bookmark-menu-item.component.css']
})

/**
 * The class is an implmentation of the bookmark menu item component.
 * @see BookmarkMenuComponent
 */
export class BookmarkMenuItemComponent implements OnInit {

  /**
   * Component parameter that contains the id of the string, then will be displayed on the menu item.
   * The ID is used by the TranslateService to obtain the string in the current language.
   * @see TranslateService
   */
  @Input() labelId: string;

  constructor() { }

  /**
   * OnInit interface implementation
   * @see OnInit
   */
  ngOnInit(): void {
  }
}
