export interface IButtonIcon {
    readonly url: string;
}

export class ButtonIcon {
    static readonly eUniversity =  {
        url:'assets/images/ui/toolbar/e-university.png'
    } 
    static readonly exit = {
        url: 'assets/images/ui/test_page/exit.png'
    }
    static readonly help = {
        url: 'assets/images/ui/toolbar/help.png'
    }
    static readonly stop = {
        url: 'assets/images/ui/toolbar/stop_icon.png'
    }
}

export interface IToggleButtonStateIcons {
    readonly on: string;
    readonly off: string;
}

