import { BrowserInfo } from "src/app/global/utils/browser-info";
import { ITestButtonParams } from "src/app/modules/common-components/test-button/test-button-params";

/**
 * The Language data structure
 * @author Ruslan Rubtsov
 * @version 1.0.1
 */
export interface ILanguage {
    readonly id: LanguageId;
    readonly name: string;
}

/**
 * The Language ID list
 * @author Ruslan Rubtsov
 * @version 1.0.1
 */
export enum LanguageId {
    English = 'en',
    German = 'de',
    French = 'fr',
    Italian = 'it'
}

/**
 * The test category ID list
 * @author Ruslan Rubtsov
 * @version 1.0.1
 */
export enum TestId {
    AA1B = 'e.driver_a_a1_b',
    FG = 'e.driver_f_g',
    M = 'e.driver_m',
    BPT = 'e.driver_truck_bpt',
    C_CE = 'e.driver_truck_c_ce',
    C1_D1 = 'e.driver_truck_c1_d1',
    CZV_ARV = 'e.driver_truck_czv_arv',
    CZV_GT = 'e.driver_truck_czv_gt',
    CZV_PT = 'e.driver_truck_czv_pt',
    D = 'e.driver_truck_d',
    Student = 'student'
}

/**
 * A test category information data structure
 * @author Ruslan Rubtsov
 * @version 1.0.2
 * 
 */
export class TestConfig {

    readonly fullName: string;
    readonly protected: boolean;                    // Indicates whether a login is required to access the test
    readonly testId: TestId;                        // Id of the test which is common for all languages

    readonly lesson?: ITestConfig_Lesson;

    // Start page buttons configuration
    readonly button: ITestButtonParams;

    // Club page configuration
    readonly clubPage: ITestConfig_ClubPage;

    // Payment information
    readonly shopList: ITestConfig_ShopList;

    // The Id of the test used by the Authentication server
    get authId(): string {
        return BrowserInfo.isMobile ?
            this.authIdMobile :
            this.authIdPc;
    }
    protected readonly authIdMobile: string;        // Authentication test id for mobile exams
    protected readonly authIdPc: string;            // Authentication test id for web/pc exams

    constructor(other: TestConfig) {

        for (const key in other) {
            if (key != 'authId') {
                this[key] = other[key];
            }
        }
    }


}

/**
 * A test category information data structure
 * @author Ruslan Rubtsov
 * @version 1.0.2
 */
export interface ITestConfig_ClubPage {
    //readonly imgSrc: string;
    readonly text: string;
    readonly textBuyOnline: string;
    readonly textShopList: string;
}

/**
 * A lesson information data structure
 * @author Ruslan Rubtsov
 * @version 1.0.2
 */
export interface ITestConfig_Lesson {
    readonly testFolder: string;    // The name of the folder, that contains lesson test data files
    readonly topicList: string;     // URL of the JSON file, which contains list of the test lecures topics structure
}

/**
 * A test category information data structure
 * @author Ruslan Rubtsov
 * @version 1.0.2
 */
export interface ITestConfig_ShopList {
    readonly imageUrl: string;
    readonly title: string;
    readonly text: string;
}


/**
 * Iformation about a file to be cached
 * @author Ruslan Rubtsov
 * @version 1.0.1
 */
export interface IWorkboxCacheEntry {
    readonly revision: string;
    readonly url: string;
}

/**
 * External configuration that defines whether the AdScreen must be shown
 * Indexed by the app version string
 * @author Ruslan Rubtsov
 * @version 1.0.2
 */
export interface IAdScreenConfig {
    [index: string]: boolean;
}