import {
    trigger, animateChild, group,
    transition, animate, style, query, state
} from '@angular/animations';

export enum Visibility {
    INVISIBLE = 'invisible',
    VISIBLE = 'visible',
    VOID = 'void'
}
export const fadeInOutAnimation =
    trigger(
        'visibility',
        [
            state(
                'visible',
                style(
                    {
                        opacity: 1,
                        display: '{{displayValue}}'
                    }
                ),
                {
                    params:
                    {
                        displayValue: 'block'
                    }
                }
            ),
            state(
                'invisible',
                style(
                    {
                        opacity: 0,
                        display: 'none'
                    }
                )
            ),
            transition(
                'invisible => visible',
                [
                    style(
                        {
                            opacity: 0,
                            display: 'block'
                        }
                    ),
                    animate(
                        '0.4s ease-out',
                        style(
                            {
                                opacity: 1
                            }
                        )
                    )
                ]),
            transition(
                'visible => invisible',
                [
                    animate(
                        '0.4s ease-in',
                        style(
                            {
                                opacity: 0
                            }
                        )
                    )
                ])
        ]
    );

const leftTransition: string[] = [
    'AdScreen => StartPage',
    'Club => Login',
    'ExamPage => TestOptions',
    'ExpertComments => TopicStatistics',
    'Help => *',
    'Lesson => TestOptions',
    'Login => StartPage',
    'Logout => *',
    'PaymentResul => *',
    'PictureZoom => ExpertComments',
    'PictureZoom => TestPage',
    'Purchase => Club',
    'ShopList => Club',
    'TestOptions => AdScreen',
    'TestOptions => Login',
    'TestOptions => StartPage',
    'TestPage => TestOptions',
    'TopicFilter => TestOptions',
    'TopicStatistics => TotalStatistics',
    'TotalStatistics => TestQuestion',
    'LessonTest => Lesson'
];
const rightTransition: string[] = [
    '* => Help',
    '* => Logout',
    '* => PaymentResul',
    'Club => Purchase',
    'Club => ShopList',
    'ExpertComments => PictureZoom',
    'Login => Club',
    'Login => TestOptions',
    'StartPage => AdScreen',
    'StartPage => Login',
    'StartPage => TestOptions',
    'TestOptions => ExamPage',
    'TestOptions => Lesson',
    'TestOptions => TestPage',
    'TestOptions => TopicFilter',
    'TestQuestion => TotalStatistics',
    'TestPage => PictureZoom',
    /*'TestPage => TotalStatistics',*/
    'TopicStatistics => ExpertComments',
    'TotalStatistics => TopicStatistics',
    'Lesson => LessonTest'
];
export const slideInAnimation =
    trigger('routeAnimations',
        [
            //transition('TestPage => TestOptions,TestOptions => StartPage, PictureZoom => TestPage, TotalStatistics => TestPage, TopicStatistics => TotalStatistics, ExpertComments => TopicStatistics, PictureZoom => ExpertComments, TopicFilter => TestOptions, Login => *',
            transition(leftTransition.join(),
                [
                    style({ position: 'relative' }),
                    query(':enter, :leave',
                        [
                            style({
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                height: '100%',
                                width: '100%'
                            })
                        ]
                    ),
                    query(':enter',
                        [
                            style({ left: '-100%' })
                        ]
                    ),
                    query(':leave', animateChild()),
                    group(
                        [
                            query(':leave',
                                [
                                    animate('500ms ease-out', style({ left: '100%' }))
                                ]
                            ),
                            query(':enter',
                                [
                                    animate('500ms ease-out', style({ left: '0%' }))
                                ]
                            )
                        ]
                    ),
                    query(':enter', animateChild())
                ]
            ),
            //transition('TestOptions => TestPage,StartPage => TestOptions, TestPage => PictureZoom, TestPage => TotalStatistics, TotalStatistics => TopicStatistics, TopicStatistics => ExpertComments, ExpertComments => PictureZoom, TestOptions => TopicFilter, * => Login',
            transition(rightTransition.join(),
                [
                    style({ position: 'relative' }),
                    query(':enter, :leave',
                        [
                            style({
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                height: '100%',
                                width: '100%'
                            })
                        ]
                    ),
                    query(':enter',
                        [
                            style({ left: '100%' })
                        ]
                    ),
                    query(':leave', animateChild()),
                    group(
                        [
                            query(
                                ':leave',
                                [
                                    animate('500ms ease-out', style({ left: '-100%' }))
                                ]
                            ),
                            query(
                                ':enter',
                                [
                                    animate('500ms ease-out', style({ left: '0%' }))
                                ]
                            )
                        ]
                    ),
                    query(':enter', animateChild())
                ]
            )
        ]
    );

export const imageFadeInOutAnimation =
    trigger('image_visibility', [
        state(Visibility.VISIBLE, style({
            opacity: 1,
        })),
        state(Visibility.INVISIBLE, style({
            opacity: 0,
        })),
        transition('invisible => visible', [
            animate('0.2s ease-out')
        ]),
        transition('visible => invisible', [
            animate('0.2s ease-in')
        ])
    ]);
