import { Component, OnInit, Input } from '@angular/core';

/**
 * The component is used to display a button with a rounded coreners.
 * e.g. Start Test button on the Test Topic Filter Page component
 * @author Ruslan Rubtsov
 * @version 1.0.1
 */
@Component({
  selector: 'round-button',
  templateUrl: './round-button.component.html',
  styleUrls: ['./round-button.component.css']
})
export class RoundButtonComponent implements OnInit {

  /**
   * Component parameter that defines the buttob background color
   */
  @Input() backgroundColor: string;
  /**
   * Component parameter that defines ID of the button title
   */
  @Input() labelId: string;

  constructor() { }

  /**
   * OnInit interface implementation
   * @see OnInit
   */
  ngOnInit(): void {
  }

}
