export interface ISapiAddress {
    readonly city: string;
    readonly company: string;
    readonly country: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly street: string;
    readonly zip: string;
}
export class SapiAddress {

    readonly city: string;
    readonly company: string;
    readonly country: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly street: string;
    readonly zip: string;

    constructor(source: ISapiAddress) {
        this.city = source.city;
        this.company = source.company;
        this.country = source.country;
        this.firstName = source.firstName;
        this.lastName = source.lastName;
        this.street = source.street;
        this.zip = source.zip;
    }
}
