import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'adaptive-test-progress',
  templateUrl: './adaptive-test-progress.component.html',
  styleUrls: ['./adaptive-test-progress.component.css']
})
export class AdaptiveTestProgressComponent implements OnInit {

  @Input() failedAttemptCount: number = 0;

  @Input() set requiredAttemptCount(count: number) {

    if (count == 0)
      return;

    this._requiredAttemptCount = count;

    this.calcPrecent();
  }

  get requiredAttemptCount(): number {
    return this._requiredAttemptCount;
  }

  private _requiredAttemptCount: number = Number.MAX_SAFE_INTEGER;

  @Input() set successfulAttemptCount(count: number) {

    this._successfulAttemptCount = count;

    this.calcPrecent();
  }

  get successfulAttemptCount(): number {
    return this._successfulAttemptCount;
  }

  private _successfulAttemptCount: number = 0;

  get percent(): number {
    return this._percent;
  }

  get percentString(): string {
    return this._percentString;
  }
  private _percentString: string = '';

  private _percent: number = 0;

  constructor() { }

  ngOnInit(): void {
  }

  private calcPrecent(): void {
    this._percent = (this._successfulAttemptCount * 100) /
      this._requiredAttemptCount;
    this._percentString = this._percent.toFixed(1) + '%';
  }
}
