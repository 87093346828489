import { ETopicMenuItemState, ITopicMenuItem } from "src/app/routed-modules/lesson/models/lesson-data-models";
import { TestResult } from "./test-result";

/**
 * A test category information
 * @author Ruslan Rubtsov
 * @version 1.0.1
 */
export class TestDataConfig {
    readonly id: string;
    readonly officialExam: boolean;
    readonly oralExam: boolean;
    readonly examDuration: number;
    readonly examQuestionsCount: number;
    readonly topicList: TestDataConfigTopic[];
}

/**
 * A test category topic information
 * @author Ruslan Rubtsov
 * @version 1.0.1
 */
export class TestDataConfigTopic
    implements ITopicMenuItem {

    public static totalQuestionsCount(
        useOnlyOfficialQuestions: boolean,
        topicList: TestDataConfigTopic[]
    ): number {

        let totalQuestionsCount: number = 0;
        topicList.forEach(
            topic =>
                totalQuestionsCount +=
                useOnlyOfficialQuestions ?
                    topic.officialQuestionList.length :
                    topic.questionList.length
        );
        return totalQuestionsCount;
    }

    readonly id: string;
    readonly name: string;
    readonly officialQuestionList: string[];
    readonly oralQuestionList: string[];
    readonly questionList: string[];
    readonly questionData: Object;
    readonly answerList: number[][];

    state?: ETopicMenuItemState;
    testResult?: TestResult;


    constructor(
        id: string,
        name: string,
        officialQuestionList: string[],
        questionList: string[],
        questionData?: Object
    ) {
        this.id = id;
        this.name = name;
        this.officialQuestionList = officialQuestionList;
        this.questionList = questionList;
        this.questionData = questionData;
    }
}
export class TestDataConfigTopic_QuestionData {
    readonly answerCount: number;
    readonly correctAnswerList: number[];
}


/**
 * A test question type (multiple_choice,type_in)
 * @author Ruslan Rubtsov
 * @version 1.1.0
 */

export enum TestQuestionType {
    MULTIPLE_CHOICE = 'multiple_choice',
    TYPE_IN = 'type_in'
}

/**
 * A test question iformation
 * @author Ruslan Rubtsov
 * @version 1.0.1
 */
export class TestQuestion {

    public static fromJSON(jsonData: Object): TestQuestion {
        let newQuestion: TestQuestion = new TestQuestion;
        return Object.assign(newQuestion, jsonData);
    }

    public get hasPicture(): boolean {
        if (this._hasPicture == null) {
            this._hasPicture = this.picture != null && this.picture.trim().length > 0;
            /*console.warn('this.picture != null', this.picture != null);
            console.warn('this.picture:', this.picture);
            console.warn('this.picture.trim().length > 0', this.picture.trim().length > 0);
            console.warn('this.picture.trim().length', this.picture.trim().length);
            console.warn('this.picture.trim()', this.picture.trim());*/
        }
        return this._hasPicture;
    }
    private _hasPicture: boolean = null;

    readonly number: string;
    readonly type: TestQuestionType;
    readonly text: string;
    readonly correctAnswers: number[];
    readonly asa: boolean;
    readonly picture: string;
    readonly pictureOrientation: string;
    readonly answerList: TestAnswer[];
    errorCount?: number;
    idx?: number;
    isAnswered?: boolean;
    isCorrectlyAnswered?: boolean;
    landscape?: boolean;
}

/**
 * A test question answer information
 * @author Ruslan Rubtsov
 * @version 1.0.1
 */
export class TestAnswer {
    readonly picture: string;
    readonly text: string;
    readonly explanation: string;
}

