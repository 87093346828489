import { TestDataConfigTopic } from "src/app/models/test/test-data-config";
import { Feature } from "src/app/services/persistent-state/keys";
import { TestQuestionListService } from "./test-question-list.service";

export class TestQuestionListNormalService extends TestQuestionListService {

    get topicQuestionCount(): number {
        return this.topic.questionList.length;
    }

    next(): boolean {

        if (this._topicQuestionNum < this.topic.questionList.length) {
            this._topicQuestionNum++;
        } else {
            if (this._topicIdx < this.topicList.length - 1) {

                this._topicIdx++;
                this._topicQuestionNum = 1;
            } else {
                return false;
            }
        }

        this._testQuestionNum++;

        return true;
    }

    previous(): boolean {

        if (this._topicQuestionNum > 1) {
            this._topicQuestionNum--;
        } else {
            if (this._topicIdx > 0) {

                this._topicIdx--;
                this._topicQuestionNum = this.topic.questionList.length;
            } else {
                return false;
            }
        }

        this._testQuestionNum--;

        return true;
    }    

    resetState(): void {
        this._topicIdx = 0;
        this._topicQuestionNum = 0;
        this._testQuestionNum = this.calcTestQuestionNum();
    }

    restoreState(): void {

        this._topicIdx = this.persistentState.get(
            Feature.CURRENT_TEST_TOPIC_IDX,
            0
        );

        this._topicQuestionNum = this.persistentState.get(
            Feature.CURRENT_TEST_QUESTION_IDX,
            1
        );
        this._testQuestionNum = this.calcTestQuestionNum();
    }
    saveState(): void {
        this.persistentState.set(
            Feature.CURRENT_TEST_QUESTION_IDX,
            this.topicQuestionNum
        );
        this.persistentState.set(
            Feature.CURRENT_TEST_TOPIC_IDX,
            this._topicIdx
        );

    }
    
    selectTopic(topicId: string): void {

        this._topicIdx = this.topicList.findIndex(
            element => element.id === topicId
        );
        this._topicQuestionNum = 1;
        this._testQuestionNum = this.calcTestQuestionNum();
    }


}