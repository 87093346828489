import { Injectable } from '@angular/core';
import { PersistentStateService } from './persistent-state/persistent-state.service';
import { Workbox } from 'workbox-window';
import { Feature } from './persistent-state/keys';
import { TranslateService } from '@ngx-translate/core';
import { AlertDialogService } from '../modules/common-components/alert-dialog/alert-dialog';
import { AppUpdateService } from '../global/services/app-update/app-update.service';
import { ProjConfigService } from '../global/services/proj-config/proj-config.service';

/**
 * The service is used to 
 * @author Ruslan Rubtsov
 * @version 1.0.1
 */
@Injectable({
  providedIn: 'root'
})
export class FileCacheService {

  private wb: Workbox;
  private swUrl: string;

  /**
   * Class constructor
   * @param persistentState The injectable persitent state service, 
   * which is used to staore the data 
   * persistent between the application executions.
   * @see PersistentStateService
   */
  constructor(
    private alert: AlertDialogService,
    private appUpdate: AppUpdateService,
    private persistentState: PersistentStateService,
    private projConfig: ProjConfigService,
    private translate: TranslateService
  ) {

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.onmessage =
        event => {
          this.appUpdate.update(event.data.appVersion);
        };
    }
  }

  cacheTestFiles(testId: string): void {

    const url = `sw-${testId}.js`;
    this.persistentState.set(Feature.SW_FILE_URL, url);
    this._initWorkbox(url);
  }

  checkForUpdate(): void {
    if (this.wb != null) {
      this.wb.update();
    }
  }

  initialize(): void {
    this._initWorkbox(
      this.persistentState.get(
        Feature.SW_FILE_URL,
        this.projConfig.defaultServiceWorker
      )
    );
  }

  private _initWorkbox(swUrl: string): boolean {

    let result: boolean = true;
    if ('serviceWorker' in navigator) {
      if (this.wb == null || this.swUrl != swUrl) {
        this.swUrl = swUrl;
        this.wb = new Workbox(swUrl);

        /*this.wb.addEventListener('installed', (event) => {
          //console.log('Installed event:',event)
          if (!event.isUpdate) {
            alert('The service worker was installed for the first time.');
          } else {
            alert('The service worker was updated');
          }
        });*/

        /*this.wb.addEventListener('controlling', (event) => {
          alert('The service worker is controlling the page');
        });*/

        /*this.wb.addEventListener('activated', (event) => {
          if (!event.isUpdate) {
            alert('Activated event. The application is updated');
            //window.location.reload();
          } else {
            alert('Activated event. The application is NOT updated');
          }
        });*/

        // Add an event listener to detect when the registered
        // service worker has installed but is waiting to activate.
        /*this.wb.addEventListener('waiting', (event) => {

          alert('The service worker is waiting');

          // `event.wasWaitingBeforeRegister` will be false if this is
          // the first time the updated service worker is waiting.
          // When `event.wasWaitingBeforeRegister` is true, a previously
          // updated same service worker is still waiting.
          // You may want to customize the UI prompt accordingly.

          // Assumes your app has some sort of prompt UI element
          // that a user can either accept or reject.
          //if (confirm('New version of the application is downloaded, do you want to update? May take two reloads.')) {
          // Assuming the user accepted the update, set up a listener
          // that will reload the page as soon as the previously waiting
          // service worker has taken control.
          //alert('New version of the application is downloaded. The page will be reloaded.')
          this.wb.addEventListener('controlling', (event) => {
            alert('The service worker is controlling the page')
            //window.location.reload();
          });

          // Send a message telling the service worker to skip waiting.
          // This will trigger the `controlling` event handler above.
          // Note: for this to work, you have to add a message
          // listener in your service worker. 
          this.wb.messageSW({ type: 'SKIP_WAITING' });
          //}
        });*/

        // TEMP DISABLE CACHING 
        this.wb.register();

      } else {
        // TEMP DISABLE CACHING 
        this.wb.update();
      }
    } else {
      result = false;
      this.translate.get('file_cache.browser_unsupported').
        subscribe(
          translation => {
            this.alert.show(translation);
          }
        );
    }

    return result;
  }
}



