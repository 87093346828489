import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit, HostListener } from '@angular/core';
import { fadeInOutAnimation, Visibility } from '../../../global/animations/animations'
import { Observable, Subject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { CheckUserCredentialsResult } from '../../../global/services/authorization/authorization.service'
import { QuestionKey, TestPageCommunicationService } from '../../../services/test/test-page-communication.service';
import { PersistentStateService } from 'src/app/services/persistent-state/persistent-state.service'
import { RouterUrlList } from 'src/app/models/url-list';
import { TestDataConfig, TestDataConfigTopic, TestQuestion, TestQuestionType } from 'src/app/models/test/test-data-config';
import { TestDataService } from 'src/app/services/test/test-data.service';
import { ExamScoresService } from 'src/app/services/exam-scores.service';
import { AppConfigService } from 'src/app/global/services/app-config/app-config.service';
import { BookmarkService, BookmarkPersistence, Bookmark } from 'src/app/global/services/bookmark/bookmark.service';
import { TestQuestionComponent } from 'src/app/routed-modules/test-question/test-question.component';
import { TestMode } from 'src/app/models/test/test-mode';
import { TestQuestionParams } from 'src/app/models/test-question-params';
import { ExamModel } from '../exam-model';
import { Feature } from 'src/app/services/persistent-state/keys';
import { AlertDialogService } from 'src/app/modules/common-components/alert-dialog/alert-dialog';
import { ExamQuestionListComponent } from 'src/app/routed-modules/exam-question-list/exam-question-list.component';
import { OralQuestionSetRandomizer } from '../randomizers/oral-question-set-randomizer';
import { takeUntil } from 'rxjs/operators';
import { TimerService } from '../timer/timer-service/timer.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmDialogParams } from 'src/app/modules/common-components/confirm-dialog/confirm-dialog.component';
import { TestView, TestViewType } from '../../test/test-view';
import { SubscriptionVerificatorService } from 'src/app/global/services/subscription-verificator.service';

/**
 * The component is used to display the test exam page
 * @author Ruslan Rubtsov
 * @version 1.0.1
 */
@Component({
  selector: 'app-exam-page',
  templateUrl: './exam-page.component.html',
  styleUrls: ['./exam-page.component.css'],
  animations: [fadeInOutAnimation],
  providers: [
    BookmarkService,
    TimerService,
    TestDataService,
    TestPageCommunicationService,
    SubscriptionVerificatorService
  ]
})
export class ExamPageComponent implements OnDestroy, OnInit {

  /**
   * Keyboard key press handler. It is used to navigate to the next/previous question using
   * keyboard left and right keys. It is used mostly for quicknavigation while debugging and testing
   * @param event 
   */
  @HostListener('document:keydown', ['$event'])
  public onKeyPress(event) {
    if (this.questionLoadComplete) {
      const key = window.event ? event.keyCode : event.which;
      switch (key) {
        case 37: // LeftArrow
          this.onPrevious();
          break;
        case 39: // RightArrow
          this.onNext();
          break;
      }
    }
  }

  public get commentsMode(): boolean {
    return this._commentsMode;
  }

  /**
   * ID of the error message (e.g. "No Internet connection available") string.
   */
  errorMessageId: string;
  /**
   * Text shown on the page header
   */
  headerLabel: string;
  /**
   * Main view is the component between the Header and Toolbar components.
   * The variable is used to show/hide a main view during transition from one main view to another
   */
  mainViewVisibility: Visibility = Visibility.VISIBLE;

  /**
   * Prevents loading new question until loading current one is complete
   * Otherwise a lot of problems occur during fast looking through the questions
   */
  questionLoadComplete: boolean = false;

  /**
   * It is used to show/hide Back or Previous button on the page header
   */
  showBackButton: boolean = false;
  /**
   * It is used to show/hide image on the page header
   */
  showHeaderImage: boolean = false;
  /**
   * It is used to show/hide progress indicator
   */
  showLoadingProgress: boolean = true;

  //readonly stop$: Observable<void>;
  /**
   * It is used to show/hide the Next button on the page header
   */
  showNextButton: boolean = true;

  showExitButton: boolean = false;
  showStopButton: boolean = true;
  /**
   * Sequential number of the current question
   */
  examQuestionNum: number;
  /**
   * Total exam question nu,ber
   */
  examQuestionCount: number;

  showBookmarkButton: boolean = true;
  showNavigationButton: boolean = true;
  /**
   * It is used to show hide "Add bookmark" item of the bookmars menu
   * It is hidden if the question has already been bookmarked
   */
  showAddBookmark: boolean = true;
  /**
   * It is used to show hide "Select bookmark" item of the bookmars menu
   * It is hidden if no question has already been bookmarked
   */
  showSelectBookmark: boolean = true;

  /**
   * Sequential number of the current question among the topic questions
   */
  topicQuestionNum: number;

  /**
   * The property indicates if the question list is shown
   */
  get questionListOn(): boolean {
    return this._questionListOn;
  }

  set questionListOn(isOn: boolean) {
    this._questionListOn = isOn;
    this.onToggleQuestionList(isOn);
  }
  private _questionListOn: boolean;

  private readonly FADE_ANIMATION_DURATION = 400;

  private _model: ExamModel;

  private _commentsMode: boolean;
  private oralExam: boolean;
  private timeout: any;
  private question: TestQuestion;
  private topicIdx: number;
  private topicList: TestDataConfigTopic[];

  private testQuestionComponent: TestQuestionComponent;

  private deleteBookmarkSubscription: Subscription;
  private destroyed: Subject<void>;

  private officialExam: boolean = false;

  private testId: string;

  /**
   * Class constructor.
   * @param bookmarks The service is used to handle bookmarks
   * @see BookmarkService
   * @param config Teh service which is used to obtain application configuration data stored in the external file
   * @see ConfigService
   * @param communication The service, whichh is used for communication between compnents on the Test Page
   * @see TestPageCommunicationService
   * @param examScores The service is used to calculate the exam score.
   * @see ExamScoresService
   * @param location A service that applications can use to interact with a browser's URL.
   * @see Location
   * @param persistentState The injectable persitent state service, which is used to store the data 
   * persistent between the application executions.
   * @see PersistentStateService
   * @param route The service is used for component navigation
   * @see ActivatedRoute
   * @param router The service is used for component navigation
   * @see Router
   * @param testData The service  which is used to load test data (topic list data, etc.)
   * @see TestDataService
   * @param translate Internationalization service
   * @see TranslateService
   */
  constructor(
    private alert: AlertDialogService,
    private bookmarks: BookmarkService,
    private config: AppConfigService,
    private communication: TestPageCommunicationService,
    private dialog: MatDialog,
    private examScores: ExamScoresService,
    private location: Location,
    private persistentState: PersistentStateService,
    private route: ActivatedRoute,
    private router: Router,
    private testData: TestDataService,
    private timer: TimerService,
    private translate: TranslateService,
    private ucVerificator: SubscriptionVerificatorService
  ) {
    this._model = new ExamModel();
  }

  /**
   * OnInit interface implementation
   * @see OnInit
   */
  ngOnInit(): void {

    this.destroyed = new Subject<void>();

    this.communication.error$.
      pipe(takeUntil(this.destroyed)).
      subscribe((messageId: string) =>
        this.onError(messageId));

    this.communication.examQuestionSelect$.
      pipe(takeUntil(this.destroyed)).
      subscribe((params: QuestionKey) =>
        this.onQuestionSelect(params));

    this.communication.mainViewLoad$.
      pipe(takeUntil(this.destroyed)).
      subscribe((success: boolean) =>
        this.onMainViewLoad(success));

    this.communication.pictureZoom$.
      pipe(takeUntil(this.destroyed)).
      subscribe((params: QuestionKey) =>
        this.onPictureZoom(params));

    this.timer.timeIsUp$.
      subscribe(() => this.onTimeIsUp());

    this._commentsMode = false;
    this.questionListOn = false;
    this.testId = this.persistentState.testId;

    this.verifyUserCredentials();

    this.bookmarks.reset(BookmarkPersistence.Temporary);
    this.showSelectBookmark = false;
    this.persistentState.clearAnswerSelectionList('exam');
    this.persistentState.clearAnswerInputList(TestMode.EXAM);

    // Load the test configuration data
    this.loadConfig();
  }

  /**
   * OnDestroy interface implementation
   * @see OnDestroy
   */
  ngOnDestroy(): void {
    this.destroyed.next();
    clearTimeout(this.timeout);
  }

  /**
   * Handles the Add Bookmark event emitted by the Toolbar component
   */
  onAddBookmark(): void {
    const questionNum: string = this.topic.questionList[this.topicQuestionNum - 1];
    this.bookmarks.addBookmark(this.topic, questionNum);
    this.showAddBookmark = false;
    this.showSelectBookmark = true;
  }

  onDeleteBookmark(bookmark: Bookmark): void {

    this.bookmarks.deleteBookmark(
      bookmark.topic,
      bookmark.questionNum
    );

    // If bookmark for the current question is deleted, then show 'Add Bookmark'
    // menu item again
    if (this.question.number == bookmark.questionNum) {
      this.showAddBookmark = true;
    }

    // If all bookmarks were deleted, then hide the 'Select bookmark' menu item
    if (!this.bookmarks.anyBookmarkExist()) {
      this.showSelectBookmark = false;
      this.onGoBack();
    }
  }


  /**
   * Handles the Exit event emitted by the Toolbar component
   */
  onExit(): void {
    this.persistentState.clearAnswerInputList(TestMode.EXAM);
    this.router.navigate([RouterUrlList.TEST_OPTIONS]);
  }

  /**
   * Handles the Next event emitted by the Header component
   */

  onNext(): void {
    if (this.topicQuestionNum < this.topic.questionList.length) {
      this.topicQuestionNum++;
      //this.communication.newTestQuestionNum++;
      this.examQuestionNum++;
    } else {
      if (this.topicIdx < this.topicList.length - 1) {
        this.topicIdx++;
        this.topicQuestionNum = 1;
        //this.communication.newTestQuestionNum++;
        this.examQuestionNum++;
      } else {
        return;
      }
    }
    this.showNewQuestion();
  }

  /**
   * Handles the Previous event emitted by the Header component
   */
  onPrevious(): void {

    if (this.showBackButton) {
      this.onGoBack();
    } else {
      this.onPreviousQuestion();
    }
  }

  /**
   * Handles the Select Bookmark event emitted by the Toolbar component
   */
  onSelectBookmark(): void {

    this.hideToolbarButtons()
    this.persistentState.set(
      Feature.QUESTION_LIST_PARAMS,
      this.bookmarks.bookmarkList
    );
    this.hideMainView();
    this.timeout = setTimeout(() => {
      this.router.navigate([RouterUrlList.QUESTION_LIST + '/true'],
        { relativeTo: this.route });
    }, this.FADE_ANIMATION_DURATION)

  }

  /**
   * Handles the Stop Exam event 
   */

  onStop(): void {

    const dialogRef: MatDialogRef<ConfirmDialogComponent, any> = this.confirmExamStop();

    dialogRef.afterClosed().subscribe(confirmed => {

      if (confirmed) {
        this.timer.stop();
        this._commentsMode = true;
        this.testQuestionComponent.commentsMode = true;
        this.showExitButton = true;
        this.showStopButton = false;
        this.reportExamScores();
        this.goToFirstQuestion();
      }
    });
  }

  /**
   * Router Outlet Activate event handler
   */
  setRoutedComponent(newView: TestView) {
    if (newView.testViewType == TestViewType.TEST_QUESTION) {
      this.testQuestionComponent = <TestQuestionComponent>newView;
      this.testQuestionComponent.commentsMode = this._commentsMode;
    } else {
      if (newView.testViewType == TestViewType.QUESTION_LIST) {
        const questionList: ExamQuestionListComponent = <ExamQuestionListComponent>newView;
        this.deleteBookmarkSubscription = questionList.deleteBookmark$.
          pipe(takeUntil(this.destroyed)).
          subscribe((bookmark) =>
            this.onDeleteBookmark(bookmark));
      }
    }
    this.onViewChange(newView.testViewType);
  }
  unsetRoutedComponent(newView: TestView) {
    if (newView.testViewType == TestViewType.QUESTION_LIST) {
      this.deleteBookmarkSubscription.unsubscribe();
    }
  }

  private confirmExamStop(): MatDialogRef<ConfirmDialogComponent, any> {
    const params: ConfirmDialogParams = {
      cancelBtnLabelId: 'confirm_dialog.back',
      confirmBtnLabelId: 'confirm_dialog.yes',
      messageId: 'exam_page.do_you_want_to_end_this_test',
      titleId: 'confirm_dialog.are_you_sure'
    }

    return this.dialog.open(ConfirmDialogComponent, { data: params });
  }



  private get topic(): TestDataConfigTopic {
    return this.topicList[this.topicIdx];

  }

  private get topicId(): string {
    return this.topic.id;
  }

  private get topicName(): string {
    return this.topicList == null ? '' : this.topic.name;
  }

  private clearError(): void {
    this.errorMessageId = null;
  }

  private formatHeaderLabel(): string {
    return `${this.examQuestionNum} / ${this.examQuestionCount}  ${this.topicName}`;
  }

  private goToFirstQuestion(): void {

    this.topicIdx = 0;
    this.topicQuestionNum = 1;
    this.examQuestionNum = 1;
    this.showNewQuestion();
  }

  private hideMainView(): void {
    this.mainViewVisibility = Visibility.INVISIBLE;
    // Show the infinite progress indicator
    this.showLoadingProgress = true;
  }

  private loadConfig(): void {

    this.testData.testConfig$.subscribe(
      testConfig =>
        this.onLoadConfig(testConfig)
    );
  }

  private loadQuestion(): void {

    // Show the infinite progress indicator
    this.showLoadingProgress = true;

    let topicId = this.topicId;
    let questionNum = this.topic.questionList[this.topicQuestionNum - 1];
    this.testData.getQuestion(topicId, questionNum).subscribe(
      question => this.onLoadQuestion(question),
      error => this.onLoadQuestionError(error)
    );
  }

  private navigateToQuestion(): void {

    // The method is called indirectly from ngOnInit when no question
    // is loaded yet. It is safe to ignore the invocation
    if (this.question == null) {
      return;
    }

    const params: TestQuestionParams = {
      adaptiveLearn: false,
      question: this.question,
      officialExam: this.officialExam,
      testMode: TestMode.EXAM,
      topicId: this.topicId,
      topicQuestionCount: this.topic.questionList.length,
      topicQuestionNum: this.topicQuestionNum
    }
    this.persistentState.set(Feature.TEST_QUESTION_PARAMS, params);

    const route: string = (this.question.type == TestQuestionType.MULTIPLE_CHOICE ?
      RouterUrlList.MULTIPLE_CHOICE_QUESTION : RouterUrlList.TYPE_IN_QUESTION) + '/' +
      new Date().getTime().toString();
    this.router.navigate([route],
      { relativeTo: this.route });

  }


  private onError(messageId: string): void {
    this.errorMessageId = messageId;
  }

  private onGoBack(): void {
    this.showToolbarButtons();
    this.hideMainView();
    this.timeout = setTimeout(() => this.location.back(),
      this.FADE_ANIMATION_DURATION);
  }

  private onLoadConfig(testConfig: TestDataConfig) {

    this.clearError();

    this.officialExam = testConfig.officialExam;
    this.oralExam = testConfig.oralExam;

    // Create a random exam questions set
    this.topicList = testConfig.oralExam ?
      this.randomizeOralQuestions(testConfig.topicList) :
      this.randomizeWrittenQuestions(
        testConfig.topicList,
        testConfig.examQuestionsCount,
        testConfig.officialExam
      );

    // Initialize the score calculation service
    // TODO: REFACTOR !!! Split oral and written exam scores services
    if (this.oralExam) {
      this.examScores.initOral(this.topicList);
    } else {
      this.examScores.init(this.topicList);
    }

    this.topicIdx = 0;
    this.topicQuestionNum = 1;
    this.examQuestionNum = 1;

    // The demo exams have less than usual exam questions count. 
    // Therefore we cannot use numbers from the config files
    // We must count the real questions count instead
    this.examQuestionCount = 0;
    this.topicList.forEach(topic => this.examQuestionCount += topic.questionList.length)

    this.showNewQuestion();
  }

  private onLoadQuestion(question: TestQuestion) {

    this.questionLoadComplete = true;

    if (!this.commentsMode) {
      this.clearError();
    } else {

    }

    // Hide the infinite progress indicator
    setTimeout(() => this.showLoadingProgress = false);

    // Question load was successful. Display the new question number, etc.
    //this.examQuestionNum = this.communication.newTestQuestionNum;
    this.headerLabel = this.formatHeaderLabel();

    this.showAddBookmark = !this.bookmarks.bookmarkExist(this.topicId, question.number);

    this.question = question;
    this.navigateToQuestion();
  }

  private onLoadQuestionError(error: any) {

    this.questionLoadComplete = true;

    // Hide the infinite progress indicator
    this.showLoadingProgress = false;
  }

  private onMainViewLoad(success: boolean) {
    this.showMainView();
  }

  private onPictureZoom(params: QuestionKey): void {
    this.hideMainView();
    this.hideToolbarButtons();
    this.timeout = setTimeout(() => {
      this.router.navigate([
        `${RouterUrlList.EXAM_PAGE}/${RouterUrlList.IMAGE_ZOOM}`,
        params.topicId,
        params.questionNum
      ]);
    }, this.FADE_ANIMATION_DURATION);
  }

  private onPreviousQuestion(): void {
    if (this.topicQuestionNum > 1) {
      this.topicQuestionNum--;
      //this.communication.newTestQuestionNum--;
      this.examQuestionNum--;
    } else {
      if (this.topicIdx > 0) {

        this.topicIdx--;
        this.topicQuestionNum = this.topic.questionList.length;
        //this.communication.newTestQuestionNum--;
        this.examQuestionNum--;
      } else {
        return;
      }
    }
    this.showNewQuestion();
  }

  private onQuestionSelect(params: QuestionKey): void {

    this.hideMainView();

    // Calculate parameters
    const topicIdx: number = this.topicList.findIndex(value => value.id == params.topicId);
    const questionIdx: number = this.topicList[topicIdx].questionList.findIndex(value => value == params.questionNum);
    let prevTopicQuestionCount: number = 0;
    this.topicList.forEach((value, index) => {
      if (index < topicIdx) {
        prevTopicQuestionCount += value.questionList.length;
      }
    });

    this.topicIdx = topicIdx;
    this.topicQuestionNum = questionIdx + 1;
    this.examQuestionNum = prevTopicQuestionCount + questionIdx + 1;
    this.showNewQuestion();
  }

  private onTimeIsUp(): void {

    this.timer.stop();
    this._commentsMode = true;
    this.testQuestionComponent.commentsMode = true;
    this.showExitButton = true;
    this.showStopButton = false;
    this.reportExamScores();
    this.goToFirstQuestion();

  }

  private onToggleQuestionList(isOn: boolean): void {
    this.clearError();
    if (isOn) {
      this.persistentState.set(
        Feature.QUESTION_LIST_PARAMS,
        this.topicList
      );

      this.hideMainView();
      this.showBookmarkButton = false;
      this.showStopButton = false;
      this.timeout = setTimeout(() => {
        this.router.navigate([RouterUrlList.QUESTION_LIST + '/false'],
          { relativeTo: this.route });
      }, this.FADE_ANIMATION_DURATION)
    } else {
      this.hideMainView();
      this.showToolbarButtons();
      this.timeout = setTimeout(() => {
        this.navigateToQuestion();
      }, this.FADE_ANIMATION_DURATION)
    }

  }

  private onUserCredentialsVerify(
    result: CheckUserCredentialsResult
  ): void {

    if (!result.valid) {

      let msg: string = this.translate.
        instant(result.errorMessageId).
        replace(
          '__USER_NAME__',
          result.userId
        );

      const afterClosed$: Observable<any> = this.alert.show(msg);

      afterClosed$.subscribe(
        () => {
          if (result.logout) {
            this.router.navigate([RouterUrlList.LOGIN]);
          } else {
            this.router.navigate([RouterUrlList.TEST_OPTIONS]);
          }
        }
      );
    }
  }

  private onViewChange(view: TestViewType): void {
    switch (view) {
      case TestViewType.IMAGE_ZOOM:
        this.showBackButton = true;
        this.showHeaderImage = true;
        this.showNextButton = false;
        break;
      case TestViewType.QUESTION_LIST:
        this.showBackButton = true;
        this.showHeaderImage = true;
        this.showNextButton = false;
        break;
      case TestViewType.TEST_QUESTION:
        this.headerLabel = this.formatHeaderLabel();
        this.showBackButton = false;
        this.showHeaderImage = false;
        this.showNextButton = true;
        this.questionListOn = false;
        break;
    }
  }
  private randomizeOralQuestions(
    topicList: TestDataConfigTopic[]
  ) {

    const randomizer: OralQuestionSetRandomizer =
      new OralQuestionSetRandomizer(
        topicList,
        this.persistentState
      );

    return randomizer.topicList;
  }

  private randomizeWrittenQuestions(
    topicList: TestDataConfigTopic[],
    examQuestionCount: number,
    officialExam: boolean
  ): TestDataConfigTopic[] {

    let newList: TestDataConfigTopic[] = [];
    let totalQuestionsCount: number =
      TestDataConfigTopic.totalQuestionsCount(
        officialExam && !this.config.useUnofficialExamQuestions,
        topicList
      );

    // Calculate topic questions count first
    let topicQuestionCountList: number[] = [];
    const questionListList: string[][] = [];
    let remainingQuestionsCount: number = examQuestionCount;
    for (let i = 0; i < topicList.length; i++) {

      // Get the topic question list
      // JSON parse/stringify is used to clone the array
      questionListList.push(
        JSON.parse(
          JSON.stringify(
            officialExam && !this.config.useUnofficialExamQuestions ?
              topicList[i].officialQuestionList :
              topicList[i].questionList
          )
        )
      );

      // Calculate the number of the topic questions to be used
      let topicQuestionCount: number =
        i < topicList.length - 1 ?
          Math.floor(questionListList[i].length * examQuestionCount / totalQuestionsCount) :
          Math.min(remainingQuestionsCount, questionListList[i].length);

      // Add at least one question if it is available
      topicQuestionCount =
        topicQuestionCount == 0 && questionListList[i].length > 0 ?
          1 :
          topicQuestionCount;

      topicQuestionCountList.push(topicQuestionCount);

      remainingQuestionsCount -= topicQuestionCount;
    }

    // The calculations above are not precise and the last topic may have not enough questions
    // So we must add questions to other topics
    if (remainingQuestionsCount > 0) {

      for (
        let i = 0;
        i < topicList.length && remainingQuestionsCount > 0;
        i++
      ) {

        const toBeAddedQuestionsCnt =
          Math.min(
            remainingQuestionsCount,
            questionListList[i].length - topicQuestionCountList[i]
          );
        topicQuestionCountList[i] += toBeAddedQuestionsCnt;
        remainingQuestionsCount -= toBeAddedQuestionsCnt;
      }
    }

    for (
      let i = 0;
      i < topicList.length;
      i++
    ) {

      const newQuestionList: string[] = [];
      const questionList: string[] = questionListList[i];
      const topic: TestDataConfigTopic = topicList[i];
      const topicQuestionCount: number = topicQuestionCountList[i];

      for (let j = 0; j < topicQuestionCount; j++) {
        const rndIdx: number = Math.floor(Math.random() * questionList.length);
        newQuestionList.push(questionList[rndIdx]);
        questionList.splice(rndIdx, 1);
      }

      // The Signale topic of the e.driver exam consists of non asa questions
      // Therefore the question list will be empty. Just do not add the topic 
      // (or any other topic similar to it)
      if (newQuestionList.length > 0) {
        const newTopic: TestDataConfigTopic =
          new TestDataConfigTopic(
            topic.id,
            topic.name,
            [],
            newQuestionList,
            topic.questionData
          );

        newList.push(newTopic);
      }
    }

    return newList;
  }

  private reportExamScores(): void {
    if (this.oralExam) {
      this.reportOralExamScores();
    } else {
      this.reportWrittenExamScores();
    }
  }

  private reportOralExamScores(): void {
    const scores: number = this.examScores.calcOralScores();
    let message: string = '';
    if (scores >= this.examScores.oralPassingScore) {
      const resultMessageSuccess: string = this.translate.instant('exam_page.result_message_success');
      message = resultMessageSuccess.replace('_score_', '' + scores).
        replace('_max_score_', '' + this.examScores.oralMaxScore);
    } else {
      const resultMessageFailure: string = this.translate.instant('exam_page.result_message_failure');
      message = resultMessageFailure.replace('_score_', '' + scores).
        replace('_max_score_', '' + this.examScores.oralMaxScore);
    }
    this.alert.show(message)
  }

  private reportWrittenExamScores(): void {

    const scores: number = this.examScores.calcScores();

    let message: string = '';

    if (scores >= this.examScores.passingScore) {

      const resultMessageSuccess: string =
        this.translate.instant('exam_page.result_message_success');

      message = resultMessageSuccess.replace('_score_', '' + scores).
        replace('_max_score_', '' + this.examScores.maxScore);
    } else {

      const resultMessageFailure: string =
        this.translate.instant('exam_page.result_message_failure');

      message = resultMessageFailure.replace('_score_', '' + scores).
        replace('_max_score_', '' + this.examScores.maxScore);
    }
    this.alert.show(message)
  }

  private showMainView(): void {
    this.mainViewVisibility = Visibility.VISIBLE;
    this.showLoadingProgress = false;
  }

  private showNewQuestion() {

    this.questionLoadComplete = false;

    // Check if the user credentials expired or have a wrong device ID
    this.verifyUserCredentials();

    // Hide main view. 
    this.hideMainView();

    // Wait for the animation to play and only then load new question data.
    // Without delay and with a fast Internet connection the animation may have not
    // enough time to be played
    this.timeout = setTimeout(
      () => this.loadQuestion(),
      this.FADE_ANIMATION_DURATION);
  }

  private verifyUserCredentials(): void {

    this.ucVerificator.verify().subscribe(
      result =>
        this.onUserCredentialsVerify(result)
    );
  }

  private hideToolbarButtons(): void {
    this.showBookmarkButton = false;
    this.showNavigationButton = false;
    this.showStopButton = false;
  }
  private showToolbarButtons(): void {
    this.showBookmarkButton = true;
    this.showNavigationButton = true;
    this.showStopButton = !this.commentsMode;
  }

}
