import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { fadeInOutAnimation } from 'src/app/global/animations/animations';
import { AppConfigService } from 'src/app/global/services/app-config/app-config.service';

/**
 * The component is used to display a page header at the page top. Sometimes the header contains some text
 * information. Sometimes it contains only application logo image. Sometimes it contains navigation buttons
 * (left and right) displayed at the left/right side.
 * @author Ruslan Rubtsov
 * @version 1.0.1
 */
@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  animations: [fadeInOutAnimation]
})

/**
 * The class is the Header component implementation
 */
export class HeaderComponent implements OnInit {

  /**
   * The constants the contain the URL of the images of the Left and Right navigation buttons
   */
  readonly leftArrowImgUrl: string = 'assets/images/ui/header/left_arrow.png';
  readonly rightArrowImgUrl: string = 'assets/images/ui/header/right_arrow.png';

  /**
   * Component input parameter containing text to be displayed on the header (no translation required)
   */
  @Input() label: string;
  
  /**
   * Component input parameter. The Translation Service ID of the string to be displayed on the header
   */
  @Input() labelId: string;

  /**
   * The component input parameter used to show/hide image (application logo) on the header
   */
  @Input() showImage: boolean = true;

  /**
   * The component input parameter used to show/hide the text defined by tge labelId parameter
   */
  @Input() showLabelFromId: boolean = true;

  /**
   * The component input parameter used to show/hide the left navigation button
   */
  @Input() showLeftButton: boolean = false;

  /**
   * The component input parameter used to show/hide the right navigation button
   */
  @Input() showRightButton: boolean = false;

  /**
   * The component input parameter used by lesson test to display test results icon (red - failed, green - assed)
   */
  //@Input()  testResult: TestResult = TestResult.NONE;

  /**
   * The component output parameter. It is used to notify the parent compomemt about right navigation
   * button click
   */
  @Output() next: EventEmitter<any> = new EventEmitter();
  /**
   * The component output parameter. It is used to notify the parent compomemt about left navigation
   * button click
   */
  @Output() previous: EventEmitter<any> = new EventEmitter();


  /**
   * The URL of the header image (application logo)
   */
  imageUrl: string = '';

  /**
   * Class constructor.
   * @param config The service, which is used to obtain application configuration data stored in the external file
   * @see ConfigService
   */
  constructor(private configService: AppConfigService) { }

  /**
   * OnInit interface implementation
   * @see OnInit
   */
  ngOnInit(): void {
    this.imageUrl = this.configService.headerImageUrl;
  }

  /**
   * Right navigation button mouse click event handler
   * @param event 
   * @see MouseEvent
   */
  onNext(event: MouseEvent) {
    if (this.showRightButton) {
      this.next.emit();
    }
  }

  /**
   * Left navigation button mouse click event handler
   * @param event 
   * @see MouseEvent
   */
  onPrevous(event: MouseEvent) {
    if (this.showLeftButton) {
      this.previous.emit();
    }
  }
}
