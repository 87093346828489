import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppConfigService } from 'src/app/global/services/app-config/app-config.service';
import { TestConfig } from 'src/app/models/config/config-types';
import { PersistentStateService } from 'src/app/services/persistent-state/persistent-state.service';

@Component({
  selector: 'test-category-name',
  templateUrl: './test-category-name.component.html',
  styleUrls: ['./test-category-name.component.css']
})
export class TestCategoryNameComponent implements OnInit {

  categoryName: string = '';

  private destroyed$$: Subject<void>;

  /**
   * Class constructor
   * @param config The service, which is used to obtain application configuration data stored in the external file
   * @see ConfigService
   * @param persistentState The injectable persitent state service, which is used to staore the data 
   * persistent between the application executions.
   * @see PersistentStateService
   * 
   */

  constructor(
    private config: AppConfigService,
    private persistentState: PersistentStateService,
    private translate: TranslateService
  ) {

  }

  ngOnInit(): void {
    this.loadTestCategoryName();

    this.destroyed$$ = new Subject<void>();
    this.translate.onLangChange.
      pipe(takeUntil(this.destroyed$$)).
      subscribe(
        () => {
          this.loadTestCategoryName();
        }
      );
  }

  /**
   * OnDestroy interface implementation
   * @see OnDestroy
   */
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.destroyed$$.next();
  }


  protected loadTestCategoryName() {

    this.config.getTestConfig(
      this.translate.currentLang,
      this.persistentState.testId
    ).subscribe(
      (testConfig: TestConfig) => {
        this.categoryName = testConfig.fullName;
      }
    );
  }
}
