import { Component, OnInit } from '@angular/core';
import { ButtonIcon, IButtonIcon } from '../toolbar-toggle-button/toolbar-button-icon';

@Component({
  selector: 'toolbar-exit-button',
  templateUrl: './toolbar-exit-button.component.html',
  styleUrls: ['./toolbar-exit-button.component.css']
})
export class ToolbarExitButtonComponent implements OnInit {

  public readonly icon: IButtonIcon = ButtonIcon.exit;
  //readonly exitIcon: string = 'assets/images/ui/toolbar/exit.png';

  constructor() { }

  ngOnInit(): void {
  }

}
