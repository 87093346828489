import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, HostListener, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, Subscription } from 'rxjs';

import { CheckUserCredentialsResult } from '../../../global/services/authorization/authorization.service'
import { fadeInOutAnimation, Visibility } from '../../../global/animations/animations'
import { Feature } from '../../../services/persistent-state/keys';
import { PersistentStateService } from 'src/app/services/persistent-state/persistent-state.service'
import { RouterUrlList } from 'src/app/models/url-list';
import { StatisticsService } from '../../../services/statistics.service'
import { TestDataService } from 'src/app/services/test/test-data.service';
import { TestDataConfig, TestQuestion, TestQuestionType } from 'src/app/models/test/test-data-config';
import { QuestionKey, TestPageCommunicationService } from '../../../services/test/test-page-communication.service';
import { TestView, TestViewType } from '../test-view';
import { BookmarkService, BookmarkPersistence, Bookmark } from 'src/app/global/services/bookmark/bookmark.service';
import { TestQuestionComponent } from 'src/app/routed-modules/test-question/test-question.component';
import { TestMode } from 'src/app/models/test/test-mode';
import { TestQuestionParams } from 'src/app/models/test-question-params';
import { AlertDialogService } from 'src/app/modules/common-components/alert-dialog/alert-dialog';
import { ExamQuestionListComponent } from 'src/app/routed-modules/exam-question-list/exam-question-list.component';
import { takeUntil } from 'rxjs/operators';
import { TestPageStateService } from './services/test-page-state.service';
import { TestQuestionListService } from './services/test-question-list/test-question-list.service';
import { testQuestionListServiceFactory, TEST_QUESTION_LIST_SERVICE } from './services/test-question-list/test-question-list.provider';
import { SubscriptionVerificatorService } from 'src/app/global/services/subscription-verificator.service';

/**
 * The component is used to display 
 * @author Ruslan Rubtsov
 * @version 1.0.1
 */
@Component({
  selector: 'test-page',
  templateUrl: './test-page.component.html',
  styleUrls: ['./test-page.component.css'],
  animations: [fadeInOutAnimation],
  providers: [
    BookmarkService,
    TestDataService,
    TestPageCommunicationService,
    TestPageStateService,
    {
      provide: TEST_QUESTION_LIST_SERVICE,
      useFactory: testQuestionListServiceFactory,
      deps: [
        PersistentStateService,
        StatisticsService
      ]
    },
    SubscriptionVerificatorService
  ]

})
export class TestPageComponent implements OnDestroy, OnInit {

  @HostListener('document:keydown', ['$event'])
  public onKeyPress(event) {
    if (this.questionLoadComplete) {
      const key = window.event ? event.keyCode : event.which;
      switch (key) {
        case 37: // LeftArrow
          this.onPrevious();
          break;
        case 39: // RightArrow
          this.onNext();
          break;
      }
    }
  }

  errorMessageId: string;
  headerLabel: string;
  questionLoadComplete: boolean = false;

  /* Toolbar input params */
  isAddBookmarkVisible: boolean = true;
  isSelectBookmarkVisible: boolean = true;

  set commentsMode(mode: boolean) {
    this._commentsMode = mode;
    this.testQuestionComponent.commentsMode = mode;
  }

  get commentsMode(): boolean {
    return this._commentsMode;
  }

  set isStatisticsOn(isOn: boolean) {
    this._isStatisticsOn = isOn;
    this.toggleStatistics(isOn);
  }

  get isStatisticsOn(): boolean {
    return this._isStatisticsOn;
  }
  private _isStatisticsOn: boolean = false;

  get showLeftButton(): boolean {
    return (
      (
        this.questionList.testQuestionNum > 1 &&
        this.pageState.hs.isPrevBtnVisible
      ) ||
      this.pageState.hs.isBackBtnVisible
    ) && this.questionLoadComplete;
  }

  get showRightButton(): boolean {
    return (this.questionList.testQuestionNum < this.questionList.testQuestionCount ||
      this.questionList.isAdaptiveLearnMode) &&
      this.pageState.hs.isNextBtnVisible &&
      this.questionLoadComplete;
  }

  // TODO: delete if not used
  private readonly FADE_ANIMATION_DURATION = 400;

  private _commentsMode: boolean = false;
  private answerModified: boolean = false;
  private officialExam: boolean = false;
  private question: TestQuestion;
  private testQuestionComponent: TestQuestionComponent;
  private timeout: any;
  private wrongAnswerMessageShown: boolean = false;

  private deleteBookmarkSubscription: Subscription;
  private destroyed$$: Subject<void>;


  /**
   * Class constructor.
   * @param config The service, which is used to obtain application configuration data stored in the external file
   * @see ConfigService
   * @param communication The service, whichh is used for communication between compnents on the Test Page
   * @see TestPageCommunicationService
   * @param persistentState The injectable persitent state service, which is used to staore the data 
   * persistent between the application executions.
   * @see PersistentStateService
   * @param testData The service  which is used to load test data (topic list data, etc.)
   * @see TestDataService
   * @param translate Internationalization service
   * @see TranslateService
   */
  constructor(
    public pageState: TestPageStateService,
    @Inject(TEST_QUESTION_LIST_SERVICE) public questionList: TestQuestionListService,
    private alert: AlertDialogService,
    private bookmarks: BookmarkService,
    private communication: TestPageCommunicationService,
    private location: Location,
    private router: Router,
    private persistentState: PersistentStateService,
    private testData: TestDataService,
    private translate: TranslateService,
    private ucVerificator: SubscriptionVerificatorService
  ) {
    this.testData.reset();
  }

  /**
   * OnDestroy interface implementation
   * @see OnDestroy
   */
  ngOnDestroy(): void {
    this.destroyed$$.next();
    clearTimeout(this.timeout);
  }

  /**
   * OnInit interface implementation
   * @see OnInit
   */
  ngOnInit(): void {

    this.destroyed$$ = new Subject<void>();

    this.initEventHandlers();

    this.verifyUserCredentials()

    this.bookmarks.reset(BookmarkPersistence.Persistent);
    this.isSelectBookmarkVisible = this.bookmarks.anyBookmarkExist();

    // Load the test configuration data
    this.loadConfig();
  }

  onAddBookmark(): void {
    this.bookmarks.addBookmark(
      this.questionList.topic,
      this.questionList.questionNum
    );
    this.isAddBookmarkVisible = false;
    this.isSelectBookmarkVisible = true;
  }

  onDeleteBookmark(bookmark: Bookmark): void {

    this.bookmarks.deleteBookmark(
      bookmark.topic,
      bookmark.questionNum
    );

    // If bookmark for the current question is deleted, then show 'Add Bookmark'
    // menu item again
    if (this.question.number == bookmark.questionNum) {
      this.isAddBookmarkVisible = true;
    }

    // If all bookmarks were deleted, then hide the 'Select bookmark' menu item
    if (!this.bookmarks.anyBookmarkExist()) {
      this.isSelectBookmarkVisible = false;
      this.onGoBack();
    }

  }

  onExit(
    saveState: boolean = true
  ): void {
    if (saveState) {
      this.questionList.saveState();
    }
    this.router.navigate([RouterUrlList.TEST_OPTIONS]);
  }

  onSelectBookmark(): void {

    this.persistentState.set(
      Feature.QUESTION_LIST_PARAMS,
      this.bookmarks.bookmarkList
    );

    // Hide the error message
    this.pageState.errorMsgVisibility = Visibility.INVISIBLE;

    this.pageState.changeMainView(TestViewType.QUESTION_LIST);
  }

  onSelectTopic(topicId: string): void {

    if (this.checkCurrentQuestionAnswer()) {

      this.questionList.selectTopic(topicId);

      this.showNewQuestion();
    }
  }

  onNext(): void {
    if (this.checkCurrentQuestionAnswer()) {

      if (this.questionList.next()) {
        this.showNewQuestion();
      } else {
        if (this.questionList.isAdaptiveLearnMode) {

          this.questionList.resetState();

          const afterClosed$: Observable<any> =
            this.alert.show(
              this.translate.instant('test_page.finish_adaptive_test')
            );
          afterClosed$.subscribe(
            () => this.onExit(false)
          );
        }
      }

    }
  }

  onPrevious(): void {

    if (this.pageState.hs.isBackBtnVisible) {
      this.onGoBack();
    } else {
      this.onPreviousQuestion();
    }
  }

  setRoutedComponent(newView: TestView) {

    if (newView.testViewType == TestViewType.TEST_QUESTION) {
      this.testQuestionComponent = <TestQuestionComponent>newView;
      this.testQuestionComponent.commentsMode = this.commentsMode;
      this.isStatisticsOn = false;
    } else {
      if (newView.testViewType == TestViewType.QUESTION_LIST) {
        const questionList: ExamQuestionListComponent = <ExamQuestionListComponent>newView;
        this.deleteBookmarkSubscription = questionList.deleteBookmark$.
          subscribe(
            (bookmark) =>
              this.onDeleteBookmark(bookmark)
          );
      }
    }
  }

  unsetRoutedComponent(newView: TestView) {
    if (newView.testViewType == TestViewType.QUESTION_LIST) {
      this.deleteBookmarkSubscription.unsubscribe();
    }
  }


  private clearError(): void {
    this.errorMessageId = null;
    this.pageState.errorMsgVisibility = Visibility.INVISIBLE;
  }

  private checkCurrentQuestionAnswer(): boolean {

    if (
      this.answerModified &&
      !this.wrongAnswerMessageShown &&
      !this.questionList.isCurrentQuestionCorrectlyAnswered

    ) {

      this.errorMessageId = 'test_page.wrong_answer';
      this.pageState.errorMsgVisibility = Visibility.VISIBLE;
      this.commentsMode = true;
      this.wrongAnswerMessageShown = true;

      return false;

    } else {

      return true;
    }
  }

  private initEventHandlers() {

    this.communication.answerSelect$.
      pipe(takeUntil(this.destroyed$$)).
      subscribe(
        index =>
          this.onAnswerSelected(index)
      );

    this.communication.error$.
      pipe(takeUntil(this.destroyed$$)).
      subscribe(
        (messageId: string) =>
          this.onError(messageId)
      );

    this.communication.examQuestionSelect$.
      pipe(takeUntil(this.destroyed$$)).
      subscribe(
        (params: QuestionKey) =>
          this.onQuestionSelect(params)
      );

    this.translate.onLangChange.
      pipe(takeUntil(this.destroyed$$)).
      subscribe(
        () => this.loadConfig()
      );
  }

  private formatHeaderLabel(): string {
    return this.questionList.isAdaptiveLearnMode ?
      `${this.questionList.testQuestionCount}`.
        concat('  ').
        concat(`${this.questionList.topicName}`) :
      `${this.questionList.testQuestionNum}`.
        concat(' / ').
        concat(`${this.questionList.testQuestionCount}`).
        concat('  ').
        concat(`${this.questionList.topicName}`);
  }

  private loadConfig(): void {

    this.testData.testConfig$.subscribe(
      testConfig => {
        this.onLoadConfig(testConfig)
      },
      error => console.log(error)
    );
  }

  private loadQuestion(): void {

    // Show the infinite progress indicator
    this.pageState.isLoadingProgressVisible = true;

    this.testData.getQuestion(
      this.questionList.topicId,
      this.questionList.questionNum
    ).subscribe(
      question => this.onLoadQuestion(question),
      error => this.onLoadQuestionError(error)
    );
  }

  private toggleStatistics(visible: boolean): void {

    this.clearError();

    if (visible) {

      this.questionList.saveState();

      this.pageState.changeMainView(TestViewType.TOTAL_STATISTICS);

    } else {
      // TODO: Show hidden buttons

      this.pageState.hideMainView();
      this.timeout = setTimeout(
        () => {
          this.navigateToQuestion();
        },
        this.FADE_ANIMATION_DURATION
      )
    }

  }

  private navigateToQuestion(): void {

    const params: TestQuestionParams = {
      adaptiveLearn: this.questionList.isAdaptiveLearnMode,
      question: this.question,
      officialExam: this.officialExam,
      testMode: TestMode.TEST,
      topicId: this.questionList.topicId,
      topicQuestionCount: this.questionList.topicQuestionCount,
      topicQuestionNum: this.questionList.topicQuestionNum
    }
    this.persistentState.set(
      Feature.TEST_QUESTION_PARAMS,
      params
    );

    this.pageState.changeMainView(

      this.question.type == TestQuestionType.MULTIPLE_CHOICE ?

        (
          this.questionList.isAdaptiveLearnMode ?
            TestViewType.ADAPTIVE_TEST_QUESTION :
            TestViewType.TEST_QUESTION
        ) :

        (
          this.questionList.isAdaptiveLearnMode ?
            TestViewType.ADAPTIVE_TEST_QUESTION_TYPEIN :
            TestViewType.TEST_QUESTION_TYPEIN
        )
    );
  }


  private onAnswerSelected(index: number): void {
    this.answerModified = true;
  }

  private onError(messageId: string): void {
    this.errorMessageId = messageId;
    this.pageState.errorMsgVisibility =
      messageId != null ?
        Visibility.VISIBLE : Visibility.INVISIBLE;
  }

  private onGoBack(): void {

    this.pageState.hideMainView();

    this.timeout = setTimeout(
      () => this.location.back(),
      this.FADE_ANIMATION_DURATION
    );
  }

  private onLoadConfig(testConfig: TestDataConfig) {

    this.clearError();

    this.officialExam = testConfig.officialExam;

    // Get the list of topics from the test configuration 
    // and apply filter defined before the test start
    this.questionList.topicList = testConfig.topicList;

    // If the user selected 'Resume Test' instaed of 'Start Test', 
    // then restore the test state saved from the last test run 
    // (current topic, question, etc.)
    // must be executed after this.topicList = testConfig.topicList;
    this.questionList.restoreState();

    // Load Config is executed at the test start in ngOnInit. 
    // But also it is executed whn the UI language is changed
    // in order to load test data in the selected language. 
    // But the language selection may take place while
    // statistics is shown. In this case the current questionin 
    // new language  must not be shown
    if (
      this.pageState.viewType == TestViewType.TEST_QUESTION ||
      this.pageState.viewType == TestViewType.TEST_QUESTION_TYPEIN ||
      this.pageState.viewType == TestViewType.ADAPTIVE_TEST_QUESTION ||
      this.pageState.viewType == TestViewType.ADAPTIVE_TEST_QUESTION_TYPEIN
    ) {
      this.showNewQuestion();
    }
  }

  private onLoadQuestion(question: TestQuestion) {

    this.questionLoadComplete = true;

    this.clearError();

    // Hide the infinite progress indicator
    this.pageState.isLoadingProgressVisible = false;

    // Question load was successful. Display the new question number, etc.
    this.headerLabel = this.formatHeaderLabel();
    this.questionList.saveState();

    this.isAddBookmarkVisible = !this.bookmarks.bookmarkExist(
      this.questionList.topicId,
      question.number
    );

    //this.communication.newQuestion = question;
    this.question = question;
    //this.router.navigate([`${new Date().getTime()}`], { relativeTo: this.route });
    this.navigateToQuestion();
  }

  private onLoadQuestionError(error: any) {

    this.questionLoadComplete = true;

    // Hide the infinite progress indicator
    this.pageState.isLoadingProgressVisible = false;
  }

  private onUserCredentialsVerify(
    result: CheckUserCredentialsResult
  ): void {

    if (!result.valid) {

      let msg: string = this.translate.
        instant(result.errorMessageId).
        replace('__USER_NAME__', result.userId);

      const afterClosed$: Observable<any> = this.alert.show(msg);

      afterClosed$.subscribe(
        () => {
          if (result.logout) {
            this.router.navigate([RouterUrlList.LOGIN]);
          } else {
            this.router.navigate([RouterUrlList.TEST_OPTIONS]);
          }
        }
      );
    }
  }

  private onQuestionSelect(
    params: QuestionKey
  ): void {

    this.pageState.hideMainView();

    this.questionList.selectQuestion(
      params.topicId,
      params.questionNum
    )
    this.showNewQuestion();
  }


  private onPreviousQuestion(): void {
    if (this.checkCurrentQuestionAnswer()) {
      this.questionList.previous()
      this.showNewQuestion();
    }
  }

  private showNewQuestion() {

    this.questionLoadComplete = false;

    // Reset flags
    this.answerModified = false;
    if (this.wrongAnswerMessageShown) {
      // If wrong answer message was shown, then hide comments for the new question
      this.commentsMode = false;
      this.wrongAnswerMessageShown = false;
    }

    // Check if the user credentials expired or have a wrong device ID
    this.verifyUserCredentials();

    // Hide main view. 
    this.pageState.hideMainView();

    // Wait for the animation to play and only then load new question data.
    // Without delay and with a fast Internet connection the animation may have not
    // enough time to be played
    this.timeout = setTimeout(
      () => this.loadQuestion(),
      this.FADE_ANIMATION_DURATION
    );
  }

  private verifyUserCredentials(): void {

    this.ucVerificator.verify().subscribe(
      result =>
        this.onUserCredentialsVerify(result)
    );
  }

}
