import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RouterUrlList } from 'src/app/models/url-list';

import { TestPageComponent } from './test-page/test-page.component';

const routes: Routes = [
  {
    path: RouterUrlList.TEST,
    component: TestPageComponent,
    data: { animation: 'TestPage' },
    children: [
      {
        path: RouterUrlList.EXPERT_COMMENTS + '/:topicId/:questionId',
        loadChildren: () =>
          import('../expert-comments/expert-comments.module').
            then(m => m.ExpertCommentsModule)
      },
      {
        path: RouterUrlList.QUESTION_LIST,
        pathMatch: 'prefix',
        loadChildren: () =>
          import('../exam-question-list/exam-question-list.module').
            then(m => m.ExamQuestionListModule)
      },
      {
        path: RouterUrlList.TOTAL_STATISTICS,
        loadChildren: () =>
          import('../total-statistics-list/total-statistics-list.module').
            then(m => m.TotalStatisticsListModule)
      },
      {
        path: RouterUrlList.TOPIC_STATISTICS + '/:id',
        loadChildren: () =>
          import('../topic-statistics/topic-statistics.module').
            then(m => m.TopicStatisticsModule)
      },
      {
        path: RouterUrlList.IMAGE_ZOOM + '/:topicId/:questionId',
        loadChildren: () =>
          import('../picture-zoom/picture-zoom.module').
            then(m => m.PictureZoomModule)
      },
      {
        path: 'tq',
        pathMatch: 'prefix',
        loadChildren: () =>
          import('../test-question/test-question.module').
            then(m => m.TestQuestionModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TestRoutingModule { }
