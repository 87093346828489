import { Component, OnInit } from '@angular/core';
import { ToggleButtonService } from './toolbar-toggle-button/toggle-button.service';

/**
 * The component is used to display the Toolbar component 
 * @author Ruslan Rubtsov
 * @version 1.0.1
 */
@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css'],
  providers: [ToggleButtonService]
})
export class ToolbarComponent implements OnInit {

  constructor() { }

  /**
   * OnInit interface implementation
   * @see OnInit
   */
  ngOnInit(): void {
  }
}
