import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css'],
  imports: [CommonModule],
  standalone: true
})
export class ProgressBarComponent implements OnInit {


  percentStr: string = '0%';

  @Input() set percent(percent: number) {
    this.percentStr = percent + '%';
  }

  constructor() { }

  ngOnInit(): void {
  }

}
