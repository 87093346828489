import { Injectable } from "@angular/core";
import { merge, Observable, ReplaySubject, timer } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { TestDataConfig } from "src/app/models/test/test-data-config";
import { TestDataService } from "src/app/services/test/test-data.service";

@Injectable()
export class TimerService {

    private readonly DEFAULT_TIMEOUT = 45 * 60;

    public readonly period: number = 5;
    public readonly stop$: Observable<void>;
    public readonly tick$: Observable<number>;
    public readonly timeIsUp$: Observable<number>;

    private readonly stopSubject$$: ReplaySubject<void>;
    private readonly tickSubject$$: ReplaySubject<number>;
    private readonly timeIsUpSubject$$: ReplaySubject<number>;

    private paused: boolean = true;
    private remainingTime: number;

    constructor(
        private testData: TestDataService
    ) {
        this.stopSubject$$ = new ReplaySubject<void>();
        this.stop$ = this.stopSubject$$.pipe(take(1));
        this.tickSubject$$ = new ReplaySubject<number>();
        this.tick$ = this.tickSubject$$.pipe();
        this.timeIsUpSubject$$ = new ReplaySubject<number>();
        this.timeIsUp$ = this.timeIsUpSubject$$.pipe(take(1));
        this.loadConfig();
    }

    public pause(): void {
        this.paused = true;
    }

    public resume(): void {
        this.paused = false;
    }

    public stop(): void {
        this.stopSubject$$.next();
        this.stopSubject$$.complete();
    }

    private loadConfig(): void {
        this.testData.testConfig$.subscribe(
            testConfig => this.onLoadConfig(testConfig),
            () => this.onLoadConfigError());
    }

    private onLoadConfig(testConfig: TestDataConfig): void {

        this.resume();

        // Exam duration is in minutes. The timer timeout is in seconds
        this.setupTimer(testConfig.examDuration * 60);
    }

    private onLoadConfigError(): void {
        this.setupTimer(this.DEFAULT_TIMEOUT);
    }

    private setupTimer(timeout: number): void {

        // Notify the timer component about timeout
        this.tickSubject$$.next(timeout);

        // Store the timeout value from config locally
        this.remainingTime = timeout;

        // Setup timer
        timer(this.period * 1000, this.period * 1000).
            pipe(
                takeUntil(
                    merge(
                        this.timeIsUp$,
                        this.stop$
                    )
                )
            ).subscribe(() => this.onTick());

    }

    private onTick(): void {
        if (!this.paused) {
            this.remainingTime -= this.period;
            this.tickSubject$$.next(this.remainingTime);

            if (this.remainingTime == 0) {
                this.timeIsUpSubject$$.next();
                this.timeIsUpSubject$$.complete();
            }
        }
    }
}