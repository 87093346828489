<div class="container">
    <div class="title">{{titleId|translate}}</div>
    <div class="message">{{messageId|translate}}</div>
    <div class="button_bar">
        <round-button [backgroundColor]="'#03914a'"
                          [labelId]="confirmBtnLabelId"
                          (click)="onConfirm()">
        </round-button>
        <round-button [backgroundColor]="'#a00f0f'"
                          [labelId]="cancelBtnLabelId"
                          (click)="onCancel()">
        </round-button>
    </div>
</div>