import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { HeaderComponent } from './header/header.component';
import { LanguageMenuItemComponent } from './language-menu/language-menu-item/language-menu-item.component';
import { RoundButtonComponent } from './round-button/round-button.component';
import { BookmarkMenuItemComponent } from './bookmark-menu/bookmark-menu-item/bookmark-menu-item.component';
import { ToolbarToggleButtonComponent } from './toolbar/toolbar-toggle-button/toolbar-toggle-button.component';
import { TopicMenuItemComponent } from './topic-menu/topic-menu-item/topic-menu-item.component';
import { TestQuestionTextComponent } from './test-question-text/test-question-text.component';
import { TestQuestionAnswerListComponent } from './test-question-answer-list/test-question-answer-list.component';
import { TestQuestionAnswerListItemComponent } from './test-question-answer-list/item/test-question-answer-list-item.component';
import { TestResultIndicatorComponent } from './test-result-indicator/test-result-indicator.component';
import { TestQuestionPictureComponent } from './test-question-picture/test-question-picture.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { TestButtonComponent } from './test-button/test-button.component';
import { ToolbarLanguageSelectComponent } from './toolbar/toolbar-language-select/toolbar-language-select.component';
import { ToolbarExitButtonComponent } from './toolbar/toolbar-exit-button/toolbar-exit-button.component';
import { ToolbarBookmarksComponent } from './toolbar/toolbar-bookmarks/toolbar-bookmarks.component';
import { ToolbarNavigationButtonComponent } from './toolbar/toolbar-navigation-button/toolbar-navigation-button.component';
import { ToggleButtonDirective } from './toolbar/toolbar-toggle-button/toggle-button.directive';
import { ToolbarTopicSelectComponent } from './toolbar/toolbar-topic-select/toolbar-topic-select.component';
import { BookmarkMenuComponent } from './bookmark-menu/bookmark-menu.component';
import { LanguageMenuComponent } from './language-menu/language-menu.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { TopicMenuComponent } from './topic-menu/topic-menu.component';
import { ClickOutsideDirective } from 'src/app/modules/common-components/directives/click-outside.directive';
import { ProfTestButtonComponent } from './prof-test-button/prof-test-button.component';
import { TestCategoryNameComponent } from './test-category-name/test-category-name.component';
import { ToolbarHelpButtonComponent } from './toolbar/toolbar-help-button/toolbar-help-button.component';
import { ToastComponent } from './toast/toast.component';
import { ToastService } from './toast/toast.service';
import { TestQuestionAnswerListItemLetterComponent } from './test-question-answer-list/item/letter/test-question-answer-list-item-letter.component';
import { TestQuestionAnswerListItemTextComponent } from './test-question-answer-list/item/text/test-question-answer-list-item-text/test-question-answer-list-item-text.component';
import { TestQuestionAnswerListItemExplanationComponent } from './test-question-answer-list/item/explanation/test-question-answer-list-item-explanation.component';
import { TestIconComponent } from 'src/app/standalone-components/test-icon/test-icon.component';

// AoT requires an exported function for factories
export function TranslateLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
  //return new TranslateGVarLoader();
}


@NgModule({
  declarations: [
    AlertDialogComponent,
    BookmarkMenuComponent,
    BookmarkMenuItemComponent,
    ClickOutsideDirective,
    ConfirmDialogComponent,
    HeaderComponent,
    LanguageMenuComponent,
    LanguageMenuItemComponent,
    ProfTestButtonComponent,
    RoundButtonComponent,
    TestButtonComponent,
    TestCategoryNameComponent,
    TestQuestionAnswerListComponent,
    TestQuestionAnswerListItemComponent,
    TestQuestionPictureComponent,
    TestQuestionTextComponent,
    TestResultIndicatorComponent,
    ToggleButtonDirective,
    ToolbarComponent,
    ToolbarToggleButtonComponent,
    TopicMenuComponent,
    TopicMenuItemComponent,
    ToolbarLanguageSelectComponent,
    ToolbarExitButtonComponent,
    ToolbarBookmarksComponent,
    ToolbarNavigationButtonComponent,
    ToolbarTopicSelectComponent,
    ToolbarHelpButtonComponent,
    ToastComponent,
    TestQuestionAnswerListItemLetterComponent,
    TestQuestionAnswerListItemTextComponent,
    TestQuestionAnswerListItemExplanationComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild({
      defaultLanguage: 'de',
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateLoaderFactory,
        deps: [HttpClient]
      }
    }),
    TestIconComponent
  ],
  exports: [
    AlertDialogComponent,
    ClickOutsideDirective,
    ConfirmDialogComponent,
    HeaderComponent,
    ProfTestButtonComponent,
    RoundButtonComponent,
    TestButtonComponent,
    TestCategoryNameComponent,
    TestQuestionAnswerListComponent,
    TestQuestionPictureComponent,
    TestQuestionTextComponent,
    TestResultIndicatorComponent,
    ToggleButtonDirective,
    ToolbarBookmarksComponent,
    ToolbarComponent,
    ToolbarExitButtonComponent,
    ToolbarLanguageSelectComponent,
    ToolbarNavigationButtonComponent,
    ToolbarToggleButtonComponent,
    ToolbarTopicSelectComponent,
    TopicMenuComponent,
    TranslateModule,
    ToolbarHelpButtonComponent,
    ToastComponent,
  ],
  providers: [
    ToastService
  ],
})
export class CommonComponentsModule { }
