import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { AppConfigService } from 'src/app/global/services/app-config/app-config.service';
import { PersistentStateService } from 'src/app/services/persistent-state/persistent-state.service';
import { TestDataConfig, TestQuestion } from 'src/app/models/test/test-data-config';
import { TestPageCommunicationService } from '../../services/test/test-page-communication.service';
import { AppDataProvider } from 'src/app/providers/app-data/app-data-provider';
import { TranslateService } from '@ngx-translate/core';
import { ProjConfigService } from 'src/app/global/services/proj-config/proj-config.service';

/**
 * The service is used to 
 * @author Ruslan Rubtsov
 * @version 1.0.1
 */

@Injectable()
export class TestDataService {

  get imageBaseUrl(): string {
    return this._imageBaseUrl;
  }
  private _imageBaseUrl: string;

  //private _baseUrl: string;
  private errorCount: number = 0;
  private errorReported: boolean = false;
  private currentLanguage: string = '';
  private currentTopicId: string = '';
  private topicData: Object;

  /**
   * Class constructor.
   * @param communication The service, whichh is used for communication between compnents on the Test Page
   * @see TestPageCommunicationService
   * @param config The service, which is used to obtain application configuration data stored in the external file
   * @see ConfigService
   * @param persistentState The injectable persitent state service, which is used to staore the data 
   * persistent between the application executions.
   * @see PersistentStateService
   * @param translate Internationalization service
   * @see TranslateService
   */
  constructor(
    private dataProvider: AppDataProvider,
    private communication: TestPageCommunicationService,
    private config: AppConfigService,
    private persistentState: PersistentStateService,
    private projConfig: ProjConfigService,
    private translate: TranslateService
  ) {
    this._imageBaseUrl = this.projConfig.url.testImagesBase;
  }


  get testConfig$(): Observable<TestDataConfig> {

    const testConfigSubject: Subject<TestDataConfig> =
      new Subject<TestDataConfig>();
    const testConfig$: Observable<TestDataConfig> =
      testConfigSubject.asObservable().pipe(take(1));

    // Try to load config data from server
    this.dataProvider.getTestDataConfig(
      this.translate.currentLang,
      this.persistentState.testId
    ).subscribe(
      config => {
        // Without timeout testConfig$ subscriber does not receive the config
        // provided by GVarDataProvider. I could use ReplaySubject, but then I get 
        // another error
        setTimeout(() => {
          this._onTestConfigLoad(
            config,
            testConfigSubject
          );
        });
      },
      error => this._onTestConfigLoadError(
        error,
        testConfigSubject
      )
    );

    return testConfig$;
  }

  getQuestion(
    topicId: string,
    questionNum: string
  ): Observable<TestQuestion> {

    // Prepare observable result
    let questionSubject: ReplaySubject<TestQuestion> = new ReplaySubject<TestQuestion>();
    let question$: Observable<TestQuestion> = questionSubject.asObservable().pipe(take(1));

    // if the topic is already loaded, then just return the question from the topic
    if (
      topicId == this.currentTopicId &&
      this.topicData != null &&
      this.currentLanguage == this.translate.currentLang
    ) {
      let question: TestQuestion =
        TestQuestion.fromJSON(
          this.topicData['q' + questionNum]
        );
      questionSubject.next(question);
    } else {

      this.currentLanguage = this.translate.currentLang;
      this.currentTopicId = topicId;

      // If the topic is not loaded, then load it first
      // Try to load topic data from server
      this.dataProvider.getTestTopic(
        this.translate.currentLang,
        this.persistentState.testId,
        topicId
      ).subscribe(
        topicData => this._onTopicLoad(
          topicData,
          questionNum,
          questionSubject
        ),
        error => this._onTopicLoadError(
          error,
          topicId,
          questionNum,
          questionSubject
        )
      );

    }


    return question$;
  }

  reset(): void {
    this.errorCount = 0;
    this.errorReported = false;
    this.currentTopicId = '';
    this.topicData = null;

  }

  private _onTestConfigLoad(
    config: TestDataConfig,
    testConfigSubject: Subject<TestDataConfig>
  ): void {

    this.errorCount = 0;
    this.errorReported = false;
    testConfigSubject.next(config);
  }

  private _onTestConfigLoadError(
    error: any,
    testConfigSubject: Subject<TestDataConfig>
  ): void {

    console.error(
      'TestDataService._onTestConfigLoadError() error:',
      error
    );

    // Report error
    if (!this.errorReported) {
      this.communication.reportError('login_page.connection_failed');
      this.errorReported = true;

    }

    if (this.errorCount < this.config.dataRequestRetryCount) {

      this.errorCount++;

      setTimeout(
        () => {
          // Try to load config data from server
          this.dataProvider.getTestDataConfig(
            this.translate.currentLang,
            this.persistentState.testId
          ).subscribe(
            config => this._onTestConfigLoad(config, testConfigSubject),
            error => this._onTestConfigLoadError(error, testConfigSubject)
          );

        }, this.config.dataRequestRetryDelay);
    }
  }

  private _onTopicLoad(
    topicData: Object,
    questionNum: string,
    questionSubject: Subject<TestQuestion>
  ): void {

    this.errorCount = 0;
    this.errorReported = false;
    this.topicData = topicData;
    let question: TestQuestion =
      TestQuestion.fromJSON(
        this.topicData['q' + questionNum]
      );
    questionSubject.next(question);
  }

  private _onTopicLoadError(
    error: any,
    topicId: string,
    questionNum: string,
    questionSubject: Subject<TestQuestion>
  ): void {

    console.error(
      'TestDataService._onTopicLoadError(' + topicId + ',' + questionNum + ') error:',
      error
    );

    // Report error
    if (!this.errorReported) {
      this.communication.reportError('login_page.connection_failed');
      this.errorReported = true;

    }

    if (this.errorCount < this.config.dataRequestRetryCount) {

      this.errorCount++;

      setTimeout(() => {
        // Try to load question data from server
        this.dataProvider.getTestTopic(
          this.translate.currentLang,
          this.persistentState.testId,
          topicId
        ).subscribe(
          topicData => this._onTopicLoad(
            topicData,
            questionNum,
            questionSubject
          ),
          error => this._onTopicLoadError(
            error,
            topicId,
            questionNum,
            questionSubject
          )
        );

      }, this.config.dataRequestRetryDelay);
    }
  }
}
