import { Directive, Output, EventEmitter, ElementRef, HostListener } from '@angular/core';

/**
 * The directive is used to detect a mouse click outside a component
 * It is used to hide a menu when a user clicks outside of it
 */
@Directive({
  selector: '[clickOutside]'
})

/**
 * The class is the implentation of the Click Outside directive
 */
export class ClickOutsideDirective {

  /**
   * Directive output parameter. This is the event, whic is emitted when a usecr clicks outside fo the
   * component with this directive
   */
  @Output() clickOutside = new EventEmitter<void>();

  /**
   * Class constructor
   * @param elementRef Reference to the parent component. It is used to determani if the mouse click 
   * took place iside or outside of the parent component.
   */
  constructor(private elementRef: ElementRef) {
  }

  /**
   * Mouse click event handler. It handles mouse click event s on the whole page
   * @param event 
   */
  @HostListener('document:click', ['$event'])
  public onClick(event) {
    // Check if the mouse click took place inside of the parent component
    const clickedInside = this.elementRef.nativeElement.contains(event.target);
    if (!clickedInside) {
      this.clickOutside.emit();
    }
    
  }

}
