import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'answer-explanation',
  templateUrl: './test-question-answer-list-item-explanation.component.html',
  styleUrls: ['./test-question-answer-list-item-explanation.component.css']
})
export class TestQuestionAnswerListItemExplanationComponent implements OnInit {

  @HostBinding('innerHTML') innerHtml;

  @Input() set text(value: string) {
    if(value) {
      this.innerHtml = value;
    } else {
      this.innerHtml = '';
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

}
