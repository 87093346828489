<div *ngIf="toastModel.visible" class="toast" [ngClass]="toastModel.position">
    <div class="toast-grid">

        <div *ngIf="toastModel.type" class="img flexbox-center">
            <img *ngIf="toastModel.type == 'error'" src="assets/images/ui/toast/error.svg" width="70">
            <img *ngIf="toastModel.type == 'warning'" src="assets/images/ui/toast/warning.svg" width="70">
            <img *ngIf="toastModel.type == 'info'" src="assets/images/ui/toast/info.svg" width="70" >
        </div>

        <div class="title">{{toastModel.title}}</div>
        <div class="message">{{toastModel.message}}</div>

        <div class="btn-close flexbox-center">
            <img (click)="close()" src="assets/images/ui/toast/close.svg" width="25">
        </div>
        
    </div>
</div>