export enum SapiProductId {
    E_DRIVER = 'E-DRIVER',
    E_DRIVER_PROF = 'E-DRIVER-PROF'
}

export class SapiProductIdFactory {

    public static fromString(stringValue: string): SapiProductId {

        let result: SapiProductId = null;

        Object.values(SapiProductId)
            .forEach(
                (value: SapiProductId) => {
                    if (stringValue == value) {
                        result = value;
                    }
                }
            )
        return result;
    }
}