import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RouterUrlList } from 'src/app/models/url-list';

import { ExamPageComponent } from './exam-page/exam-page.component'

const routes: Routes = [
  {
    path: RouterUrlList.EXAM_PAGE,
    component: ExamPageComponent,
    data: { animation: 'ExamPage' },
    children: [
      {
        path: RouterUrlList.QUESTION_LIST,
        pathMatch: 'prefix',
        loadChildren: () =>
          import('../exam-question-list/exam-question-list.module').
            then(m => m.ExamQuestionListModule),
        data: { animation: 'QuestionList' }
      },
      {
        path: RouterUrlList.IMAGE_ZOOM + '/:topicId/:questionId',
        loadChildren: () =>
          import('../picture-zoom/picture-zoom.module').
            then(m => m.PictureZoomModule),
        data: { animation: 'PictureZoom' }
      },
      {
        path: 'tq',
        pathMatch: 'prefix',
        loadChildren: () =>
          import('../test-question/test-question.module').
            then(m => m.TestQuestionModule)
      }/*,
      {
        path: ':dummy',
        loadChildren: () => import('../routed-modules/test-question/test-question.module').then(m => m.TestQuestionModule),
        data: { animation: 'TestQuestion' }
      }*/]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ExamRoutingModule { }
