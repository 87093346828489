import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { MatDialogModule } from "@angular/material/dialog";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TestButtonsComponent } from '../../routed-modules/start-page/test-buttons/test-buttons.component';
import { StartPageComponent } from '../../routed-modules/start-page/start-page/start-page.component';
import { CommonComponentsModule } from 'src/app/modules/common-components/common-components.module';
import { AlertDialogService } from 'src/app/modules/common-components/alert-dialog/alert-dialog';
import { AppConfigProvider, appConfigProviderFactory } from 'src/app/global/services/app-config/app-config-provider';
import { AppDataProvider, appDataProviderFactory } from 'src/app/providers/app-data/app-data-provider';
import { ExamModule } from '../../routed-modules/exam/exam.module';
import { TestModule } from '../../routed-modules/test/test.module';
import { projConfigProviderFactory, ProjConfigService } from 'src/app/global/services/proj-config/proj-config.service';
import { BookmarkService } from 'src/app/global/services/bookmark/bookmark.service';

// AoT requires an exported function for factories
export function TranslateLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
  //return new TranslateGVarLoader();
}

@NgModule({
  declarations: [
    AppComponent,
    TestButtonsComponent,
    StartPageComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonComponentsModule,
    ExamModule,
    FormsModule,
    HttpClientModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    TestModule,
    TranslateModule.forRoot({
      defaultLanguage: 'de',
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateLoaderFactory,
        deps: [HttpClient]
      }
    }),
    // AppRoutingModule MUST be imported afer other routed modules 
    AppRoutingModule
  ],
  providers: [
    AlertDialogService,
    BookmarkService,
    AppConfigProvider,
    {
      provide: APP_INITIALIZER,
      useFactory: appConfigProviderFactory,
      deps: [AppConfigProvider],
      multi: true
    },
    AppDataProvider,
    {
      provide: APP_INITIALIZER,
      useFactory: appDataProviderFactory,
      deps: [AppDataProvider],
      multi: true
    },
    ProjConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: projConfigProviderFactory,
      deps: [
        ProjConfigService
      ],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
