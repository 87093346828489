export interface ITestPageHeaderState {
    readonly isBackBtnVisible: boolean;
    readonly isHeaderImgVisible: boolean;
    readonly isHeaderLabelFromIdVisible: boolean;
    readonly isPrevBtnVisible: boolean;
    readonly isNextBtnVisible: boolean;
}

export class TestPageHeaderState {

    readonly ADAPTIVE_TEST_QUESTION: ITestPageHeaderState = {
        isBackBtnVisible: false,
        isHeaderImgVisible: false,
        isHeaderLabelFromIdVisible: false,
        isPrevBtnVisible: false,
        isNextBtnVisible: true
    };
    readonly ADAPTIVE_TEST_QUESTION_TYPEIN: ITestPageHeaderState = {
        isBackBtnVisible: false,
        isHeaderImgVisible: false,
        isHeaderLabelFromIdVisible: false,
        isPrevBtnVisible: false,
        isNextBtnVisible: true
    };

    readonly TEST_QUESTION: ITestPageHeaderState = {
        isBackBtnVisible: false,
        isHeaderImgVisible: false,
        isHeaderLabelFromIdVisible: false,
        isPrevBtnVisible: true,
        isNextBtnVisible: true
    };
    readonly TEST_QUESTION_TYPEIN: ITestPageHeaderState = {
        isBackBtnVisible: false,
        isHeaderImgVisible: false,
        isHeaderLabelFromIdVisible: false,
        isPrevBtnVisible: true,
        isNextBtnVisible: true
    };

    readonly TOTAL_STATISTICS: ITestPageHeaderState = {
        isBackBtnVisible: true,
        isHeaderImgVisible: false,
        isHeaderLabelFromIdVisible: true,
        isPrevBtnVisible: false,
        isNextBtnVisible: false
    };
    readonly TOPIC_STATISTICS: ITestPageHeaderState = {
        isBackBtnVisible: true,
        isHeaderImgVisible: false,
        isHeaderLabelFromIdVisible: true,
        isPrevBtnVisible: false,
        isNextBtnVisible: false
    };
    readonly EXPERT_COMMENTS: ITestPageHeaderState = { 
        isBackBtnVisible: true,
        isHeaderImgVisible: false,
        isHeaderLabelFromIdVisible: true,
        isPrevBtnVisible: false,
        isNextBtnVisible: false
    };
    readonly IMAGE_ZOOM: ITestPageHeaderState = { 
        isBackBtnVisible: true,
        isHeaderImgVisible: false,
        isHeaderLabelFromIdVisible: true,
        isPrevBtnVisible: false,
        isNextBtnVisible: false
    };
    readonly QUESTION_LIST: ITestPageHeaderState = { 
        isBackBtnVisible: true,
        isHeaderImgVisible: true,
        isHeaderLabelFromIdVisible: false,
        isPrevBtnVisible: false,
        isNextBtnVisible: false
    };
}
