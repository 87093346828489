import {
  Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, Input,
  OnInit, ViewContainerRef, ViewChild
} from '@angular/core';
import { TestAnswer } from 'src/app/models/test/test-data-config';
import { TestQuestionAnswerListItemComponent } from './item/test-question-answer-list-item.component';

export class AnswerListData {
  answerList: TestAnswer[];
  answerSelectionList: boolean[]
  correctAnswerList: number[];

  constructor(answerList: TestAnswer[], answerSelectionList: boolean[], correctAnswerList: number[]) {
    this.answerList = answerList;
    this.answerSelectionList = answerSelectionList;
    this.correctAnswerList = correctAnswerList;
  }
}

/**
 * The component is used to display 
 * @author Ruslan Rubtsov
 * @version 1.0.1
 */
@Component({
  selector: 'test-question-answer-list',
  templateUrl: './test-question-answer-list.component.html',
  styleUrls: ['./test-question-answer-list.component.css']
})
export class TestQuestionAnswerListComponent implements OnInit {

  /**
   * Component parameter that 
   */
  @Input() set commentsMode(isOn: boolean) {
    this._commentsMode = isOn;
    this._componentList.forEach(item => item.commentsMode = isOn);
  };
  /**
   * Component parameter that 
   */
  @Input() set data(data: AnswerListData) {
    this._data = data;
    setTimeout(() => {
      this._updateView();
    });
  }

  /**
   * The member contains the reference to the view contaier, which will be used for the dymnamic 
   */
  @ViewChild('answer_container', { read: ViewContainerRef, static: true }) viewContainer: ViewContainerRef;

  private componentFactory: ComponentFactory<TestQuestionAnswerListItemComponent>;
  private _commentsMode: boolean = false;
  private _componentList: TestQuestionAnswerListItemComponent[] = [];
  private _data: AnswerListData;

  /**
   * Class constructor
   * @param componentFactoryResolver The service is required for the dynamic component creation
   * @see ComponentFactoryResolver
   */
  constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

  /**
   * OnInit interface implementation
   * @see OnInit
   */
  ngOnInit(): void {
    this.componentFactory = this.componentFactoryResolver.resolveComponentFactory(TestQuestionAnswerListItemComponent);
  }

  private _updateView(): void {

    this._componentList.length = 0;
    this.viewContainer.clear();

    this._data.answerList.forEach((value, index, array) => {
      this._componentList.push(
        this.createAnswerComponent(value,
          index,
          this._data.correctAnswerList.includes(index + 1),
          this._data.answerSelectionList[index]));
    });
  }

  private createAnswerComponent(
    answer: TestAnswer,
    index: number,
    correct: boolean,
    selected: boolean): TestQuestionAnswerListItemComponent {

    let componentRef: ComponentRef<TestQuestionAnswerListItemComponent> = this.viewContainer.createComponent(this.componentFactory);
    componentRef.instance.answer = answer;
    componentRef.instance.commentsMode = this._commentsMode;
    componentRef.instance.index = index;
    componentRef.instance.correct = correct;
    componentRef.instance.selected = selected;
    return componentRef.instance;
  }
}
