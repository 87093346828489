import { Component, ComponentFactory, ComponentFactoryResolver, EventEmitter, OnDestroy, OnInit, Output, Renderer2, ViewChild, ViewContainerRef, ComponentRef } from '@angular/core';
import { Subject } from 'rxjs';

import { TestConfig } from 'src/app/models/config/config-types';
import { TestButtonComponent } from '../../../modules/common-components/test-button/test-button.component'
import { AppConfigService } from 'src/app/global/services/app-config/app-config.service'
import { TranslateService } from '@ngx-translate/core';
//import { AuthorizationService } from 'src/app/global/services/authorization/authorization.service';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { SubscriptionService } from 'src/app/global/services/subscription-api/subscription.service';

/**
 * The component is used to display the list of the test categories buttons
 * @author Ruslan Rubtsov
 * @version 1.0.1
 */
@Component({
  selector: 'test-buttons',
  templateUrl: './test-buttons.component.html',
  styleUrls: ['./test-buttons.component.css']
})
export class TestButtonsComponent implements OnDestroy, OnInit {

  /**
   * The component output parameter, which is used to notify parent component
   * that a test category button has been clicked
   */
  @Output() buttonClick: EventEmitter<TestConfig> = new EventEmitter();
  /**
   * The member contains the reference to the view contaier, which will be used for the dymnamic 
   * button list item creation
   */
  @ViewChild('button_container', { read: ViewContainerRef }) viewContainer: ViewContainerRef;

  private destroyed: Subject<void>;

  /**
   * Class constructor.
   * @param authorization The service is used to check if the current user credentials are valid
   * @see AuthorizationService
   * @param componentFactoryResolver The service is required for the dynamic component creation
   * @see ComponentFactoryResolver
   * @param config The service, which is used to obtain application configuration data stored in the external file
   * @see AppConfigService
   * @param renderer Angular service. Used by the component for listening Mouse Click events
   * @see Renderer2
   * @param translate Internationalization service
   * @see TranslateService
   */
  constructor(
    //private authorization: AuthorizationService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private config: AppConfigService,
    private renderer: Renderer2,
    private router: Router,
    private subscriptionService: SubscriptionService,
    private translate: TranslateService) { }

  /**
   * OnInit interface implementation
   * @see OnInit
   */
  ngOnInit(): void {

    this.destroyed = new Subject<void>();

    this.translate.onLangChange
      .pipe(takeUntil(this.destroyed))
      .subscribe(
        () => {
          this.loadButtons();
        }
      );

    this.loadButtons();
  }

  /**
   * OnDestroy interface implementation
   * @see OnDestroy
   */
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.destroyed.next();
  }

  onButtonClick(testConfig: TestConfig): void {
    this.buttonClick.emit(testConfig);
  }

  private loadButtons(): void {
    this.config.getTestConfigList(
      this.translate.currentLang
    ).subscribe(
      testButtons => {
        setTimeout(
          () => {
            this.createButtons(testButtons);
          }
        );
      }
    );
  }

  private createButtons(testButtons: TestConfig[]) {
    const componentFactory =
      this.componentFactoryResolver
        .resolveComponentFactory(TestButtonComponent);

    this.viewContainer.clear();
    testButtons.forEach(
      value => {
        this.createButton(componentFactory, value);
      }
    )
  }

  private createButton(
    componentFactory: ComponentFactory<TestButtonComponent>,
    testConfig: TestConfig
  ) {

    const componentRef: ComponentRef<TestButtonComponent> =
      this.viewContainer.createComponent(componentFactory);

    componentRef.instance.config = testConfig.button;
    componentRef.instance.enabled = !testConfig.protected ||
      !this.subscriptionService.loginRequired;

    this.renderer.listen(
      componentRef.location.nativeElement,
      'click',
      event => { this.onButtonClick(testConfig) }
    );
  }
}
