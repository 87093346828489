import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { TestViewType } from 'src/app/routed-modules/test/test-view';
import { Visibility } from "src/app/global/animations/animations";
import { QuestionKey, TestPageCommunicationService } from "src/app/services/test/test-page-communication.service";
import { ActivatedRoute, Router } from "@angular/router";
import { RouterUrlList } from "src/app/models/url-list";
import { ITestPageToolbarState, TestPageToolbarState } from "./data/tes-page-toolbar-state";
import { ITestPageHeaderState, TestPageHeaderState } from "./data/tes-page-header-state";

@Injectable()
export class TestPageStateService {

    private readonly headerStateList: TestPageHeaderState = new TestPageHeaderState();
    hs: ITestPageHeaderState = this.headerStateList.TEST_QUESTION;

    private readonly toolbarStateList: TestPageToolbarState = new TestPageToolbarState();
    tbs: ITestPageToolbarState = this.toolbarStateList.TEST_QUESTION;

    adaptiveTestProgressVisibility: Visibility = Visibility.VISIBLE;
    errorMsgVisibility: Visibility = Visibility.INVISIBLE;  // TODO: Readonly?
    mainViewVisibility: Visibility = Visibility.VISIBLE;    // TODO: Readonly?

    isLoadingProgressVisible: boolean = true;   // TODO: Readonly?

    get viewType(): TestViewType {
        return this._viewType;
    }
    private _viewType: TestViewType = TestViewType.TEST_QUESTION;

    private readonly FADE_ANIMATION_DURATION = 400;

    private destroyed$$: Subject<void>;
    private timeout: any;

    constructor(
        communication: TestPageCommunicationService,
        private route: ActivatedRoute,
        private router: Router

    ) {
        this.destroyed$$ = new Subject<void>();

        communication.mainViewLoad$.
            pipe(takeUntil(this.destroyed$$)).
            subscribe(
                (success: boolean) =>
                    this.onMainViewLoad(success)
            );

        communication.pictureZoom$.
            pipe(takeUntil(this.destroyed$$)).
            subscribe(
                (params: QuestionKey) =>
                    this.onPictureZoom(params)
            );

        communication.statQuestionSelect$.
            pipe(takeUntil(this.destroyed$$)).
            subscribe(
                (params: QuestionKey) =>
                    this.onStatQuestionSelect(params)
            );

        communication.statTopicSelect$.
            pipe(takeUntil(this.destroyed$$)).
            subscribe(
                (topicId: string) =>
                    this.onStatTopicSelect(topicId)
            );

    }

    ngOnDestroy() {
        clearTimeout(this.timeout);
        this.destroyed$$.next();
    }

    // TODO:Private ?
    hideMainView(): void {
        this.mainViewVisibility = Visibility.INVISIBLE;
        // Show the infinite progress indicator
        this.isLoadingProgressVisible = true;
    }

    // TODO:Private ?
    showMainView(): void {
        this.mainViewVisibility = Visibility.VISIBLE;
        this.isLoadingProgressVisible = false;
    }

    changeMainView(
        viewType: TestViewType,
        commands: any[] = []
    ): void {

        this.hs = this.headerStateList[viewType];

        // Do not change the toolbar state during transition
        // from expert comments to image zoom. Otherwise the 
        // statistics button will be hidden
        if (this._viewType != TestViewType.EXPERT_COMMENTS ||
            viewType != TestViewType.IMAGE_ZOOM) {

            this.tbs = this.toolbarStateList[viewType];
        }

        // Hide the adaptive test progress for statistics
        // and show for everything else
        this.adaptiveTestProgressVisibility =
            viewType == TestViewType.TOTAL_STATISTICS ?
                Visibility.INVISIBLE :
                Visibility.VISIBLE;

        this._viewType = viewType;

        this.hideMainView();

        this.timeout = setTimeout(

            () => {

                this.router.navigate(
                    [
                        this.mainViewUrl(viewType),
                        ...commands
                    ],
                    { relativeTo: this.route }
                );
            },
            this.FADE_ANIMATION_DURATION
        )
    }

    // TODO: move to another class?
    private mainViewUrl(viewType: TestViewType): string {
        let url = '';
        switch (viewType) {
            case TestViewType.ADAPTIVE_TEST_QUESTION:
                url = RouterUrlList.MULTIPLE_CHOICE_QUESTION + '/' +
                    new Date().getTime().toString();
                break;
            case TestViewType.ADAPTIVE_TEST_QUESTION_TYPEIN:
                url = RouterUrlList.TYPE_IN_QUESTION + '/' +
                    new Date().getTime().toString();
                break;
            case TestViewType.EXPERT_COMMENTS:
                url = RouterUrlList.EXPERT_COMMENTS;
                break;
            case TestViewType.IMAGE_ZOOM:
                url = RouterUrlList.IMAGE_ZOOM;
                break;
            case TestViewType.QUESTION_LIST:
                url = RouterUrlList.QUESTION_LIST + '/true';
                break;
            case TestViewType.TEST_QUESTION:
                url = RouterUrlList.MULTIPLE_CHOICE_QUESTION + '/' +
                    new Date().getTime().toString();
                break;
            case TestViewType.TEST_QUESTION_TYPEIN:
                url = RouterUrlList.TYPE_IN_QUESTION + '/' +
                    new Date().getTime().toString();
                break;
            case TestViewType.TOPIC_STATISTICS:
                url = RouterUrlList.TOPIC_STATISTICS;
                break;
            case TestViewType.TOTAL_STATISTICS:
                url = RouterUrlList.TOTAL_STATISTICS;
                break;
        }
        return url;
    }

    private onMainViewLoad(success: boolean) {

        setTimeout(
            () => {
                this.showMainView();
            }
        );
    }

    private onPictureZoom(params: QuestionKey): void {

        // Hide the error message
        this.errorMsgVisibility = Visibility.INVISIBLE;

        this.changeMainView(
            TestViewType.IMAGE_ZOOM,
            [
                params.topicId,
                params.questionNum
            ]
        );
    }

    private onStatQuestionSelect(params: QuestionKey): void {

        this.changeMainView(
            TestViewType.EXPERT_COMMENTS,
            [
                params.topicId,
                params.questionNum
            ]);
    }


    private onStatTopicSelect(topicId: string): void {

        this.changeMainView(
            TestViewType.TOPIC_STATISTICS,
            [topicId]
        );
    }
}