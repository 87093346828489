import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IAppConfig } from "src/app/global/services/app-config/i-app-config";
import { IProjConfig } from "src/app/global/services/proj-config/proj-config.interfaces";
import { IAdScreenConfig, ILanguage, TestConfig } from "src/app/models/config/config-types";
import { TestDataConfig } from "src/app/models/test/test-data-config";
import { ILessonTopic } from "src/app/routed-modules/lesson/models/lesson-data-models";
import { HttpDataProvider } from "./http-data-provider";
import { IDataProvider } from "./i-data-provider";
import { ITestTopic } from "./i-test-data";

@Injectable()
export class AppDataProvider implements IDataProvider {

    constructor(
        private dataProvider: HttpDataProvider
    ) {
        //this._dataProvider = new GVarDataProvider();
        //this.dataProvider = new HttpDataProvider();
    }

    get adScreenConfig(): Observable<IAdScreenConfig> {
        /* When using gvar data provider we still need the HttpDataProvider here?
        const provider: HttpDataProvider = this.dataProvider instanceof HttpDataProvider ?
            this.dataProvider : new HttpDataProvider(this.http);
        return provider.adScreenConfig;*/
        return this.dataProvider.adScreenConfig;
    }

    get appConfig(): Observable<IAppConfig> {
        return this.dataProvider.appConfig;
    }

    get projConfigData(): Observable<IProjConfig> {

        return this.dataProvider.projConfigData;
    }


    getLanguageList(lang: string): Observable<ILanguage[]> {
        return this.dataProvider.getLanguageList(lang);
    }

    getLessonTestDataConfig(lang: string, dataFolder: string): Observable<TestDataConfig> {
        return this.dataProvider.getLessonTestDataConfig(lang, dataFolder);
    }

    getLessonTestTopic(lang: string, dataFolder: string, topicId: string): Observable<ITestTopic> {
        return this.dataProvider.getLessonTestTopic(lang, dataFolder, topicId);
    }


    getLessonTopicList(lang: string, testId: string): Observable<ILessonTopic[]> {
        return this.dataProvider.getLessonTopicList(lang, testId);
    }

    getTestConfigList(lang: string): Observable<TestConfig[]> {
        return this.dataProvider.getTestConfigList(lang);
    }

    getTestDataConfig(lang: string, testId: string): Observable<TestDataConfig> {
        return this.dataProvider.getTestDataConfig(lang, testId);
    }

    getTestTopic(lang: string, testId: string, topicId: string): Observable<ITestTopic> {
        return this.dataProvider.getTestTopic(lang, testId, topicId);
    }

}

// TODO: WTF is this?
export function appDataProviderFactory(provider: AppDataProvider) {
    //return () => console.log('AppDataProvider appDataProviderFactory()');
    return () => { };
}