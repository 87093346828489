import { Component, ComponentFactory, ComponentFactoryResolver, EventEmitter, OnInit, Output, Renderer2, ViewChild, ViewContainerRef, Input, OnDestroy, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

import { AppConfigService } from 'src/app/global/services/app-config/app-config.service'
import { ILanguage } from 'src/app/models/config/config-types';
import { PersistentStateService } from 'src/app/services/persistent-state/persistent-state.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { fadeInOutAnimation } from 'src/app/global/animations/animations';
import { LanguageMenuItemComponent } from './language-menu-item/language-menu-item.component';
import { Feature } from 'src/app/services/persistent-state/keys';

/**
 * The component is used to display the language menu 
 * @author Ruslan Rubtsov
 * @version 1.0.1
 */
@Component({
  selector: 'language-menu',
  templateUrl: './language-menu.component.html',
  styleUrls: ['./language-menu.component.css'],
  animations: [fadeInOutAnimation]
})

/**
 * The class is the Language Menu component iplementation
 */
export class LanguageMenuComponent implements OnInit, OnDestroy {

  /**
   * Component parameter that is used to show/hide the menu
   */
  @Input() visible: boolean = false;

  @Output() selectLanguage: EventEmitter<ILanguage> = new EventEmitter<ILanguage>();

  /**
   * The member contains the reference to the view contaier, which will be used for dymnamic 
   * menu item creation.
   */
  @ViewChild('view_container', { read: ViewContainerRef }) viewContainer: ViewContainerRef;

  private _destroyed: Subject<void>;

  /**
 * Class constructor.
 * @param componentFactoryResolver The service is required for the dynamic component creation
 * @see ComponentFactoryResolver
 * @param config Teh service which is used to obtain application configuration data stored in the external file
 * @see ConfigService
 * @param persistentState The injectable persitent state service, which is used to staore the data 
 * persistent between the application executions.
 * @see PersistentStateService
 * @param renderer Angular service. Used by the component for listening Mouse Click events
 * @see Renderer2
 * @param translate Internationalization service
 * @see TranslateService
 */
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private config: AppConfigService,
    @Inject(DOCUMENT) private document: Document,
    private persistentState: PersistentStateService,
    private renderer: Renderer2,
    private translate: TranslateService
  ) { }

  /**
   * OnInit interface implementation
   * @see OnInit
   */
  ngOnInit(): void {

    this._destroyed = new Subject<void>();
    this.translate.onLangChange.
      pipe(takeUntil(this._destroyed)).
      subscribe(() => this._loadMenuItems());
    this._loadMenuItems();
  }

  /**
   * OnDestroy interface implementation
   * @see OnDestroy
   */
  ngOnDestroy(): void {

    // Unsubscribe to prevent memory leaks
    this._destroyed.next();
  }

  /**
   * Hides the menu
   */
  hide(): void {
    this.visible = false;
  }

  /**
   * Mouse click event handler. It is envoked when a user selects a language menu item by pressing it
   * @param language The selected language data
   * @see Language
   */
  onSelectLanguage(language: ILanguage): void {
    this.visible = false;

    // Save the current language for future when the application starts next time
    // IMPORTANT!!! This must be doent before this.translate.use(language.id)
    // Otherwise some components may use old language ID form the persistent service
    this.persistentState.set(Feature.CURRENT_LANGUAGE, language.id);

    this.document.documentElement.lang = language.id;

    // MAke the translate service use the new language
    this.translate.use(language.id);
    this.selectLanguage.emit(language);
  }

  /**
   * Use to toggle the menu visibility
   */
  toggleVisibility(): void {
    this.visible = !this.visible;
  }

  /**
   * Creates the list of the menu items
   * @param languageList list of languages data 
   * @see Language
   */
  private _createMenu(languageList: ILanguage[]) {
    const componentFactory = this.componentFactoryResolver.
      resolveComponentFactory(LanguageMenuItemComponent);

    this.viewContainer.clear();

    for (let language of languageList) {
      this._createMenuItem(componentFactory, language);
    }
  }

  /**
   * Creates a single language menu item
   * @param componentFactory used to create a component
   * @see ComponentFactory
   * @param language The menu item language data
   */
  private _createMenuItem(
    componentFactory: ComponentFactory<LanguageMenuItemComponent>,
    language: ILanguage
  ): void {

    let componentRef = this.viewContainer.createComponent(componentFactory);
    componentRef.instance.language = language;
    this.renderer.listen(componentRef.location.nativeElement,
      'click',
      event => { this.onSelectLanguage(language) });
  }

  /**
   * Obtains the list of languages from the configuration file
   */
  private _loadMenuItems(): void {
    this.config.getLanguageList(this.translate.currentLang).subscribe(languageList => {
      setTimeout(() => {
        this._createMenu(languageList);
      });
    });
  }
}
