import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'test-icon',
  templateUrl: './test-icon.component.html',
  styleUrls: ['./test-icon.component.css'],
  imports: [CommonModule],
  standalone: true
})
export class TestIconComponent implements OnInit {

  @Input() enabled: boolean;
  @Input() imgSrc: string = '';
  @Input() subtitle:string = '';
  @Input() title:string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
