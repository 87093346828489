import {
  Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';

import { fadeInOutAnimation } from '../../../../global/animations/animations';
import { ToggleButtonStateIconsService } from './toggle-button-state-icons.service';
import { IToggleButtonStateIcons } from './toolbar-button-icon';

/**
 * The component is used to display 
 * @author Ruslan Rubtsov
 * @version 1.0.1
 */
@Component({
  selector: 'toolbar-toggle-button',
  templateUrl: './toolbar-toggle-button.component.html',
  styleUrls: ['./toolbar-toggle-button.component.css'],
  animations: [fadeInOutAnimation]
})
export class ToolbarToggleButtonComponent implements OnInit {

  @Input() set icon(iconSet: string) {
    this.icons = this.stateIcons.getIcons(iconSet);
  }

  // [(isOn)] implementation
  @Input() isOn: boolean
  @Output() isOnChange: EventEmitter<boolean> = new EventEmitter();

  public icons: IToggleButtonStateIcons;
  public togglableFromOutside: boolean = true;

  /**
   * Class constructor.
   * @param stateIcons The service, which provides the URLs for the 
   * states icons of this button
   * @see ToggleButtonStateIconsService
   */

  constructor(private stateIcons: ToggleButtonStateIconsService) {

  }

  /**
   * OnInit interface implementation
   * @see OnInit
   */
  ngOnInit(): void { }

  onClick(event: MouseEvent): void {
    this.isOnChange.emit(!this.isOn);
    event.stopPropagation();
  }
}
