import { Injectable } from "@angular/core";
import { forkJoin, Observable, ReplaySubject } from "rxjs";
import { TestConfig } from 'src/app/models/config/config-types';
import { TestDataConfig, TestDataConfigTopic } from "src/app/models/test/test-data-config";
import { AlertDialogService } from "src/app/modules/common-components/alert-dialog/alert-dialog";
import { AppDataProvider } from 'src/app/providers/app-data/app-data-provider';
import { Feature } from "src/app/services/persistent-state/keys";
import { AppConfigService } from "../app-config/app-config.service";
import { AppVersion } from "./app-version";

@Injectable({
    providedIn: 'root'
})

export class AppUpdateService {

    constructor(
        private alert: AlertDialogService,
        private configService: AppConfigService,
        private dataProvider: AppDataProvider
    ) {

    }

    /**
     * Temporary patch for version 1.4.9 (in fact it was 1.5.0)
     */
    public update_1_4_9(): void {
        const flag = localStorage['update_1_4_9_done'];
        if (flag == null) {
            this.updateBookmarks150();
            this.updateStatistics150();
            localStorage['update_1_4_9_done'] = 'true';
        }
    }

    public update_1_5_4(): void {

        const flag = localStorage['update_1_5_4_done'];
        if (flag == null) {

            this.loadQuestionData().subscribe(

                (questionData: Object) => {

                    //console.warn('+++ questionData', questionData);

                    this.updateBookmarks(questionData);
                    this.updateStatistics(questionData);
                    localStorage['update_1_5_4_done'] = 'true';
                }
            );
        }
    }

    public update(newVesion: number) {

        // Check if a new version was loaded
        if (newVesion != AppVersion.version) {

            // If this is not the first installation, but update
            if (AppVersion.version != 0) {

                this.loadQuestionData().subscribe(

                    (questionData: Object) => {

                        // Update statistics
                        this.updateBookmarks(questionData);
                        this.updateStatistics(questionData);

                        // prompt the user and reload 
                        // the page in order to run new code

                        const afterClosed$: Observable<any> =
                            this.alert.show(
                                'The application was updated. The page will reload!'
                            );
                        afterClosed$.subscribe(
                            () => window.location.reload()
                        );

                    }
                );

            }

            // Save the new app version
            AppVersion.version = newVesion;
        }
    }

    private loadQuestionData(): Observable<Object> {

        let subject$$: ReplaySubject<Object> = new ReplaySubject<Object>();
        let result$: Observable<Object> = subject$$.asObservable();

        let questionData: Object = {};

        // Get the list of tests to get their IDs to be used to obtain data
        this.configService.getTestConfigList('de').subscribe(

            (testConfigList: TestConfig[]) => {

                // Load all test data configs
                let testDataConfigList$ = [];
                //console.warn('testConfigList', testConfigList);
                testConfigList.forEach(
                    (testConfig: TestConfig) => {

                        //console.warn('testConfig', testConfig);
                        testDataConfigList$.push(
                            this.dataProvider.getTestDataConfig(
                                'de',
                                testConfig.testId
                            )
                        );
                    }
                );

                // Wait for all test data configs to be loaded
                forkJoin(testDataConfigList$).subscribe(

                    (testDataConfigList: TestDataConfig[]) => {

                        // For each test data config
                        testDataConfigList.forEach(

                            (testDataConfig: TestDataConfig) => {

                                let testQuestionData: Object = {};

                                testDataConfig.topicList.forEach(
                                    (topic: TestDataConfigTopic) => {

                                        testQuestionData[topic.id] = {
                                            answerList: topic.answerList,
                                            questionList: topic.questionList
                                        };
                                    }
                                );

                                questionData[testDataConfig.id] = testQuestionData;
                            }
                        );

                        subject$$.next(questionData);
                    }
                );
            }
        );

        return result$;
    }

    private updateBookmarks(questionData: Object): void {

        //console.warn('questionNumberList', questionData);

        const testIdList: string[] = Object.keys(questionData);
        //console.warn('testIdList', testIdList);

        // For each test
        testIdList.forEach(

            (testId: string) => {

                //console.warn('testId', testId);

                //
                // First check if any bookmarked question was deleted
                //

                // Load bookmarks for the current test
                const bookmarkList: TestDataConfigTopic[] = this.loadBookmarks(testId);
                //console.warn('bookmarkList', JSON.stringify(bookmarkList));

                // For each topic in the bookmark list
                bookmarkList.forEach(
                    (bookmarkTopic: TestDataConfigTopic) => {

                        //console.warn('bookmarkTopic', bookmarkTopic);
                        const topicQuestionList: string[] =
                            questionData[testId][bookmarkTopic.id].questionList;
                        //console.warn('topicQuestionList', topicQuestionList);

                        // For each bookmarked question in the topic
                        bookmarkTopic.questionList.forEach(

                            (questionNumber: string) => {
                                //console.warn('questionNumber', questionNumber);

                                // Check if the question is still available in the test

                                const idx: number = topicQuestionList.findIndex(
                                    number => questionNumber == number
                                );

                                // If the bookmarked question number was not found in the new exam questions list,
                                // then the question was deleted. Delete it's bookmark
                                if (idx == -1) {

                                    console.warn('The following question was not found. The bookmark is deleted. questionNumber:', questionNumber);

                                    this.deleteBookmark(
                                        bookmarkList,
                                        bookmarkTopic.id,
                                        questionNumber);

                                    //console.warn('Updated bookmarkList', bookmarkList);

                                }
                            }
                        );
                    }
                );
                this.saveBookmarks(testId, bookmarkList);
            }

        );
    }

    private updateStatistics(questionData: Object): void {

        //console.warn('questionNumberList', questionData);

        const testIdList: string[] = Object.keys(questionData);
        //console.warn('testIdList', testIdList);

        // For each test
        testIdList.forEach(

            (testId: string) => {

                console.warn('testId', testId);

                const statisticsKey = `${testId}_${Feature.STATISTICS}`;
                const statisticsStr = localStorage[statisticsKey];

                // If the test statistics exists
                if (statisticsStr != null) {
                    const statistics = JSON.parse(statisticsStr);
                    //console.warn('statistics', JSON.stringify(statistics));
                    const newStatistics = {
                        total_statistics: {
                            correctAnswerCount: 0,
                            incorrectAnswerCount: 0
                        }
                    };

                    const correctlyAnsweredQuestionsKey = `${testId}_${Feature.CORRECTLY_ANSWERED_QUESTIONS}`;
                    const correctlyAnsweredQuestions = JSON.parse(localStorage[correctlyAnsweredQuestionsKey]);
                    //console.warn('correctlyAnsweredQuestions', correctlyAnsweredQuestions);
                    const newCorrectlyAnsweredQuestions = {};

                    const incorrectlyAnsweredQuestionsKey = `${testId}_${Feature.INCORRECTLY_ANSWERED_QUESTIONS}`;
                    const incorrectlyAnsweredQuestions = JSON.parse(localStorage[incorrectlyAnsweredQuestionsKey]);
                    //console.warn('incorrectlyAnsweredQuestions', incorrectlyAnsweredQuestions);
                    const newIncorrectlyAnsweredQuestions = {};

                    const answerSelectionsKey = `test_${testId}_${Feature.ANSWER_SELECTIONS}`;
                    const answerSelections = JSON.parse(localStorage[answerSelectionsKey]);
                    //console.warn('answerSelections', answerSelections);
                    const newAnswerSelections = {};

                    const oldCorrectAnswerList = localStorage['correct_answer_list'] == null ?
                        {} :
                        JSON.parse(localStorage['correct_answer_list']);
                    //console.warn('oldCorrectAnswerList', oldCorrectAnswerList);
                    const newCorrectAnswerList = {};

                    const topicList = questionData[testId];
                    //console.warn('topicList', topicList);

                    const topicIdList = Object.keys(topicList);

                    // For every topic
                    topicIdList.forEach(
                        (topicId: string) => {

                            //console.warn('topicId', topicId);

                            const topicQuestionNumList: string[] = topicList[topicId].questionList;
                            const topicCorrectAnswerList: number[][] = topicList[topicId].answerList;
                            //console.warn('topicQuestionNumList', topicQuestionNumList);
                            //console.warn('topicCorrectAnswerList', topicCorrectAnswerList);

                            // For each question in the topic
                            for (let i = 0; i < topicQuestionNumList.length; i++) {

                                const questionNumber: string = topicQuestionNumList[i];

                                //console.warn('questionNumber', questionNumber);

                                // Check if the question was answered
                                // and that the correct answers did not change
                                if (
                                    answerSelections[questionNumber] != null &&
                                    this.compareArrays(
                                        oldCorrectAnswerList[questionNumber],
                                        topicCorrectAnswerList[i]
                                    )
                                ) {
                                    //console.warn('The following question was answered. questionNumber', questionNumber);

                                    if (newStatistics[topicId] == null) {
                                        newStatistics[topicId] = {
                                            correctAnswerCount: 0,
                                            incorrectAnswerCount: 0
                                        }
                                    }

                                    // Save the correct answers for future updates
                                    newCorrectAnswerList[questionNumber] =
                                        oldCorrectAnswerList[questionNumber];

                                    // Save the questions answer selections and statistics
                                    newAnswerSelections[questionNumber] =
                                        answerSelections[questionNumber];

                                    const isCorrect: boolean =
                                        correctlyAnsweredQuestions[questionNumber] != null;

                                    if (isCorrect) {
                                        newCorrectlyAnsweredQuestions[questionNumber] =
                                            correctlyAnsweredQuestions[questionNumber];
                                        newStatistics[topicId].correctAnswerCount++;
                                        newStatistics.total_statistics.correctAnswerCount++;
                                    } else {
                                        newIncorrectlyAnsweredQuestions[questionNumber] =
                                            incorrectlyAnsweredQuestions[questionNumber];
                                        newStatistics.total_statistics.incorrectAnswerCount++;
                                        newStatistics[topicId].incorrectAnswerCount++;
                                    }
                                }

                            }
                        }
                    );
                    console.warn('statistics', statistics);
                    console.warn('newStatistics', newStatistics);
                    console.warn('correctlyAnsweredQuestions', correctlyAnsweredQuestions);
                    console.warn('newCorrectlyAnsweredQuestions', newCorrectlyAnsweredQuestions);
                    console.warn('incorrectlyAnsweredQuestions', incorrectlyAnsweredQuestions);
                    console.warn('newIncorrectlyAnsweredQuestions', newIncorrectlyAnsweredQuestions);
                    console.warn('answerSelections', answerSelections);
                    console.warn('newAnswerSelections', newAnswerSelections);
                    localStorage['correct_answer_list'] = JSON.stringify(newCorrectAnswerList);
                    localStorage[statisticsKey] = JSON.stringify(newStatistics);
                    localStorage[correctlyAnsweredQuestionsKey] = JSON.stringify(newCorrectlyAnsweredQuestions);
                    localStorage[incorrectlyAnsweredQuestionsKey] = JSON.stringify(newIncorrectlyAnsweredQuestions);
                    localStorage[answerSelectionsKey] = JSON.stringify(newAnswerSelections);
                }
            }
        );
    }



    private updateBookmarks150(): void {

        // For all tests in the deleted questions list
        for (let i = 0; i < this.deletedQuestionList.length; i++) {

            // Get the current test
            const test = this.deletedQuestionList[i];
            console.warn('test:', test);

            // Load bookmarks for the current test
            const bookmarkList: TestDataConfigTopic[] = this.loadBookmarks(test.testId);
            console.warn('bookmarkList', bookmarkList);

            // Get the topic list from the deleted question list
            const topicList = Object.keys(test.questionList);
            console.warn('topicList', topicList);

            // For each topic with deleted questions
            for (let j = 0; j < topicList.length; j++) {

                const topicId: string = topicList[j];
                const questionList = test.questionList[topicId];
                console.warn('topicId', topicId, 'questionList', questionList);

                // for each deleted question
                for (let k = 0; k < questionList.length; k++) {

                    // Delete it's bookmark
                    this.deleteBookmark(
                        bookmarkList,
                        topicId,
                        questionList[k]);
                }
            }

            this.saveBookmarks(test.testId, bookmarkList);
        }
    }

    private compareArrays(
        array1: number[],
        array2: number[]
    ): boolean {

        let equal = array1 != null &&
            array2 != null &&
            array1.length == array2.length;

        if (equal) {
            for (let i = 0; i < array1.length; i++) {
                if (array1[i] != array2[i]) {
                    equal = false;
                    break;
                }
            }
        }
        //console.warn('compareArrays', array1, array2, 'equal', equal);
        return equal;
    }

    private loadBookmarks(testId: string): TestDataConfigTopic[] {
        const key: string = `${testId}_${Feature.BOOKMARK_LIST}`;
        const storedValue: string = localStorage[key];
        let list: TestDataConfigTopic[] = storedValue == null ? [] : JSON.parse(storedValue);
        return list;
    }

    private saveBookmarks(
        testId: string,
        bookmarkList: TestDataConfigTopic[]
    ): void {
        const key: string = `${testId}_${Feature.BOOKMARK_LIST}`;
        localStorage[key] = JSON.stringify(bookmarkList);
    }

    private deleteBookmark(
        bookmarkList: TestDataConfigTopic[],
        topicId: string,
        questionNum: string
    ): void {

        console.warn('Delete bookmark', topicId, questionNum);

        // Find the topic
        const topicIndex: number = bookmarkList.findIndex(value => value.id == topicId);
        if (topicIndex != -1) {

            //console.warn('Topic FOUND');

            // Find the question in the topic
            const questionIndex: number = bookmarkList[topicIndex].questionList.indexOf(questionNum);

            // If the question was found, then delete it from the question list
            if (questionIndex > -1) {
                //console.warn('Bookmark FOUND');

                bookmarkList[topicIndex].questionList.splice(questionIndex, 1);

                // If it was the last bookmark in the topic, then delete the topic too
                if (bookmarkList[topicIndex].questionList.length == 0) {
                    bookmarkList.splice(topicIndex, 1);
                }
            } else {
                console.warn('!!! Bookmark NOT found !!! topicId', topicId, 'question num', questionNum);
            }

        } else {
            console.warn('Topic NOT found');
        }

    }



    private updateStatistics150(): void {
        console.warn('---------------------------   UPDATE STATISTICS   ----------------------------');

        this.updateDeletedQuestionStatistics();
        this.updateUpdatedQuestionStatistics();
    }

    private updateDeletedQuestionStatistics(): void {

        console.warn('updateDeletedQuestionStatistics()');

        for (let i = 0; i < this.deletedQuestionList.length; i++) {

            const test = this.deletedQuestionList[i];
            console.warn('test:', test);

            const statisticsKey = `${test.testId}_${Feature.STATISTICS}`;
            const statisticsStr = localStorage[statisticsKey];

            // If the test statistics exists
            if (statisticsStr != null) {
                const statistics = JSON.parse(statisticsStr);
                console.warn('statistics', JSON.stringify(statistics));

                const correctlyAnsweredQuestionsKey = `${test.testId}_${Feature.CORRECTLY_ANSWERED_QUESTIONS}`;
                const correctlyAnsweredQuestions = JSON.parse(localStorage[correctlyAnsweredQuestionsKey]);
                console.warn('correctlyAnsweredQuestions', correctlyAnsweredQuestions);

                const incorrectlyAnsweredQuestionsKey = `${test.testId}_${Feature.INCORRECTLY_ANSWERED_QUESTIONS}`;
                const incorrectlyAnsweredQuestions = JSON.parse(localStorage[incorrectlyAnsweredQuestionsKey]);
                console.warn('incorrectlyAnsweredQuestions', incorrectlyAnsweredQuestions);

                const answerSelectionsKey = `test_${test.testId}_${Feature.ANSWER_SELECTIONS}`;
                const answerSelections = JSON.parse(localStorage[answerSelectionsKey]);
                console.warn('answerSelections', answerSelections);

                const topicList = Object.keys(test.questionList);
                console.warn('topicList', topicList);

                // For every topic
                for (let j = 0; j < topicList.length; j++) {

                    const topicId: string = topicList[j];
                    const questionList = test.questionList[topicId];
                    console.warn('topicId', topicId, 'questionList', questionList);

                    // for every question
                    for (let k = 0; k < questionList.length; k++) {

                        const questionNum: string = questionList[k];
                        if (answerSelections[questionNum] != null) {
                            console.warn('statistics exists for ' + questionNum)

                            // Delete the answer selections
                            delete answerSelections[questionNum];

                            // chek the correctly/incorrectly answered questions lists
                            if (correctlyAnsweredQuestions[questionNum] != null) {
                                delete correctlyAnsweredQuestions[questionNum];
                                statistics[topicId].correctAnswerCount--;
                                statistics.total_statistics.correctAnswerCount--;
                            } else {
                                delete incorrectlyAnsweredQuestions[questionNum];
                                statistics[topicId].incorrectAnswerCount--;
                                statistics.total_statistics.incorrectAnswerCount--;
                            }
                        } else {
                            console.warn('statistics does NOT exists for ' + questionNum)
                        }
                    }
                }
                console.warn('statistics', statistics);
                console.warn('correctlyAnsweredQuestions', correctlyAnsweredQuestions);
                console.warn('incorrectlyAnsweredQuestions', incorrectlyAnsweredQuestions);
                console.warn('answerSelections', answerSelections);
                localStorage[statisticsKey] = JSON.stringify(statistics);
                localStorage[correctlyAnsweredQuestionsKey] = JSON.stringify(correctlyAnsweredQuestions);
                localStorage[incorrectlyAnsweredQuestionsKey] = JSON.stringify(incorrectlyAnsweredQuestions);
                localStorage[answerSelectionsKey] = JSON.stringify(answerSelections);
            }
        }
    }

    private updateUpdatedQuestionStatistics(): void {

        console.warn('updateUpdatedQuestionStatistics()');

        for (let i = 0; i < this.updatedQuestionList.length; i++) {

            const test = this.updatedQuestionList[i];
            console.warn('test:', test);

            const statisticsKey = `${test.testId}_${Feature.STATISTICS}`;
            const statisticsStr = localStorage[statisticsKey];

            // If the test statistics exists
            if (statisticsStr != null) {
                const statistics = JSON.parse(statisticsStr);
                console.warn('statistics', JSON.stringify(statistics));

                const correctlyAnsweredQuestionsKey = `${test.testId}_${Feature.CORRECTLY_ANSWERED_QUESTIONS}`;
                const correctlyAnsweredQuestions = JSON.parse(localStorage[correctlyAnsweredQuestionsKey]);
                console.warn('correctlyAnsweredQuestions', correctlyAnsweredQuestions);

                const incorrectlyAnsweredQuestionsKey = `${test.testId}_${Feature.INCORRECTLY_ANSWERED_QUESTIONS}`;
                const incorrectlyAnsweredQuestions = JSON.parse(localStorage[incorrectlyAnsweredQuestionsKey]);
                console.warn('incorrectlyAnsweredQuestions', incorrectlyAnsweredQuestions);

                const answerSelectionsKey = `test_${test.testId}_${Feature.ANSWER_SELECTIONS}`;
                const answerSelections = JSON.parse(localStorage[answerSelectionsKey]);
                console.warn('answerSelections', answerSelections);

                const topicList = Object.keys(test.questionList);
                console.warn('topicList', topicList);

                // For every topic
                for (let j = 0; j < topicList.length; j++) {

                    const topicId: string = topicList[j];
                    const questionList = test.questionList[topicId];
                    console.warn('topicId', topicId, 'questionList', questionList);

                    // for every question
                    for (let k = 0; k < questionList.length; k++) {

                        const questionNum: string = questionList[k];
                        if (answerSelections[questionNum] != null) {
                            console.warn('statistics exists for ' + questionNum)

                            // Delete the answer selections
                            delete answerSelections[questionNum];

                            // chek the correctly/incorrectly answered questions lists
                            if (correctlyAnsweredQuestions[questionNum] != null) {
                                delete correctlyAnsweredQuestions[questionNum];
                                statistics[topicId].correctAnswerCount--;
                                statistics.total_statistics.correctAnswerCount--;
                            } else {
                                delete incorrectlyAnsweredQuestions[questionNum];
                                statistics[topicId].incorrectAnswerCount--;
                                statistics.total_statistics.incorrectAnswerCount--;
                            }
                        } else {
                            console.warn('statistics does NOT exists for ' + questionNum)
                        }
                    }
                }
                console.warn('statistics', statistics);
                console.warn('correctlyAnsweredQuestions', correctlyAnsweredQuestions);
                console.warn('incorrectlyAnsweredQuestions', incorrectlyAnsweredQuestions);
                console.warn('answerSelections', answerSelections);
                localStorage[statisticsKey] = JSON.stringify(statistics);
                localStorage[correctlyAnsweredQuestionsKey] = JSON.stringify(correctlyAnsweredQuestions);
                localStorage[incorrectlyAnsweredQuestionsKey] = JSON.stringify(incorrectlyAnsweredQuestions);
                localStorage[answerSelectionsKey] = JSON.stringify(answerSelections);
            }
        }
    }


    //e.driver_truck_c1_d1_statistics
    //e.driver_truck_c1_d1_correctlyAnsweredQuestions
    //e.driver_truck_c1_d1_incorrectlyAnsweredQuestions
    //test_e.driver_truck_c1_d1_ANSWER_SELECTIONS


    private deletedQuestionList = [
        {
            testId: 'e.driver_a_a1_b',
            questionList: {
                ZeichenUndMarkierungen: ['20160', '40099', '40257'],
                Vortrittsregeln: ['40096']
            }
        },
        {
            testId: 'e.driver_f_g',
            questionList: {
                ZeichenUndMarkierungen: ['20160', '40257'],
                Vortrittsregeln: ['40096']
            }
        },
        {
            testId: 'e.driver_m',
            questionList: {
                ZeichenUndMarkierungen: ['40257']
            }
        },
        {
            testId: 'e.driver_truck_c1_d1',
            questionList: {
                FahrzeugtechnikUndUnterhalt: ['805006', '805013'],
                Guetertransport: ['808058']
            }
        },
        {
            testId: 'e.driver_truck_c_ce',
            questionList: {
                RegelnUndVorschriften: ['803073'],
                FahrzeugtechnikUndUnterhalt: ['805006', '805013'],
                Guetertransport: ['808025', '808058']
            }
        },
        {
            testId: 'e.driver_truck_d',
            questionList: {
                RegelnUndVorschriften: ['803073'],
                FahrzeugtechnikUndUnterhalt: ['805013']
            }
        }
    ];

    private updatedQuestionList = [
        {
            testId: 'e.driver_truck_c1_d1',
            questionList: {
                FahrzeugeUndAusweise: ['801018'],
                SignaleUndMarkierungen: ['802043', '802090'],
                RegelnUndVorschriften: ['803053', '803071', '803084', '803085', '803086', '803089'],
                MasseUndGewichte: ['804042'],
                FahrzeugtechnikUndUnterhalt: ['805070', '805083'],
                Anhaengerbetrieb: ['812113'],
                Guetertransport: ['808011', '808013', '808012', '808014', '808018', '808019', '808021', '808022', '808033', '808040']
            }
        },
        {
            testId: 'e.driver_truck_c_ce',
            questionList: {
                FahrzeugeUndAusweise: ['801018'],
                SignaleUndMarkierungen: ['802043', '802090'],
                RegelnUndVorschriften: ['803053', '803067', '803071', '803072', '803078', '803084', '803085', '803086', '803089'],
                MasseUndGewichte: ['804042'],
                FahrzeugtechnikUndUnterhalt: ['805004', '805070', '805083', '805098'],
                Anhaengerbetrieb: ['812113'],
                Guetertransport: ['808011', '808013', '808012', '808014', '808018', '808019', '808021', '808022', '808028', '808029', '808033', '808040']
            }
        },
        {
            testId: 'e.driver_truck_d',
            questionList: {
                SignaleUndMarkierungen: ['802090'],
                RegelnUndVorschriften: ['803053', '803072', '803084', '803085', '803086', '803089'],
                FahrzeugtechnikUndUnterhalt: ['805070', '805083']
            }
        }
    ]
}