import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { backgroundColorAnimation } from 'src/app/global/animations/background-color.animation';

@Component({
  selector: 'answer-text',
  templateUrl: './test-question-answer-list-item-text.component.html',
  styleUrls: ['./test-question-answer-list-item-text.component.css'],
  animations: [
    backgroundColorAnimation
  ]
})
export class TestQuestionAnswerListItemTextComponent implements OnInit {

  @HostBinding('@backgroundColor')
  get backgroundColorAnimation() {
    return {
      value: !this.commentsMode ? 'color1' : 'color2',
      params: {
        color1: '#2F2F2F',
        color2: this.correct ? '#03914A' : '#A00F0F'
      }
    }
  };

  //@HostBinding('innerHTML') innerHtml;

  innerHtml: string;

  @Input() commentsMode: boolean;
  @Input() correct: boolean;
  @Input() set text(value: string) {
    if (value) {
      this.innerHtml = value;
    } else {
      this.innerHtml = '';
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

}
