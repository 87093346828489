import { Injectable } from '@angular/core';
import { TestDataConfigTopic } from '../models/test/test-data-config';

/**
 * The service is used to 
 * @author Ruslan Rubtsov
 * @version 1.0.1
 */
@Injectable({
  providedIn: 'root'
})
export class ExamScoresService {

  oralMaxScore: number = 15;
  maxScore: number;
  oralPassingScore: number = 13;
  passingScore: number;

  private _oralScores: Object;
  private _scores: Object;

  constructor() { }

  calcScores(): number {

    let totalScores: number = this.maxScore;

    //console.warn('Calc scores', this._scores);

    Object.values(this._scores).forEach(
      value => {
        totalScores -= value;
      }
    );
    return totalScores;
  }

  calcOralScores(): number {

    let totalScores: number = 0;

    Object.values(this._oralScores).forEach(
      isCorrect => {
        totalScores += isCorrect ? 1 : 0;
      }
    );
    return totalScores;
  }

  init(
    topicList: TestDataConfigTopic[]
  ): void {

    this.maxScore = 0;
    this._scores = new Object();

    topicList.forEach(

      topic => {

        topic.questionList.forEach(

          questionNumber => {

            let answerCnt: number =
              topic.questionData[questionNumber].answerCount;

            this._scores[questionNumber] = answerCnt;
            this.maxScore += answerCnt;
          }
        );
      }
    );
    this.passingScore = this.maxScore * 0.9;

    //console.warn('Init scores', this._scores);
    //console.warn('maxScore', this.maxScore);
    //console.warn('passingScore', this.passingScore);

  }

  initOral(topicList: TestDataConfigTopic[]): void {

    this._oralScores = new Object();

    topicList.forEach(
      topic => {

        topic.questionList.forEach(
          questionNumber =>
            this._oralScores[questionNumber] = false
        );
      }
    );
  }

  update(
    questionNumber: string,
    correctAnswerList: number[],
    selectionList: boolean[]
  ): void {

    //console.warn('questionNumber', questionNumber, 'selectionList', selectionList);

    const answerCount: number = selectionList.length;

    // If all or none answers are marked it counts as answersCount errors
    let allSelected: boolean = true;
    selectionList.forEach(value => allSelected &&= value);
    let someSelected: boolean = false;
    selectionList.forEach(value => someSelected ||= value);

    if (allSelected || !someSelected) {

      //console.warn('All or nothing selected. allSelected', allSelected, '!someSelected', !someSelected)
      this._scores[questionNumber] = answerCount;
      //console.warn('Questions scores', this._scores[questionNumber])

    } else {

      //console.warn('Something selected')

      // In any other case you get 1 error for each selected wrong answer 
      // and one error for not selected correct answer
      let correctSelectionList: Array<boolean> =
        new Array<boolean>(answerCount);

      for (
        let i = 0;
        i < answerCount;
        i++
      ) {
        correctSelectionList[i] =
          correctAnswerList.indexOf(i + 1) != -1;
      }
      this._scores[questionNumber] = 0;

      //console.warn('correctSelectionList', correctSelectionList)

      selectionList.forEach(
        (value, index) => {
          //console.warn('selction list value, index', value, index)
          if (
            // Correct answer is not checked
            (correctSelectionList[index] && !value) ||
            // Incorrect answer is checked
            (!correctSelectionList[index] && value)
          ) {
            if (correctSelectionList[index] && !value) {
              //console.warn('Correct answer is not checked')
            } else {
              //console.warn('Incorrect answer is checked')
            }
            this._scores[questionNumber]++;
            //console.warn('Questions scores', this._scores[questionNumber])
          }
        }
      );
    }
    //console.warn('Questions scores', this._scores[questionNumber])
  }

  updateOral(
    questionNumber: string,
    isCorrect: boolean
  ): void {
    this._oralScores[questionNumber] = isCorrect;
  }
}
