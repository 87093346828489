import { Component, OnInit } from '@angular/core';
import { ButtonIcon, IButtonIcon } from '../toolbar-toggle-button/toolbar-button-icon';

@Component({
  selector: 'toolbar-help-button',
  templateUrl: './toolbar-help-button.component.html',
  styleUrls: ['./toolbar-help-button.component.css']
})
export class ToolbarHelpButtonComponent implements OnInit {

  public readonly icon: IButtonIcon = ButtonIcon.help;

  constructor() { }

  ngOnInit(): void {
  }

}
