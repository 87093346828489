import { Injectable } from '@angular/core';
import { AppConfigService } from '../app-config/app-config.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, retry, take } from 'rxjs/operators';
import { SubscriptionApiUrlService } from './subscription-api-url.service';
import { SapiHttpHeader } from '../../models/subscription-api/enums/sapi-http-header.enum';
import { SapiSubscriptionCreate } from '../../models/subscription-api/sapi-subscription-create';
import { ISapiSubscription, SapiSubscription } from '../../models/subscription-api/sapi-subscription';
import { SapiSubscriptionUpdate } from '../../models/subscription-api/sapi-subscription-update';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionApiService {

    private readonly httpGetHeaders: HttpHeaders;
    private readonly httpPostHeaders: HttpHeaders;
    private readonly httpRequestRetryCount: number;

    constructor(
        config: AppConfigService,
        private http: HttpClient,
        private urls: SubscriptionApiUrlService
    ) {
        this.httpRequestRetryCount = config.httpRequestRetryCount
        this.httpGetHeaders = new HttpHeaders(
            {
                [SapiHttpHeader.ACCEPT]: 'application/json',
                [SapiHttpHeader.API_KEY]: config.subscriptionApi.apiKey
            }
        );
        this.httpPostHeaders = new HttpHeaders(
            {
                [SapiHttpHeader.ACCEPT]: 'application/json',
                [SapiHttpHeader.API_KEY]: config.subscriptionApi.apiKey,
                [SapiHttpHeader.CONTENT_TYPE]: 'application/json'
            }
        );
    }

    public create(
        subscriptionCreate: SapiSubscriptionCreate
    ): Observable<SapiSubscription> {

        const subject$$: Subject<SapiSubscription> =
            new Subject();

        const url: string =
            this.urls.getCreateUrl();

        this.http.post<ISapiSubscription>(
            url,
            subscriptionCreate,
            {
                headers: this.httpPostHeaders
            }
        ).pipe(
            retry(this.httpRequestRetryCount),
            catchError(this.handleCreateError)
        ).subscribe(
            subscription =>
                subject$$.next(
                    new SapiSubscription(subscription)
                ),
            error => subject$$.error(error)
        );

        return subject$$
            .asObservable()
            .pipe(take(1));
    }



    public read(
        subscriptionId: string
    ): Observable<SapiSubscription> {

        const subject$$: Subject<SapiSubscription> =
            new Subject();

        const url: string =
            this.urls.getReadUrl(subscriptionId);

        this.http.get<ISapiSubscription>(
            url,
            {
                headers: this.httpGetHeaders
            }
        ).pipe(
            retry(this.httpRequestRetryCount),
            catchError(this.handleReadError)
        ).subscribe(
            subscription =>
                subject$$.next(
                    new SapiSubscription(subscription)
                ),
            error => subject$$.error(error)
        );

        return subject$$
            .asObservable()
            .pipe(take(1));
    }


    public readByUserId(
        userId: string,
        password: string
    ): Observable<SapiSubscription> {

        const subject$$: Subject<SapiSubscription> =
            new Subject();

        const url: string =
            this.urls.getReadByUserIdUrl(userId, password);

        this.http.get<ISapiSubscription>(
            url,
            {
                headers: this.httpGetHeaders
            }
        ).pipe(
                retry(this.httpRequestRetryCount),
                catchError(this.handleReadError)
            ).subscribe(
                subscription =>
                    subject$$.next(
                        new SapiSubscription(subscription)
                    ),
                error => subject$$.error(error)
            );

        return subject$$
            .asObservable()
            .pipe(take(1));
    }

    public update(
        subscriptionId: string,
        subscriptionUpdate: SapiSubscriptionUpdate
    ): Observable<SapiSubscription> {

        const subject$$: Subject<SapiSubscription> =
            new Subject();

        const url: string =
            this.urls.getUpdateUrl(subscriptionId);

        this.http.put<ISapiSubscription>(
            url,
            subscriptionUpdate,
            {
                headers: this.httpPostHeaders
            }
        ).pipe(
            retry(this.httpRequestRetryCount),
            catchError(this.handleUpdateError)
        ).subscribe(
            subscription =>
                subject$$.next(
                    new SapiSubscription(subscription)
                ),
            error => subject$$.error(error)
        );

        return subject$$
            .asObservable()
            .pipe(take(1));
    }

    private handleCreateError(error: HttpErrorResponse) {

        let errorId: string = 'login_page.connection_failed';

        if (error.status === 0) {

            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error);

        } else {

            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(
                `Backend returned code ${error.status}, body was: `, error.error);

            // Status === 400 - Bad developer
            // Status === 401 - Bad password
            // Status === 404 - Bad user ID
            // Status === 500 - Problems on the server
            errorId = 'subscription_api.create_error_' + error.status;
        }

        return throwError(errorId);
    }
    private handleReadError(error: HttpErrorResponse) {

        let errorId: string = 'login_page.connection_failed';

        if (error.status === 0) {

            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error);

        } else {

            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(
                `Backend returned code ${error.status}, body was: `, error.error);

            // Status === 400 - Bad developer
            // Status === 401 - Bad password
            // Status === 404 - Bad user ID
            // Status === 500 - Problems on the server
            errorId = 'subscription_api.read_error_' + error.status;
        }

        return throwError(errorId);
    }

    private handleUpdateError(error: HttpErrorResponse) {

        let errorId: string = 'login_page.connection_failed';

        if (error.status === 0) {

            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error);

        } else {

            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(
                `Backend returned code ${error.status}, body was: `, error.error);

            // Status === 400 - Bad developer
            // Status === 401 - Bad password
            // Status === 404 - Bad user ID
            // Status === 500 - Problems on the server
            errorId = 'subscription_api.update_error_' + error.status;
        }

        return throwError(errorId);
    }
}  