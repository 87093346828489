<toolbar-toggle-button 
                       [icon]="'navigation'"
                       [toggleButton]="'navigation'"
                       [(isOn)]="isTopicMenuOn"
                       class="topic-button">
    <topic-menu [@visibility]="isTopicMenuOn?'visible':'invisible'"
                [lessonTestFolder]="lessonTestFolder"
                [topicList]="topicList"
                (selectTopic)="onSelectTopic($event)"
                (clickOutside)="isTopicMenuOn = false">
    </topic-menu>
</toolbar-toggle-button>