import { Injectable } from "@angular/core";
import { TestConfig } from "src/app/models/config/config-types";
import { AppDataProvider } from "src/app/providers/app-data/app-data-provider";
//import { IProjConfig } from "../proj-config/proj-config.interfaces";
import { IAllCategoriesTestConfig, IAppConfig } from "./i-app-config";


@Injectable()
export class AppConfigProvider {

    private _appConfig: IAppConfig;
    //private _projConfig: IProjConfig;

    constructor(private dataProvider: AppDataProvider) {
    }

    public get appConfig(): IAppConfig {
        return this._appConfig;
    }

    public load(): Promise<boolean> {
        return new Promise((resolve, reject) => {

            this.dataProvider.appConfig.subscribe(
                config => {
                    this._appConfig =
                        AppConfigProvider.
                            updateAllCategoriesTestConfig(config);
                    resolve(true);
                },
                error => {
                    reject(error);
                });
        })
    }

    private static updateAllCategoriesTestConfig(config: IAppConfig): IAppConfig {

        const configList: IAllCategoriesTestConfig =
            config.allCategoriesTestConfig;

        if (configList != null) {
            for (const key in configList) {
                configList[key] = new TestConfig(configList[key]);
            }
        }

        return config;
    }
}

export function appConfigProviderFactory(provider: AppConfigProvider) {
    return () => provider.load();
}