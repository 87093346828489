import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageId } from 'src/app/models/config/config-types';
import { IProjConfig, IProjConfig_StringId, IProjConfig_Url } from './proj-config.interfaces';


@Injectable()
export class ProjConfigService {

    public static readonly APP_CONFIG_URL: string = 'assets/data/config/app-config.json';
    public static readonly PROJ_CONFIG_URL: string = 'assets/data/config/proj-config.json';

    demo: boolean;
    languageIdList: LanguageId[];
    stringId: IProjConfig_StringId;
    url: IProjConfig_Url;

    constructor(
        private http: HttpClient,
        private translate: TranslateService
    ) {
        //this.load();
    }

    get acceptLanguageUrl(): string {
        return this.url.acceptLanguageUrl;
    }

    get adScreenShortVideoGuideUrl(): string {
        return this.translate.instant(this.stringId.adScreenShortVideoGuideUrl);
    }

    get adScreenVideoGuide1Url(): string {
        return this.translate.instant(this.stringId.adScreenVideoGuide1Url);
    }
    get adScreenVideoGuide2Url(): string {
        return this.translate.instant(this.stringId.adScreenVideoGuide2Url);
    }

    get clubPageHeaderImg(): string {
        return this.url.clubPageHeaderImg;
    }

    get defaultServiceWorker(): string {
        return this.url.defaultServiceWorker;
    }

    get profHomeUrl(): string {
        return this.url.home;
    }

    languageListUrl(lang: string): string {
        return `${this.url.configBase}/language_list.${lang}.json`;
    }

    lessonTestDataConfigUrl(
        lang: string,
        dataFolder: string
    ): string {
        return `${this.url.lessonTestDataBase}/${dataFolder}/${lang}/config.json`;
    }

    lessonTestTopicUrl(
        lang: string,
        dataFolder: string,
        topicId: string
    ): string {
        return `${this.url.lessonTestDataBase}/${dataFolder}/${lang}/${topicId}.json`;
    }

    testConfigListUrl(lang: string): string {
        return `${this.url.configBase}/test_config_list.${lang}.json`
    }

    testDataConfigUrl(
        lang: string,
        testId: string
    ): string {
        return `${this.url.testDataBase}/${lang}/${testId}/config.json`;
    }

    testTopicUrl(
        lang: string,
        testId: string,
        topicId: string
    ): string {
        return `${this.url.testDataBase}/${lang}/${testId}/${topicId}.json`;
    }

    public load(): Promise<boolean> {
        return new Promise(
            (resolve, reject) => {

                this.http.get<IProjConfig>(ProjConfigService.PROJ_CONFIG_URL).subscribe(
                    config => {
                        this.demo = config.demo;
                        this.languageIdList = config.languageIdList;
                        this.stringId = config.stringId;
                        this.url = config.url;
                        resolve(true);
                    },
                    error => {
                        console.log('ProjConfigService load error:', error);
                        reject(error);
                    });
            }
        );
    }

}
export function projConfigProviderFactory(service: ProjConfigService) {
    return () => service.load();
}
/*export function projConfigProviderFactory(
    appData: AppDataProvider,
    translate: TranslateService
) {
    return new ProjConfigService(appData, translate);
}*/