import { BrowserInfo } from "src/app/global/utils/browser-info";
import { ISapiAddress, SapiAddress } from "./sapi-address";
import { ISapiDevice, SapiDevice } from "./sapi-device";
import { SapiProductId, SapiProductIdFactory } from "./enums/sapi-product-id.enum";
import { SapiDeviceType } from "./enums/sapi-device-type.enum";

export class SapiSubscription {

    address: SapiAddress;
    creationDate: Date;
    devices: SapiDevice[];
    id: string;
    lastAccessTime: Date;
    length: number;
    userId: string;
    password: string;
    email: string;
    language: string;
    paymentId: string;
    productId: SapiProductId;
    startDate: Date;

    constructor(source: ISapiSubscription) {
        this.copy(source);
    }

    public get device(): SapiDevice {
        return this.devices.find(
            (device: SapiDevice) => {
                return device.type === (
                    BrowserInfo.isMobile ?
                        SapiDeviceType.MOBILE :
                        SapiDeviceType.DESKTOP
                )
            }
        );
    }

    public get expirationDate(): Date {
        return new Date(
            this.startDate.getTime() +
            this.length * 24 * 60 * 60 * 1000
        );
    }

    public get isExpired(): boolean {

        // If subscription is not started yet
        if (this.startDate == null) {
            return false;
        }

        let now: number = new Date().getTime();
        let timeDiff: number = now - this.startDate.getTime();
        let daysPassed: number = timeDiff / (24 * 60 * 60 * 1000);

        return daysPassed > this.length;
    }

    private copy(source: ISapiSubscription) {
        this.address = new SapiAddress(source.address);
        this.creationDate = new Date(source.creationDate);
        this.devices = [
            new SapiDevice(source.devices[0]),
            new SapiDevice(source.devices[1])
        ];
        this.id = source.id;
        this.lastAccessTime = new Date(source.lastAccessTime);
        this.length = source.length;
        this.userId = source.userId;
        this.password = source.password;
        this.email = source.email;
        this.language = source.language;
        this.paymentId = source.paymentId;
        this.productId = SapiProductIdFactory.fromString(source.productId);
        this.startDate = source.startDate == null ?
            null :
            new Date(source.startDate);
    }
}

export interface ISapiSubscription {

    readonly address: ISapiAddress;
    readonly creationDate: string;
    readonly devices: ISapiDevice[];
    readonly id: string;
    readonly lastAccessTime: string;
    readonly length: number;
    readonly userId: string;
    readonly password: string;
    readonly email: string;
    readonly language: string;
    readonly paymentId: string;
    readonly productId: string;
    readonly startDate: string;
}