<header [label]="headerLabel"
        [labelId]="'statistics_page.evaluation'"
        [showImage]="pageState.hs.isHeaderImgVisible"
        [showLabelFromId]="pageState.hs.isHeaderLabelFromIdVisible"
        [showLeftButton]="showLeftButton"
        [showRightButton]="showRightButton"
        (next)="onNext()"
        (previous)="onPrevious()">
</header>

<adaptive-test-progress *ngIf="questionList.isAdaptiveLearnMode"
                        [@visibility]="pageState.adaptiveTestProgressVisibility"
                        [failedAttemptCount]="questionList.failedAttemptCount"
                        [successfulAttemptCount]="questionList.successfulAttemptCount"
                        [requiredAttemptCount]="questionList.requiredAttemptCount">

</adaptive-test-progress>

<!--<div class="content-container">-->
<div class="error-message"
     [@visibility]="pageState.errorMsgVisibility">
    <p>{{errorMessageId | translate}}</p>
</div>

<div class="main-view">

    <mat-spinner *ngIf="pageState.isLoadingProgressVisible"
                 mode="indeterminate"
                 class="custom-spinner"></mat-spinner>

    <div [@visibility]="pageState.mainViewVisibility"
         class="router-outlet-container">

        <router-outlet (activate)="setRoutedComponent($event)"
                       (deactivate)="unsetRoutedComponent($event)"></router-outlet>
    </div>

</div>
<!--</div>-->

<toolbar>
    <!-- Exit button -->
    <toolbar-exit-button (click)="onExit()">
    </toolbar-exit-button>

    <!-- Navigation button -->
    <toolbar-topic-select [@visibility]="{
        value:pageState.tbs.navigationBtnVisibility,
        params:{displayValue:'flex'}}"
                          [topicList]="questionList.topicList"
                          (selectTopic)="onSelectTopic($event)">
    </toolbar-topic-select>

    <!-- Bookmark button-->
    <toolbar-bookmarks [@visibility]="{
                            value:pageState.tbs.bookmarksBtnVisibility,
                            params:{displayValue:'flex'}
                        }"
                       [showAddBookmark]="isAddBookmarkVisible"
                       [showSelectBookmark]="isSelectBookmarkVisible"
                       (addBookmark)="onAddBookmark()"
                       (selectBookmark)="onSelectBookmark()">
    </toolbar-bookmarks>

    <toolbar-toggle-button [@visibility]="{
        value:pageState.tbs.commentsBtnVisibility,
    params:{displayValue:'flex'}}"
                           [icon]="'comments'"
                           [(isOn)]="commentsMode"
                           [toggleButton]="'commentsMode'"
                           togglableFromOutside="false">
    </toolbar-toggle-button>

    <toolbar-toggle-button [@visibility]="pageState.tbs.statisticsBtnVisibility"
                           [(isOn)]="isStatisticsOn"
                           [icon]="'statistics'"
                           [toggleButton]="'statistics'"
                           togglableFromOutside="false">
    </toolbar-toggle-button>

    <toolbar-language-select>
    </toolbar-language-select>

</toolbar>