import {
  Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, EventEmitter,
  OnInit, Output, Renderer2, ViewContainerRef, ViewChild, Input
} from '@angular/core';
import { ETopicMenuItemState, ITopicMenuItem } from 'src/app/routed-modules/lesson/models/lesson-data-models';
import { PersistentStateService } from 'src/app/services/persistent-state/persistent-state.service';
import { TopicMenuItemComponent } from './topic-menu-item/topic-menu-item.component';


/**
 * The component is used to display 
 * @author Ruslan Rubtsov
 * @version 1.0.1
 */
@Component({
  selector: 'topic-menu',
  templateUrl: './topic-menu.component.html',
  styleUrls: ['./topic-menu.component.css']
})
export class TopicMenuComponent implements OnInit {


  @Input() lessonTestFolder: string;
  /**
   * Component parameter that 
   */
  @Input()
  set topicList(list: ITopicMenuItem[]) {

    if (list != null) {
      this._topicList = list;
      this.updateView();
    }
  }

  /**
 * The component output parameter, which is used to notify parent component
 */
  @Output() selectTopic: EventEmitter<string> = new EventEmitter();

  /**
   * The member contains the reference to the view contaier, which will be used for the dymnamic 
   */
  @ViewChild('view_container', { read: ViewContainerRef })
  set viewContainer(ref: ViewContainerRef) {

    if (ref != null) {
      this._viewContainer = ref;
      this.updateView();
    }
  }

  private componentFactory: ComponentFactory<TopicMenuItemComponent>;
  private _topicList: ITopicMenuItem[];
  private _viewContainer: ViewContainerRef;

  /**
   * Class constructor
   * @param componentFactoryResolver The service is required for the dynamic component creation
   * @see ComponentFactoryResolver
   * @param persistentState The injectable persitent state service, which is used to staore the data 
   * persistent between the application executions.
   * @see PersistentStateService
   * @param renderer Angular service. Used by the component for listening Mouse Click events
   * @see Renderer2
   */
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private persistentState: PersistentStateService,
    private renderer: Renderer2
  ) { }

  /**
   * OnInit interface implementation
   * @see OnInit
   */
  ngOnInit(): void {
    this.componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(TopicMenuItemComponent);
  }

  private updateView(): void {

    if (
      this._viewContainer != null &&
      this._topicList != null
    ) {

      this._viewContainer.clear();

      this._topicList.forEach(
        topic =>
          this.createMenuItemComponent(topic)
      );
    }
  }

  private createMenuItemComponent(topic: ITopicMenuItem) {

    // Create component
    let componentRef: ComponentRef<TopicMenuItemComponent> =
      this._viewContainer.createComponent(this.componentFactory);

    // Pass data
    componentRef.instance.topic = topic;

    // Assign mouse click handler
    if (topic.state != ETopicMenuItemState.DISABLED) {
      this.renderer.listen(
        componentRef.location.nativeElement,
        'click',
        (event) => {
          this.onTopicClick(event, topic);
        }
      );
    }
  }

  private onTopicClick(
    event: PointerEvent,
    topic: ITopicMenuItem
  ) {
    event.stopPropagation();
    if (topic.state != ETopicMenuItemState.DISABLED) {
      this.selectTopic.emit(topic.id);
    }
  }

}
