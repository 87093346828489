import { Component, OnInit } from '@angular/core';
import { backgroundColorAnimation } from 'src/app/global/animations/background-color.animation';
import { heightOpacityAnimation } from 'src/app/global/animations/height-opacity.animation';

import { TestAnswer } from 'src/app/models/test/test-data-config';
import { TestDataService } from 'src/app/services/test/test-data.service';
import { TestPageCommunicationService } from 'src/app/services/test/test-page-communication.service';

/**
 * The component is used to display 
 * @author Ruslan Rubtsov
 * @version 1.0.1
 */
@Component({
  selector: 'test-question-answer-list-item',
  templateUrl: './test-question-answer-list-item.component.html',
  styleUrls: ['./test-question-answer-list-item.component.css'],
  animations: [
    heightOpacityAnimation,
    backgroundColorAnimation
  ]
})
export class TestQuestionAnswerListItemComponent implements OnInit {

  // TODO: Delete since it is not used aymore
  //readonly letterList: string[] = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'];

  get answer(): TestAnswer {
    return this._answer;
  }
  set answer(answer: TestAnswer) {
    this.hasExplanation = answer.explanation.trim().length != 0;
    this._answer = answer;
  }
  private _answer: TestAnswer;

  commentsMode: boolean = false;
  correct: boolean = false;
  hasExplanation: boolean = true;
  imageBaseUrl: string = '';
  index: number;
  selected: boolean = false;

  /**
   * Class constructor
   * @param communication The service, whichh is used for communication between compnents on the Test Page
   * @see TestPageCommunicationService
   * @param testData The service  which is used to load test data (topic list data, etc.)
   * @see TestDataService
   */
  constructor(
    private communication: TestPageCommunicationService,
    private testData: TestDataService
  ) { }

  /**
   * OnInit interface implementation
   * @see OnInit
   */
  ngOnInit(): void {
    // KOSTIL: TODO: move image base url to AppConfig
    this.imageBaseUrl = `${this.testData.imageBaseUrl}/`;
  }

  onClick(event: MouseEvent) {

    if (!this.commentsMode) {
      this.selected = !this.selected;
      this.communication.selectAnswer(this.index);
    }
  }
}
