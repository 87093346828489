interface IQueueEl<T> {
    next: IQueueEl<T>;
    prev: IQueueEl<T>;
    value: T;
}

class QueueEl<T> implements IQueueEl<T> {

    next: IQueueEl<T>;
    prev: IQueueEl<T>;

    get value(): T {
        return this._value;
    };
    private _value: T;

    constructor(value: T) {
        this.next = null;
        this.prev = null;
        this._value = value;
    }
}

export interface IQueue<T> {
    readonly first: T;
    readonly last: T;
    readonly isEmpty: boolean;
    enqueue(el: T): void;
    dequeue(): T;
}

export class Queue<T> implements IQueue<T> {

    get first(): T {
        return this._first?.value;
    }

    get last(): T {
        return this._last?.value;
    }

    get isEmpty(): boolean {
        return this._first == null;
    };

    get size(): number {
        return this._size;
    }
    private _size: number = 0;

    enqueue(el: T): void {

        this._size++;

        const prevLast: IQueueEl<T> = this._last;

        this._last = new QueueEl<T>(el);
        this._last.next = null;
        this._last.prev = prevLast;

        if (prevLast != null) {
            prevLast.next = this._last;
        }

        if (this._first == null) {
            this._first = this._last;
        }

    };

    dequeue(): T {

        this._size--;

        // If the queue is empty, then return null
        // We all love null pointer exceptions!
        if (this.isEmpty) {
            return null;
        }

        // Save the value to be returned
        const result: T = this._first.value;

        // If we dequeue the last element, 
        // then set first and last to null
        if (this._first.next == null) {
            this._first = null;
            this._last = null;
        } else {
            // If more elements exists, then
            // make the second element the first one

            this._first = this._first.next;
            this._first.prev = null;
        }

        return result;
    };

    forEach(
        callbackFn: (value: T, idx?: number) => void
    ): void {

        if (this._first == null) {
            return;
        }

        let i: number = 0;
        let el: IQueueEl<T> = this._first;

        do {
            callbackFn(el.value, i++);
            el = el.next;
        } while (el != null)
    }

    fromArray(array: T[]) {

        this._first = null;
        this._last = null;

        array.forEach(
            value =>
                this.enqueue(value)
        )
    }

    toArray(): T[] {

        let array: T[] = new Array<T>(this._size);
        this.forEach(
            (value: T, idx: number) =>
                array[idx] = value
        );

        return array;
    }

    private _first: IQueueEl<T> = null;
    private _last: IQueueEl<T> = null;

}