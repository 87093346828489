/**
 * A lsit of component URL. It is used in Angular navigation
 * @author Ruslan Rubtsov
 * @version 1.0.1
 */
export enum RouterUrlList {
    AD_SCREEN = 'ad-screen',
    CLUB = 'club',
    CONFIGURATION = 'config',
    EXAM_PAGE = 'exam',
    EXPERT_COMMENTS = 'expert-comments',
    HELP = 'help',
    LESSON = 'lesson',
    LESSON_TEST = 'lesson-test',
    LESSON_TEST_START = 'lesson-test-start',
    LOGIN = 'login',
    LGOUT = 'logout',
    MULTIPLE_CHOICE_QUESTION = 'tq/mc',
    PAYMENT_RESULT = 'payment-result',
    //PfPaymentSuccess = 'pf-payment-success',
    IMAGE_ZOOM = 'image',
    PURCHASE = 'purchase',
    QUESTION_LIST = 'question-list',
    SHOP_LIST = 'shop-list',
    START_PAGE = 'home',
    TEST_OPTIONS = 'test-options',
    TEST = 'test',
    TOTAL_STATISTICS = 'statistics',
    TOPIC_STATISTICS = 'topic-statistics',
    TYPE_IN_QUESTION = 'tq/ti'
}
