import { Feature, PersistentStateKey } from "src/app/services/persistent-state/keys";

export class AppVersion {

    private static _version = null;

    static get version(): number {
  
      if (AppVersion._version == null) {

        // Get the current app version from local storage
        const key: string = PersistentStateKey.get(Feature.APP_VERSION);
        const strValue: string = localStorage[key];
        AppVersion._version = strValue == null ? 0 : JSON.parse(strValue);
      }
      return AppVersion._version;
    }
  
    static set version(version: number) {
      AppVersion._version = version;
  
      // Save the new app version
      const key: string = PersistentStateKey.get(Feature.APP_VERSION);
      localStorage[key] = JSON.stringify(version);
    }
  
}