import { Injectable } from '@angular/core';
import { IToggleButtonStateIcons } from './toolbar-button-icon';

@Injectable({
  providedIn: 'root'
})
export class ToggleButtonStateIconsService {

  public getIcons(iconSetName: string): IToggleButtonStateIcons {
    return this.iconSet[iconSetName];
  }

  private iconSet: Object;

  constructor() {
    this.iconSet = {
      bookmarks: {
        on: 'assets/images/ui/test_page/bookmarks_on.png',
        off: 'assets/images/ui/test_page/bookmarks_off.png'
      },
      comments: {
        off: 'assets/images/ui/test_page/comments_off.png',
        on: 'assets/images/ui/test_page/comments_on.png'
      },
      copy: {
        off: 'assets/images/ui/copy-icon.png',
        on: 'assets/images/ui/purchase_page/check.png'
      },
      language: {
        on: 'assets/images/ui/test_page/lang_on.png',
        off: 'assets/images/ui/test_page/lang_off.png'
      },
      navigation: {
        on: 'assets/images/ui/test_page/navigation_on.png',
        off: 'assets/images/ui/test_page/navigation_off.png'
      },
      profile: {
        on: 'assets/images/ui/toolbar/selected_profile_icon.png',
        off: 'assets/images/ui/toolbar/unselected_profile_icon.png'
      },
      sound: {
        on: 'assets/images/ui/lesson_page/sound_on.png',
        off: 'assets/images/ui/lesson_page/sound_off.png'
      },
      statistics: {
        off: 'assets/images/ui/test_page/statistics_off.png',
        on: 'assets/images/ui/test_page/statistics_on.png'
      }

    }
  }
}
