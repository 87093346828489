import { TestDataConfigTopic } from "src/app/models/test/test-data-config";
import { Feature } from "src/app/services/persistent-state/keys";
import { PersistentStateService } from "src/app/services/persistent-state/persistent-state.service";

/**
 * An utility class that is used to create a random list of the qustions
 * for an oral exam. The rules are the following: from all topics select 
 * three. From each selected topic use only five questions. Avoid using 
 * the same topic again in the next exam untill all other topics are
 * used.
 * @author Ruslan Rubtsov
 * @version 1.0.1
 */
export class OralQuestionSetRandomizer {

    static readonly EXAM_TOPIC_COUNT: number = 3;
    static readonly QUESTION_PER_TOPIC_COUNT: number = 5;

    private readonly _topicList: TestDataConfigTopic[];
    private readonly _persistentState: PersistentStateService;

    /**
     * The class constructor.
     * @param topicList The list of all test questions
     * @see TestDataConfigTopic
     * @param persistentState The injectable persitent state service, which is used to store the data 
     * persistent between the application executions.
     * @see PersistentStateService
     */
    constructor(topicList: TestDataConfigTopic[],
        persistentState: PersistentStateService) {
        this._topicList = topicList;
        this._persistentState = persistentState;
    }

    public get topicList(): TestDataConfigTopic[] {
        return this.createTopicList();
    }

    /**
     * The method creates default usage statistics list.
     */
    private createDefaultTopicUsageStatistics(): TopicUsageStatistics[] {

        let statistics: TopicUsageStatistics[] = [];

        this._topicList.forEach(topic => statistics.push({
            topicId: topic.id,
            usageCount: 0
        }));

        return statistics;
    }

    /**
     * The method tries to load the topic usage statistics from the local data.
     * If no statistics exists yet, then creates default one.
     */
    private getTopicUsageStatistics(): TopicUsageStatistics[] {

        let statistics: TopicUsageStatistics[] =
            this._persistentState.get(Feature.EXAM_TOPIC_USAGE_STATISTICS);

        return statistics != null ? statistics :
            this.createDefaultTopicUsageStatistics();
    }

    private createRandomQuestionList(questionList: string[]): string[] {

        // Create a copy of the original question list
        const fullList: string[] = JSON.parse(JSON.stringify(questionList));
        const newList: string[] = [];
        const listLength = Math.min(OralQuestionSetRandomizer.QUESTION_PER_TOPIC_COUNT,fullList.length);

        for (let i = 0;
            i < listLength;
            i++) {

            const rndIdx: number = Math.floor(Math.random() * fullList.length);
            newList.push(fullList[rndIdx]);
            fullList.splice(rndIdx, 1);
        }

        return newList;
    }

    private createTopicIdList(): string[] {

        // Find the topic usage statistics. Store it in local variable to avoid recalculation
        const statistics: TopicUsageStatistics[] = this.getTopicUsageStatistics();

        // Create topic ID list
        const topicIdList: string[] = [];
        for (let i = 0; i < OralQuestionSetRandomizer.EXAM_TOPIC_COUNT; i++) {
            topicIdList.push(statistics[i].topicId);
            statistics[i].usageCount++;
        }

        // Save updated statistics
        this.saveStatistics(statistics);

        return topicIdList;
    }

    private createTopicList(): TestDataConfigTopic[] {

        const topicIdList: string[] = this.createTopicIdList();
        const topicList: TestDataConfigTopic[] = [];

        this._topicList.forEach(topic => {

            if (topicIdList.indexOf(topic.id) > -1) {

                const newTopic: TestDataConfigTopic = new TestDataConfigTopic(topic.id,
                    topic.name,
                    [],
                    this.createRandomQuestionList(topic.oralQuestionList));
                topicList.push(newTopic);
            }
        });
        console.warn('topicList:',topicList);

        return topicList;
    }

    private saveStatistics(statistics: TopicUsageStatistics[]): void {
        // First sort the statistics array
        const sortedStatistics: TopicUsageStatistics[] = statistics.sort((el1, el2) => {
            return el1.usageCount > el2.usageCount ? 1 :
                el1.usageCount < el2.usageCount ? -1 : 0;
        });

        this._persistentState.set(Feature.EXAM_TOPIC_USAGE_STATISTICS,
            sortedStatistics);
    }
}

export class TopicUsageStatistics {
    public topicId: string;
    public usageCount: number;
}