import { Component, OnInit, Input } from '@angular/core';

import { ITestButtonParams } from './test-button-params';

/**
 * The component is used to display Test Button list item
 * @author Ruslan Rubtsov
 * @version 1.0.1
 */
@Component({
  selector: 'test-button',
  templateUrl: './test-button.component.html',
  styleUrls: ['./test-button.component.css']
})
export class TestButtonComponent implements OnInit {

  /**
   * Component parameter that 
   */
  @Input() config: ITestButtonParams;
  @Input() enabled: boolean;

  constructor() {
  }

  /**
   * OnInit interface implementation
   * @see OnInit
   */
  ngOnInit(): void {
  }
}
