import { TestId } from "src/app/models/config/config-types";

export class PersistentStateKey {

  private static map: any;

  static get(feature: string): string {
    if (PersistentStateKey.map == null) {
      const id: string = localStorage[Feature.TEST_ID];
      const testId: TestId = (id != null) ? JSON.parse(id) : TestId.Student;
      PersistentStateKey._initialize(testId);
    }
    return PersistentStateKey.map.get(feature);
  }

  static set testId(id: TestId) {
    PersistentStateKey._initialize(id);
  }

  private static _initialize(testId: TestId) {
    PersistentStateKey.map = new Map<string, string>();
    PersistentStateKey.map.set('bookmarkList', `${testId}_${Feature.BOOKMARK_LIST}`);
    PersistentStateKey.map.set('answerInput', `${testId}_${Feature.ANSWER_INPUT}`);
    PersistentStateKey.map.set('answerSelection', `${testId}_${Feature.ANSWER_SELECTIONS}`);
    PersistentStateKey.map.set('currentTestQuestionIdx', `${testId}_${Feature.CURRENT_TEST_QUESTION_IDX}`);
    PersistentStateKey.map.set('deviceId', Feature.DEVICE_ID);
    PersistentStateKey.map.set('incorrectlyAnsweredQuestions', `${testId}_${Feature.INCORRECTLY_ANSWERED_QUESTIONS}`);
    PersistentStateKey.map.set('lessonBookmarkList', `${testId}_${Feature.LESSON_BOOKMARK_LIST}`);
    PersistentStateKey.map.set('lessonWarningPlayed', `${testId}_${Feature.LESSON_WARNING_PLAYED}`);
    PersistentStateKey.map.set('statistics', `${testId}_${Feature.STATISTICS}`);
    PersistentStateKey.map.set('userCredentials', Feature.USER_CREDENTIALS);
    PersistentStateKey.map.set('currentLanguage', Feature.CURRENT_LANGUAGE);
    PersistentStateKey.map.set('purchaseEmail', Feature.PURCHASE_EMAIL);
    PersistentStateKey.map.set(Feature.ADAPTIVE_LEARN_QUEUE_LIST, `${testId}_${Feature.ADAPTIVE_LEARN_QUEUE_LIST}`);
    PersistentStateKey.map.set(Feature.ADAPTIVE_LEARN_STATISTICS, `${testId}_${Feature.ADAPTIVE_LEARN_STATISTICS}`);
    PersistentStateKey.map.set(Feature.ADAPTIVE_TEST_RESULT, `${testId}_${Feature.ADAPTIVE_TEST_RESULT}`);
    //PersistentStateKey.map.set(Feature.APP_ENVIRONMENT, Feature.APP_ENVIRONMENT);
    PersistentStateKey.map.set(Feature.APP_VERSION, Feature.APP_VERSION);
    PersistentStateKey.map.set(Feature.BUY_ALL_CATEGORIES, Feature.BUY_ALL_CATEGORIES);
    PersistentStateKey.map.set(Feature.CORRECT_ANSWER_LIST, Feature.CORRECT_ANSWER_LIST);
    PersistentStateKey.map.set(Feature.CORRECTLY_ANSWERED_QUESTIONS, `${testId}_${Feature.CORRECTLY_ANSWERED_QUESTIONS}`);
    PersistentStateKey.map.set(Feature.CURRENT_TEST_QUESTION_IDX, `${testId}_${Feature.CURRENT_TEST_QUESTION_IDX}`);
    PersistentStateKey.map.set(Feature.CURRENT_TEST_TOPIC_IDX, `${testId}_${Feature.CURRENT_TEST_TOPIC_IDX}`);
    PersistentStateKey.map.set(Feature.CURRENT_LESSON_PAGE, `${testId}_${Feature.CURRENT_LESSON_PAGE}`);
    PersistentStateKey.map.set(Feature.EXAM_TOPIC_USAGE_STATISTICS, `${testId}_${Feature.EXAM_TOPIC_USAGE_STATISTICS}`);
    PersistentStateKey.map.set(Feature.INSTALL_MESSAGE_COUNT, Feature.INSTALL_MESSAGE_COUNT);
    PersistentStateKey.map.set(Feature.INSTALL_MESSAGE_TIME, Feature.INSTALL_MESSAGE_TIME);
    PersistentStateKey.map.set(Feature.LESSON_AUDIO_ENABLED, Feature.LESSON_AUDIO_ENABLED);
    PersistentStateKey.map.set(Feature.LESSON_TEST_FOLDER, Feature.LESSON_TEST_FOLDER);
    PersistentStateKey.map.set(Feature.LESSON_TEST_RESULT, `${testId}_${Feature.LESSON_TEST_RESULT}`);
    PersistentStateKey.map.set(Feature.LESSON_TEST_START_PARAMS, `${testId}_${Feature.LESSON_TEST_START_PARAMS}`);
    PersistentStateKey.map.set(Feature.LESSON_TEXT_PARAMS, `${testId}_${Feature.LESSON_TEXT_PARAMS}`);
    PersistentStateKey.map.set(Feature.LOGIN_PARAMS, Feature.LOGIN_PARAMS);
    PersistentStateKey.map.set(Feature.QUESTION_LIST_PARAMS, Feature.QUESTION_LIST_PARAMS);
    PersistentStateKey.map.set(Feature.SW_FILE_URL, Feature.SW_FILE_URL);
    PersistentStateKey.map.set(Feature.SUBSCRIPTION, Feature.SUBSCRIPTION);
    PersistentStateKey.map.set(Feature.SUBSCRIPTION_UPDATE_TIME, Feature.SUBSCRIPTION_UPDATE_TIME);
    PersistentStateKey.map.set(Feature.TEST_AUTH_ID, Feature.TEST_AUTH_ID);
    PersistentStateKey.map.set(Feature.TEST_CONFIG, `${testId}_${Feature.TEST_CONFIG}`);
    PersistentStateKey.map.set(Feature.TEST_ID, Feature.TEST_ID);
    PersistentStateKey.map.set(Feature.TEST_QUESTION_PARAMS, `${testId}_${Feature.TEST_QUESTION_PARAMS}`);
    PersistentStateKey.map.set(Feature.TOPIC_FILTER, `${testId}_${Feature.TOPIC_FILTER}`);
  }
}

export enum Feature {
  /* Anonymous keys */
  DEVICE_ID = 'x001',
  USER_CREDENTIALS = 'x002',

  /* Named keys */
  ADAPTIVE_LEARN_QUEUE_LIST = 'adaptiveLearnQueueList',
  ADAPTIVE_LEARN_STATISTICS = 'adaptiveLearnStatistics',
  ADAPTIVE_TEST_RESULT = 'adaptiveTestResult',
  APP_VERSION = 'applicationVersion',
  BUY_ALL_CATEGORIES = 'buyAllCategories',
  BOOKMARK_LIST = 'BOOKMARK_LIST',
  ANSWER_INPUT = 'ANSWER_INPUT',
  ANSWER_SELECTIONS = 'ANSWER_SELECTIONS',
  CORRECT_ANSWER_LIST = 'correct_answer_list',
  CORRECTLY_ANSWERED_QUESTIONS = 'correctlyAnsweredQuestions',
  CURRENT_LANGUAGE = 'currentLanguage',
  CURRENT_LESSON_PAGE = 'currentLessonPage',
  CURRENT_TEST_QUESTION_IDX = 'CURRENT_TEST_QUESTION_IDX',
  CURRENT_TEST_TOPIC_IDX = 'CURRENT_TEST_TOPIC_IDX',
  EXAM_TOPIC_USAGE_STATISTICS = 'exam_topic_usage_statistics',
  INCORRECTLY_ANSWERED_QUESTIONS = 'incorrectlyAnsweredQuestions',
  INSTALL_MESSAGE_COUNT = 'install_message_count',
  INSTALL_MESSAGE_TIME = 'install_message_time',
  LESSON_AUDIO_ENABLED = 'lessonAudioEnabled',
  LESSON_BOOKMARK_LIST = 'lessonBookmarkList',
  LESSON_TEST_FOLDER = 'lessonTestFolder',
  LESSON_TEST_START_PARAMS = 'lessonTestStartParams',
  LESSON_TEST_RESULT = 'lessonTestResult',
  LESSON_TEXT_PARAMS = 'lessonTextParams',
  LESSON_WARNING_PLAYED = 'LESSON_WARNING_PLAYED',
  LOGIN_PARAMS = 'loginParams',
  PURCHASE_EMAIL = 'PURCHASE_EMAIL',
  QUESTION_LIST_PARAMS = 'questionListParams',
  STATISTICS = 'statistics',
  SUBSCRIPTION = 'subscription',
  SUBSCRIPTION_UPDATE_TIME = 'subscription_uipdate_time',
  SW_FILE_URL = 'swFileURL',
  TEST_AUTH_ID = 'testAuthId',
  TEST_CONFIG = 'testConfig',
  TEST_ID = 'testId',
  TEST_QUESTION_PARAMS = 'TEST_QUESTION_PARAMS',
  TOPIC_FILTER = 'topicFilter'
}