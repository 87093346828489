import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ToolbarToggleButtonComponent } from "./toolbar-toggle-button.component";

@Injectable()
export class ToggleButtonService {

    private resetSubject$$: Subject<void> = new Subject<void>();
    private reset$: Observable<void> = this.resetSubject$$.asObservable();

    private buttons: Object = {};

    constructor() {
    }

    addButton(id: string, button: ToolbarToggleButtonComponent) {
        this.buttons[id] = button;
        button.isOnChange.pipe((takeUntil(this.reset$))).
            subscribe((isOn: boolean) =>
                this.onButtonToggle(id, isOn));
    }

    reset(): void {
        this.resetSubject$$.next();
    }

    private onButtonToggle(id: string, isOn: boolean): void {
        if (isOn) {
            Object.keys(this.buttons).forEach((buttonId) => {
                const button: ToolbarToggleButtonComponent = this.buttons[buttonId];
                if (
                    buttonId != id &&
                    button.isOn &&
                    button.togglableFromOutside
                ) {
                    button.isOnChange.emit(false);
                }
            })
        }
    }

}